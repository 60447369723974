export const SET_IS_RECOVERY_RADAR_WORKOUT_MODAL_SHOWING = 'SET_IS_RECOVERY_RADAR_WORKOUT_MODAL_SHOWING';
export const SET_IS_RECOVERY_RADAR_WEIGHTROOM_MODAL_SHOWING = 'SET_IS_RECOVERY_RADAR_WEIGHTROOM_MODAL_SHOWING';

export const SET_SELECTED_BODY_PART = 'SET_SELECTED_BODY_PART';
export const UPDATE_BODY_PART_ISSUE = 'UPDATE_BODY_PART_ISSUE';

export const FETCH_BODY_MAP_DATA_BY_DATE_START = 'FETCH_BODY_MAP_DATA_BY_DATE_START';
export const FETCH_BODY_MAP_DATA_BY_DATE_FULFILLED = 'FETCH_BODY_MAP_DATA_BY_DATE_FULFILLED';
export const FETCH_BODY_MAP_DATA_BY_DATE_REJECTED = 'FETCH_BODY_MAP_DATA_BY_DATE_REJECTED';

export const SUBMIT_BODY_MAP_DATA_START = 'SUBMIT_BODY_MAP_DATA_START';
export const SUBMIT_BODY_MAP_DATA_FULFILLED = 'SUBMIT_BODY_MAP_DATA_FULFILLED';
export const SUBMIT_BODY_MAP_DATA_REJECTED = 'SUBMIT_BODY_MAP_DATA_REJECTED';

export const UPDATE_BODY_MAP_DATA_START = 'UPDATE_BODY_MAP_DATA_START';
export const UPDATE_BODY_MAP_DATA_FULFILLED = 'UPDATE_BODY_MAP_DATA_FULFILLED';
export const UPDATE_BODY_MAP_DATA_REJECTED = 'UPDATE_BODY_MAP_DATA_REJECTED';

export const SET_IS_REMOVE_MODAL_SHOWING = 'SET_IS_REMOVE_MODAL_SHOWING';
export const SET_REMOVE_BODY_MAP_DATA_ITEMS = 'SET_REMOVE_BODY_MAP_DATA_ITEMS';
export const SET_REMOVE_PREVIOUS_ITEMS = 'SET_REMOVE_PREVIOUS_ITEMS';
export const SET_CONFIRM_REMOVE_PREVIOUS_ITEMS = 'SET_CONFIRM_REMOVE_PREVIOUS_ITEMS';
export const SET_REMOVE_ALL_BODY_MAP_DATA = 'SET_REMOVE_ALL_BODY_MAP_DATA';

export const DELETE_BODY_MAP_DATA_START = 'DELETE_BODY_MAP_DATA_START';
export const DELETE_BODY_MAP_DATA_FULFILLED = 'DELETE_BODY_MAP_DATA_FULFILLED';
export const DELETE_BODY_MAP_DATA_REJECTED = 'DELETE_BODY_MAP_DATA_REJECTED';

export const SET_ACTIVE_WEIGHTROOM_PANEL = 'SET_ACTIVE_WEIGHTROOM_PANEL';

export const SET_IS_ONBOARDING_MODAL_SHOWING = 'SET_IS_ONBOARDING_MODAL_SHOWING';
