import React from 'react';
import PropTypes from 'prop-types';
import SelectBox from '../../../../shared/_TBComponents/SelectBox';

const CustomSelectBox = ({
  options,
  value,
  defaultValue,
  placeholder,
  onChange,
  title,
  titleStyledProps,
}) => (
  <SelectBox
    options={options}
    onChangeFunction={onChange}
    value={value}
    defaultValue={defaultValue}
    placeholder={placeholder}
    isClearable={false}
    title={title}
    titleStyledProps={{
      fontWeight: 700,
      fontFamily: 'Nunito Sans',
      marginBottom: '6px',
      ...titleStyledProps,
    }}
    customStyles={{
      control: (provided) => ({
        ...provided,
        cursor: 'pointer',
        border: 'solid 2px #E0E0E0',
        boxShadow: 'none',
        borderRadius: '5px',
        fontFamily: 'Nunito Sans',
        '&:hover': {
          borderColor: '#ccc',
        },
      }),
      indicatorsContainer: (provided) => ({
        ...provided,
        padding: '0 8px',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      option: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        backgroundColor: state.isFocused ? '#f0f0f0' : '#fff',
        color: '#333',
        '&:active': {
          backgroundColor: '#f0f0f0',
        },
      }),
    }}
  />
);

CustomSelectBox.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  value: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  defaultValue: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  titleStyledProps: PropTypes.shape({
    fontWeight: PropTypes.number,
    fontFamily: PropTypes.string,
    fontSize: PropTypes.string,
    marginBottom: PropTypes.string,
  }),
};

CustomSelectBox.defaultProps = {
  value: null,
  defaultValue: null,
  placeholder: '',
  titleStyledProps: {},
};

export default CustomSelectBox;
