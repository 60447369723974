import React from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '../../../../shared/_TBComponents/Button';
import Text from '../../../../shared/_TBComponents/Text';
import {
  setIsRemoveModalShowing,
  deleteBodyMapData,
  setConfirmRemovePreviousItems,
  setRemovePreviousItems,
  setRemoveAllBodyMapData,
} from '../../ducks/recoveryRadarTrackerActions';

const Overlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background: rgba(0, 0, 0, 0.75);
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
`;

const ModalContainer = styled.div`
  position: fixed;
  background: white;
  border-radius: 24px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 45vw;
  max-width: 450px;
  @media only screen and (max-width: 768px) {
    width: 80vw;
    max-width: 90vw;
  }
  z-index: 1101;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const RemoveModal = ({
  currentUser,
  userId,
  bodyMapData,
  isWeightRoomView,
  activeAthleteId,
  formattedActiveDate,
  currentWeightroomUser,
  activeWeightroomPanel,
}) => {
  const dispatch = useDispatch();

  const removeAll = useSelector((state) => state.recoveryRadarTracker.ui.removeAllBodyMapData);
  const itemsToRemove = useSelector((state) => state.recoveryRadarTracker.data.itemsToRemove);
  const previousItemsToRemove = useSelector(
    (state) => state.recoveryRadarTracker.data.previousItemsToRemove,
  );
  const previousItemsRemoved = useSelector(
    (state) => state.recoveryRadarTracker.data.previousItemsRemoved,
  );

  const areasSelected = bodyMapData?.currentIssues?.length || 0;
  const activeWorkoutDate = useSelector((state) => state.workouts.data.activeWorkoutDate);

  const handleClose = () => {
    batch(() => {
      dispatch(setRemoveAllBodyMapData(false));
      dispatch(setRemovePreviousItems([]));
      dispatch(setIsRemoveModalShowing(false));
    });
  };

  const handleRemove = () => {
    const issueIds = bodyMapData?.currentIssues?.map((issue) => issue.id) || [];

    const selectedUser = isWeightRoomView ? currentWeightroomUser : currentUser;
    const selectedUserId = isWeightRoomView ? activeAthleteId : userId;
    const selectedItems = removeAll ? issueIds : itemsToRemove;
    const selectedDate = isWeightRoomView ? formattedActiveDate : activeWorkoutDate;
    const selectedPanel = isWeightRoomView ? activeWeightroomPanel : null;

    const isRemovingCurrentIssues = selectedItems.some((item) => issueIds.includes(item));
    const isRemovingPreviousIssues = previousItemsToRemove.length > 0;

    const successMessage = removeAll
      ? 'All issues removed successfully!'
      : 'Issue removed successfully!';

    // Only send request if removing a current issue and just filter previous issues
    if (selectedItems.some((item) => issueIds.includes(item))) {
      dispatch(deleteBodyMapData(
        selectedUser,
        selectedUserId,
        selectedItems,
        selectedDate,
        isWeightRoomView,
        selectedPanel,
        successMessage,
      ));
    }

    if (isRemovingPreviousIssues) {
      dispatch(setConfirmRemovePreviousItems([...previousItemsRemoved, ...previousItemsToRemove]));
      if (!isRemovingCurrentIssues || !removeAll) {
        toast.success(successMessage);
      }
    }

    dispatch(setRemovePreviousItems([]));
    handleClose();
  };

  return (
    <Overlay onClick={handleClose}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <ModalContent>
          <Text
            styledTextProps={{
              fontSize: '34px',
              fontWeight: '700',
              lineHeight: '130%',
            }}
          >
            {removeAll
              ? 'Remove All Areas Selected'
              : 'Remove Area Selected?'}
          </Text>
          <Text
            styledTextProps={{ fontSize: '19px' }}
            styledWrapperProps={{ margin: '16px 0 64px 0' }}
          >
            {removeAll
              ? `All areas selected (${areasSelected}) and areas to review will be removed.`
              : 'The area selected will be removed.'}
            <br />
            This action cannot be undone.
          </Text>
          <ButtonWrapper>
            <Button
              name='Remove'
              bgColor='#FF3737'
              borderColor='#FF3737'
              txtColor='#FFFFFF'
              customBorderRadius='4px'
              styledTextProps={{
                fontSize: '13px',
                fontWeight: '700',
                letterSpacing: '0.5px',
              }}
              styledWrapperProps={{ borderWidth: '2px', padding: '16px' }}
              onClick={handleRemove}
            />
            <Button
              name='Cancel'
              customBorderRadius='4px'
              styledTextProps={{
                fontSize: '13px',
                fontWeight: '700',
                letterSpacing: '0.5px',
              }}
              styledWrapperProps={{ borderWidth: '2px', padding: '16px' }}
              onClick={handleClose}
            />
          </ButtonWrapper>
        </ModalContent>
      </ModalContainer>
    </Overlay>
  );
};

RemoveModal.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }),
  userId: PropTypes.number,
  bodyMapData: PropTypes.shape({
    currentIssues: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        bodyPart: PropTypes.string.isRequired,
        issueType: PropTypes.string.isRequired,
        severity: PropTypes.number.isRequired,
      }),
    ).isRequired,
    previousIssues: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        bodyPart: PropTypes.string.isRequired,
        issueType: PropTypes.string.isRequired,
        severity: PropTypes.number.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  isWeightRoomView: PropTypes.bool.isRequired,
  activeAthleteId: PropTypes.number,
  formattedActiveDate: PropTypes.string,
  currentWeightroomUser: PropTypes.shape({
    id: PropTypes.number,
    accountCode: PropTypes.number,
  }),
  activeWeightroomPanel: PropTypes.string,
};

RemoveModal.defaultProps = {
  currentUser: null,
  userId: null,
  activeAthleteId: null,
  formattedActiveDate: null,
  currentWeightroomUser: null,
  activeWeightroomPanel: null,
};

export default RemoveModal;
