import React, { useState, useRef } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';
import Button from '../../../../shared/_TBComponents/Button';
import Spinner from '../../../../shared/_TBComponents/Spinner';
import Text from '../../../../shared/_TBComponents/Text';
import OnboardingModal from '../../../../shared/_TBComponents/OnboardingModal';

import onboardingImageOne from '../../../../shared/images/rr-tracker-onboarding-one.png';
import onboardingImageTwo from '../../../../shared/images/rr-tracker-onboarding-two.png';
import onboardingImageThree from '../../../../shared/images/rr-tracker-onboarding-three.png';

import BottomSheet from '../BottomSheets/BottomSheet';
import RemoveModal from '../BottomSheets/RemoveModal';
import RecoveryRadarBodyMap from './RecoveryRadarBodyMap';
import {
  setSelectedBodyPart,
  updateBodyPartIssue,
  setIsOnboardingModalShowing,
} from '../../ducks/recoveryRadarTrackerActions';

const ModalContainer = styled('div')`
  display: flex;
  background: white;
  height: 90vh;
  @media only screen and (max-width: 768px) {
    width: 90vw;
    max-width: 100vw;
  }
  @media only screen and (min-height: 950px) {
    height: 75vh;
  }
  width: 50vw;
  z-index: ${({ isOnboardingModalShowing }) => (isOnboardingModalShowing ? 1000 : 1001)};
  overflow: hidden;
  box-shadow: ${BoxShadow};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  max-width: 500px;
  transition: all 0.3s linear;
`;

const Container = styled('div')`
  width: 100%;
  flex-direction: column;
  transition: all 1s ease;
  overflow: hidden;
  user-select: none;
  height: 100%;
  border-radius: 12px;
`;

const TopMenu = styled('div')`
  display: flex;
  padding: 20px 20px 0 20px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  align-self: stretch;
`;

const FlexContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ScrollContainer = styled('div')`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SpinnerContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
`;

const RecoveryRadarTrackerModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const scrollRef = useRef(null);

  const [currentView, setCurrentView] = useState('athleteInfo');
  const [resetOnBodyPartPress, setResetOnBodyPartPress] = useState(() => () => {});

  const currentUser = useSelector((state) => state.auth?.data?.currentUser);
  const sharedAthlete = useSelector((state) => state.workouts?.data?.sharedAthlete);
  const userId = currentUser?.admin ? sharedAthlete?.id : currentUser?.id;

  const isBodyMapDataLoading = useSelector(
    (state) => state.recoveryRadarTracker.ui.isBodyMapDataLoading,
  );
  const isRemoveModalShowing = useSelector(
    (state) => state.recoveryRadarTracker.ui.isRemoveModalShowing,
  );
  const isRecoveryRadarWorkoutModalShowing = useSelector(
    (state) => state.recoveryRadarTracker.ui.isRecoveryRadarWorkoutModalShowing,
  );
  const isRecoveryRadarWeightroomModalShowing = useSelector(
    (state) => state.recoveryRadarTracker.ui.isRecoveryRadarWeightroomModalShowing,
  );
  const isOnboardingModalShowing = useSelector(
    (state) => state.recoveryRadarTracker.ui.isOnboardingModalShowing,
  );

  const selectedBodyPart = useSelector(
    (state) => state.recoveryRadarTracker.data.selectedBodyPart,
  );
  const workoutEntryData = useSelector(
    (state) => state.recoveryRadarTracker.data.workoutEntryData,
  );
  const weightRoomViewData = useSelector(
    (state) => state.recoveryRadarTracker.data.weightRoomViewData,
  );
  const activeWeightroomPanel = useSelector(
    (state) => state.recoveryRadarTracker.data.activeWeightroomPanel,
  );

  const currentWeightroomUser = useSelector(
    (state) => state.weightRoomView.data.currentUser,
  );
  const activeAthleteId = useSelector(
    (state) => state.weightRoomView[activeWeightroomPanel]?.athleteId,
  );
  const activeDate = useSelector(
    (state) => state.weightRoomView[activeWeightroomPanel]?.activeDate,
  );

  const parsedActiveDate = activeDate ? moment(activeDate).startOf('day') : null;
  const formattedActiveDate = parsedActiveDate ? parsedActiveDate.format('YYYY-MM-DD') : null;

  const isWeightRoomView = Object.keys(weightRoomViewData).length > 0
      && Object.values(weightRoomViewData).some(
        (panel) => panel.currentIssues.length > 0 || panel.previousIssues.length > 0,
      );

  const bodyMapData = isWeightRoomView
    ? weightRoomViewData[activeWeightroomPanel]
    : workoutEntryData;

  // Checks if the coach or athlete in Workout Entry or WRV should see region and status
  const heatMapTagsEnabled = (() => {
    if (sharedAthlete && isRecoveryRadarWorkoutModalShowing) {
      return currentUser?.userOrgSSHubSettings?.heatMapTagsEnabled;
    } if (currentWeightroomUser && isRecoveryRadarWeightroomModalShowing) {
      return currentWeightroomUser?.userOrgSSHubSettings?.heatMapTagsEnabled;
    }
    return null;
  })();

  const formatSlug = (slug) => {
    if (!slug) return '';
    return slug
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const handleClose = () => {
    dispatch(setSelectedBodyPart(''));
    onClose();
  };

  const handleBack = () => {
    if (currentView === 'reportForm') {
      setCurrentView('athleteInfo');
      batch(() => {
        dispatch(setSelectedBodyPart(''));
        dispatch(updateBodyPartIssue({}));
      });
      setResetOnBodyPartPress(() => () => {});
    } else {
      onClose();
    }
  };

  const handleOpenOnboarding = () => {
    dispatch(setIsOnboardingModalShowing(true));
  };

  const handleCloseOnboarding = () => {
    dispatch(setIsOnboardingModalShowing(false));
  };

  const onBodyPartPress = (bodyPart) => {
    batch(() => {
      dispatch(setSelectedBodyPart(bodyPart));
      dispatch(updateBodyPartIssue({}));
    });
    setCurrentView('reportForm');
  };

  const ONBOARDING_CONTENT = [
    {
      image: onboardingImageOne,
      title: 'Log Recovery Data',
      description: 'Use the interactive body map to log any pain or soreness you’re experiencing. Simply click on the affected area to begin. Each selection helps build a personal history of recovery that your coach can monitor over time.',
      gradientType: 'primary',
    },
    {
      image: onboardingImageTwo,
      title: 'Add Details',
      description: heatMapTagsEnabled
        ? 'Provide more context by selecting the type (Pain or Soreness), severity (1-7), specific region, and status. You can also add notes to help your coach better understand your condition.'
        : 'Provide more context by selecting the type (Pain or Soreness) and severity (1-7). You can also add notes to help your coach better understand your condition.',
      gradientType: 'primary',

    },
    {
      image: onboardingImageThree,
      title: 'Track & Adjust',
      description: 'After clicking Save, your entries become part of a historical record that you and your coach can review. Coaches can use this data to adjust training plans, monitor trends, and provide support. When reviewing past entries, you can update the details if the issue persists or remove them if it’s no longer a concern.',
      gradientType: 'primary',

    },
  ];

  return (
    <>
      <ModalContainer isOnboardingModalShowing={isOnboardingModalShowing}>
        <Container>
          <TopMenu>
            <FlexContainer>
              {!selectedBodyPart ? (
                <Button
                  icon='remove'
                  iconSize={24}
                  iconOnly
                  noBorder
                  onClick={handleClose}
                />
              ) : (
                <Button
                  icon='left-arrow'
                  iconSize={24}
                  iconOnly
                  noBorder
                  onClick={handleBack}
                />
              )}
              <Button
                icon='info2'
                name='How to use'
                txtColor='#424242'
                styledTextProps={{ fontSize: '16px', fontWeight: '400', letterSpacing: '0' }}
                styledWrapperProps={{ style: { borderWidth: '2px', padding: '4px 18px' } }}
                customBorderRadius='20px'
                onClick={handleOpenOnboarding}
              />
            </FlexContainer>
            {selectedBodyPart && (
            <Text styledTextProps={{ fontSize: '19px', fontWeight: '700' }}>
              {formatSlug(selectedBodyPart.slug)}
            </Text>
            )}
          </TopMenu>
          <ScrollContainer ref={scrollRef}>
            {isBodyMapDataLoading
              ? (
                <SpinnerContainer>
                  <Spinner />
                </SpinnerContainer>
              ) : (
                <RecoveryRadarBodyMap
                  selectedBodyPart={selectedBodyPart}
                  onBodyPartPress={onBodyPartPress}
                  resetOnBodyPartPress={resetOnBodyPartPress}
                  bodyMapData={bodyMapData}
                />
              )}
            <BottomSheet
              background='#ffffff'
              currentUser={currentUser}
              userId={userId}
              currentView={currentView}
              setCurrentView={setCurrentView}
              bodyMapData={bodyMapData}
              scrollRef={scrollRef}
              heatMapTagsEnabled={heatMapTagsEnabled}
            />
          </ScrollContainer>
        </Container>
        {isOnboardingModalShowing && (
        <OnboardingModal
          badgeText='GET STARTED'
          content={ONBOARDING_CONTENT}
          dashName='Body Heat Map'
          isActive={isOnboardingModalShowing}
          maxHeight={null}
          minHeight={null}
          onClose={handleCloseOnboarding}
          onPrimaryAction={handleCloseOnboarding}
          onSecondaryAction={handleCloseOnboarding}
          primaryButtonText='GET STARTED'
          showSingleButton
        />
        )}
      </ModalContainer>
      {isRemoveModalShowing && (
      <RemoveModal
        currentUser={currentUser}
        userId={userId}
        bodyMapData={bodyMapData}
        isWeightRoomView={isWeightRoomView}
        activeAthleteId={activeAthleteId}
        formattedActiveDate={formattedActiveDate}
        currentWeightroomUser={currentWeightroomUser}
        activeWeightroomPanel={activeWeightroomPanel}
      />
      )}
    </>
  );
};

RecoveryRadarTrackerModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default RecoveryRadarTrackerModal;
