import { axiosAuthed } from '../../../shared/utils/setCommonHeaders';

/**
 * Submits the user's acceptance of the AI workout terms
 * @param {number} acceptanceId - The ID of the acceptance type (1 for coach in webapp)
 * @returns {Promise} Promise that resolves with the response data
 */
export const submitUserAcceptance = (acceptanceId) => axiosAuthed
  .post(`/me/acceptances/${acceptanceId}`)
  .then((response) => response.data)
  .catch((err) => Promise.reject(err));
