import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from '../../../../shared/_TBComponents/Icon';

const ButtonContainer = styled.button`
  height: ${({ size }) => size || '40px'};
  width: ${({ size }) => size || '40px'};
  border: 2px solid
    ${({ isActive, activeColor, borderColor }) => (isActive ? activeColor : borderColor)};
  background-color: ${({
    isActive, activeColor, inactiveColor, isSoreness,
  }) => {
    if (isActive) {
      return isSoreness ? `${activeColor}80` : activeColor;
    }
    return inactiveColor;
  }};
  color: #444444;
  padding-bottom: ${({ hasPadding }) => (hasPadding === true ? '3px' : '0px')};
  font-size: 16px;
  font-weight: 400;
  border-radius: 20px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  transition: all 0.2s ease;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:hover {
    background-color: ${({ activeColor, disabled }) => (disabled ? 'inherit' : activeColor)};
    border-color: ${({ activeColor, disabled }) => (disabled ? 'inherit' : activeColor)};
  }
`;

const CustomButton = ({
  isActive,
  activeColor,
  inactiveColor,
  borderColor,
  isSoreness,
  disabled,
  size,
  icon,
  iconColor,
  iconSize,
  hasPadding,
  children,
  ...rest
}) => (
  <ButtonContainer
    activeColor={activeColor}
    inactiveColor={inactiveColor}
    isActive={isActive}
    borderColor={borderColor}
    isSoreness={isSoreness}
    disabled={disabled}
    size={size}
    padding={hasPadding}
    {...rest}
  >
    {icon ? (
      <Icon
        icon={icon}
        color={iconColor || '#444444'}
        size={iconSize || '16px'}
      />
    ) : (
      children
    )}
  </ButtonContainer>
);

CustomButton.propTypes = {
  isActive: PropTypes.bool.isRequired,
  activeColor: PropTypes.string.isRequired,
  inactiveColor: PropTypes.string,
  borderColor: PropTypes.string,
  isSoreness: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  iconSize: PropTypes.string,
  hasPadding: PropTypes.bool,
  children: PropTypes.node,
};

CustomButton.defaultProps = {
  inactiveColor: '#ffffff',
  borderColor: '#E0E0E0',
  isSoreness: false,
  disabled: false,
  size: '40px',
  icon: null,
  iconColor: null,
  iconSize: null,
  hasPadding: false,
  children: null,
};

export default CustomButton;
