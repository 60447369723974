import React, { memo } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import Table from '../../../../shared/components/Table/Table';

const TableWrapper = styled('div')`
  padding: 0px 20px;
  margin-top: 30px;
  
  .rdt_TableBody {
    padding: 0px 5px;
  }

  @media print {
    zoom: 85%;
  }
  
  @media screen and (max-width: 540px) {
    padding: 0px;
    margin: 0px;
  }
`;

const ActivityReportTable = ({
  tableColumns,
  tableData,
}) => (
  <TableWrapper>
    <Table
      center
      columns={tableColumns}
      data={tableData}
      highlightOnHover
      noHeader
      pointerOnHover
      options={{ headCells: { textAlign: 'center' } }}
    />
  </TableWrapper>
);

ActivityReportTable.propTypes = {
  tableColumns: PropTypes.instanceOf(Object).isRequired,
  tableData: PropTypes.instanceOf(Object).isRequired,
};

export default memo(ActivityReportTable);
