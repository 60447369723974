import { toast } from 'react-toastify';
import {
  SET_IS_RECOVERY_RADAR_WORKOUT_MODAL_SHOWING,
  SET_IS_RECOVERY_RADAR_WEIGHTROOM_MODAL_SHOWING,
  SET_SELECTED_BODY_PART,
  UPDATE_BODY_PART_ISSUE,
  FETCH_BODY_MAP_DATA_BY_DATE_START,
  FETCH_BODY_MAP_DATA_BY_DATE_FULFILLED,
  FETCH_BODY_MAP_DATA_BY_DATE_REJECTED,
  SUBMIT_BODY_MAP_DATA_START,
  SUBMIT_BODY_MAP_DATA_FULFILLED,
  SUBMIT_BODY_MAP_DATA_REJECTED,
  UPDATE_BODY_MAP_DATA_START,
  UPDATE_BODY_MAP_DATA_FULFILLED,
  UPDATE_BODY_MAP_DATA_REJECTED,
  DELETE_BODY_MAP_DATA_START,
  DELETE_BODY_MAP_DATA_FULFILLED,
  DELETE_BODY_MAP_DATA_REJECTED,
  SET_IS_REMOVE_MODAL_SHOWING,
  SET_REMOVE_BODY_MAP_DATA_ITEMS,
  SET_REMOVE_PREVIOUS_ITEMS,
  SET_CONFIRM_REMOVE_PREVIOUS_ITEMS,
  SET_REMOVE_ALL_BODY_MAP_DATA,
  SET_ACTIVE_WEIGHTROOM_PANEL,
  SET_IS_ONBOARDING_MODAL_SHOWING,
} from './actionTypes';

import { axiosAuthed } from '../../../shared/utils/setCommonHeaders';

export const setIsRecoveryRadarWorkoutModalShowing = (bool) => ({
  type: SET_IS_RECOVERY_RADAR_WORKOUT_MODAL_SHOWING,
  payload: bool,
});

export const setIsRecoveryRadarWeightroomModalShowing = (bool) => ({
  type: SET_IS_RECOVERY_RADAR_WEIGHTROOM_MODAL_SHOWING,
  payload: bool,
});

export const setSelectedBodyPart = (bodyPart) => ({
  type: SET_SELECTED_BODY_PART,
  payload: bodyPart,
});

export const updateBodyPartIssue = (updatedIssue) => ({
  type: UPDATE_BODY_PART_ISSUE,
  payload: updatedIssue,
});

export const fetchBodyMapData = (
  currentUser, userId, date, isWeightRoomView = false, weightRoomPanel = null,
) => (dispatch) => {
  dispatch({
    type: FETCH_BODY_MAP_DATA_BY_DATE_START,
    payload: { isWeightRoomView, weightRoomPanel },
  });

  let baseUrl;
  if (isWeightRoomView) {
    // Always use coach path for WRV
    baseUrl = `/accounts/${currentUser.accountCode}/users/${userId}`;
  } else {
    // Check coach or athlete path for Workout Entry
    baseUrl = currentUser.id === userId
      ? `/me/accounts/${currentUser.accountCode}`
      : `/accounts/${currentUser.accountCode}/users/${userId}`;
  }

  axiosAuthed.get(`${baseUrl}/body-heat-map/${date}`)
    .then((response) => {
      dispatch({
        type: FETCH_BODY_MAP_DATA_BY_DATE_FULFILLED,
        payload: { isWeightRoomView, weightRoomPanel, data: response.data },
      });
    })
    .catch((err) => {
      toast.error(err?.response?.headers?.message || 'An unknown error has occurred');
      dispatch({
        type: FETCH_BODY_MAP_DATA_BY_DATE_REJECTED,
        payload: { isWeightRoomView, weightRoomPanel, err },
      });
    });
};

export const submitBodyMapData = (
  currentUser,
  userId,
  bodyMapData,
  date,
  isWeightRoomView = false,
  weightRoomPanel = null,
) => (dispatch) => {
  dispatch({ type: SUBMIT_BODY_MAP_DATA_START });

  let baseUrl;
  if (currentUser.id === userId) {
    baseUrl = `/me/accounts/${currentUser.accountCode}`;
  } else {
    baseUrl = `/accounts/${currentUser.accountCode}/users/${userId}`;
  }

  axiosAuthed.post(`${baseUrl}/body-heat-map`, bodyMapData)
    .then((response) => {
      if (response.data.errors.length > 0) {
        toast.error(response.data.errors.map((err) => err.message).join(', '));
      } else {
        toast.success('Issue added successfully!');
      }
      dispatch({
        type: SUBMIT_BODY_MAP_DATA_FULFILLED,
        payload: response.data,
      });
      // Refetch body map data after submission
      dispatch(
        fetchBodyMapData(currentUser, userId, date, isWeightRoomView, weightRoomPanel),
      );
    })
    .catch((err) => {
      toast.error(err?.response?.headers?.message || 'An unknown error has occurred');
      dispatch({
        type: SUBMIT_BODY_MAP_DATA_REJECTED,
        payload: err?.response?.headers?.message,
      });
    });
};

export const updateBodyMapData = (
  currentUser,
  userId,
  bodyMapData,
  date,
  isWeightRoomView = false,
  weightRoomPanel = null,
) => (dispatch) => {
  dispatch({ type: UPDATE_BODY_MAP_DATA_START });

  let baseUrl;
  if (currentUser.id === userId) {
    baseUrl = `/me/accounts/${currentUser.accountCode}`;
  } else {
    baseUrl = `/accounts/${currentUser.accountCode}/users/${userId}`;
  }

  axiosAuthed.put(`${baseUrl}/body-heat-map`, bodyMapData)
    .then((response) => {
      if (response.data.errors.length > 0) {
        toast.error(response.data.errors.map((err) => err.message).join(', '));
      } else {
        toast.success('Issue updated successfully!');
      }
      dispatch({
        type: UPDATE_BODY_MAP_DATA_FULFILLED,
        payload: response.data,
      });
      // Refetch body map data after update
      dispatch(
        fetchBodyMapData(currentUser, userId, date, isWeightRoomView, weightRoomPanel),
      );
    })
    .catch((err) => {
      toast.error(err?.response?.headers?.message || 'An unknown error has occurred');
      dispatch({
        type: UPDATE_BODY_MAP_DATA_REJECTED,
        payload: err?.response?.headers?.message,
      });
    });
};

export const setIsRemoveModalShowing = (bool) => ({
  type: SET_IS_REMOVE_MODAL_SHOWING,
  payload: bool,
});

export const setRemoveBodyMapDataItems = (items) => ({
  type: SET_REMOVE_BODY_MAP_DATA_ITEMS,
  payload: items,
});

export const setRemovePreviousItems = (items) => ({
  type: SET_REMOVE_PREVIOUS_ITEMS,
  payload: items,
});

export const setConfirmRemovePreviousItems = (items) => ({
  type: SET_CONFIRM_REMOVE_PREVIOUS_ITEMS,
  payload: items,
});

export const setRemoveAllBodyMapData = (bool) => ({
  type: SET_REMOVE_ALL_BODY_MAP_DATA,
  payload: bool,
});

export const deleteBodyMapData = (
  currentUser,
  userId,
  issueId,
  date,
  isWeightRoomView = false,
  weightRoomPanel = null,
  successMessage,
) => (dispatch) => {
  dispatch({ type: DELETE_BODY_MAP_DATA_START });

  let baseUrl;
  if (currentUser.id === userId) {
    baseUrl = `/me/accounts/${currentUser.accountCode}`;
  } else {
    baseUrl = `/accounts/${currentUser.accountCode}/users/${userId}`;
  }

  axiosAuthed.patch(`${baseUrl}/body-heat-map`, issueId)
    .then((response) => {
      if (response.data.errors.length > 0) {
        toast.error(response.data.errors.map((err) => err.message).join(', '));
      } else {
        toast.success(successMessage);
      }
      dispatch({
        type: DELETE_BODY_MAP_DATA_FULFILLED,
        payload: response.data,
      });
      // Refetch body map data after removal
      dispatch(
        fetchBodyMapData(currentUser, userId, date, isWeightRoomView, weightRoomPanel),
      );
    })
    .catch((error) => {
      dispatch({
        type: DELETE_BODY_MAP_DATA_REJECTED,
        payload: error,
      });
    });
};

export const setActiveWeightroomPanel = (activePanel) => ({
  type: SET_ACTIVE_WEIGHTROOM_PANEL,
  payload: activePanel,
});

export const setIsOnboardingModalShowing = (bool) => ({
  type: SET_IS_ONBOARDING_MODAL_SHOWING,
  payload: bool,
});
