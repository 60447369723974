import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { useTheme } from 'emotion-theming';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';

import Button from '../../../../shared/_TBComponents/Button';
import Text from '../../../../shared/_TBComponents/Text';
import CustomSelectBox from './CustomSelectBox';
import CustomTextArea from './CustomTextArea';
import SeverityScale from './SeverityScale';

import {
  updateBodyPartIssue,
  submitBodyMapData,
  setSelectedBodyPart,
  updateBodyMapData,
} from '../../ducks/recoveryRadarTrackerActions';
import {
  TYPE_OPTIONS, REGION_OPTIONS, STATUS_OPTIONS, NOTE_MAX_LENGTH, SEVERITY_COLORS,
} from '../../constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2px;
`;

const SelectionContainer = styled.div`
  width: 100%;
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin-bottom: 125px;
`;

const ReportForm = ({
  currentUser,
  userId,
  setCurrentView,
  foundBodyPart,
  heatMapTagsEnabled,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [selectedType, setSelectedType] = useState(TYPE_OPTIONS[0]);
  const [selectedSeverity, setSelectedSeverity] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [additionalNotes, setAdditionalNotes] = useState('');

  const activeWeightroomPanel = useSelector(
    (state) => state.recoveryRadarTracker.data.activeWeightroomPanel,
  );
  const activeDate = useSelector(
    (state) => state.weightRoomView[activeWeightroomPanel]?.activeDate,
  );
  const weightRoomViewData = useSelector(
    (state) => state.recoveryRadarTracker.data.weightRoomViewData,
  );
  const currentWeightroomUser = useSelector(
    (state) => state.weightRoomView.data.currentUser,
  );
  const activeAthleteId = useSelector(
    (state) => state.weightRoomView[activeWeightroomPanel]?.athleteId,
  );
  const activeWorkoutDate = useSelector(
    (state) => state.workouts.data.activeWorkoutDate,
  );
  const selectedBodyPart = useSelector(
    (state) => state.recoveryRadarTracker.data.selectedBodyPart,
  );

  const parsedActiveDate = activeDate ? moment(activeDate).startOf('day') : null;
  const formattedActiveDate = parsedActiveDate ? parsedActiveDate.format('YYYY-MM-DD') : null;
  const isWeightRoomView = Object.keys(weightRoomViewData).length > 0
  && Object.values(weightRoomViewData).some(
    (panel) => panel.currentIssues.length > 0 || panel.previousIssues.length > 0,
  );

  const textAreaColors = {
    text: theme.colors.text,
    background: theme.colors.background[0],
    placeholder: theme.colors.lightText[3],
    focus: theme.colors.primary,
    error: theme.colors.red,
  };

  useEffect(() => {
    if (!foundBodyPart || foundBodyPart.slug !== selectedBodyPart?.slug) {
      setSelectedSeverity(null);
      setAdditionalNotes('');
    }
  }, [selectedBodyPart?.slug, foundBodyPart?.slug]);

  useEffect(() => {
    if (foundBodyPart) {
      // Backend sends some as lowercase but options in constants file are capitalized
      setSelectedType(TYPE_OPTIONS.find(
        (option) => option.name.toLowerCase() === foundBodyPart?.issueType?.toLowerCase(),
      ) || TYPE_OPTIONS[0]);
      setSelectedSeverity(foundBodyPart?.severity || null);
      setSelectedRegion(REGION_OPTIONS.find(
        (option) => option.name.toLowerCase() === foundBodyPart?.region?.toLowerCase(),
      ) || null);
      setSelectedStatus(STATUS_OPTIONS.find(
        (option) => option.name === foundBodyPart?.status,
      ) || null);
      setAdditionalNotes(foundBodyPart?.additionalNotes || '');
    }
  }, [foundBodyPart]);

  useEffect(() => {
    if (selectedBodyPart) {
      const updatedIssue = {
        slug: selectedBodyPart.slug,
        severity: selectedSeverity,
        color: SEVERITY_COLORS[selectedSeverity - 1] || '#898989',
        issueType: selectedType.name.toLowerCase(),
      };
      dispatch(updateBodyPartIssue(updatedIssue));
    }
  }, [selectedBodyPart, selectedType, selectedSeverity]);

  const handleTypeChange = (type) => {
    setSelectedType(type);

    if (selectedBodyPart) {
      const updatedIssue = {
        slug: selectedBodyPart.slug,
        severity: selectedSeverity,
        color: SEVERITY_COLORS[selectedSeverity - 1],
        issueType: type.name.toLowerCase(),
      };
      dispatch(updateBodyPartIssue(updatedIssue));
    }
  };

  const handleSeverityChange = (severity) => {
    setSelectedSeverity(severity);
    // This updates the body part colors in real time
    if (selectedBodyPart) {
      const updatedIssue = {
        slug: selectedBodyPart.slug,
        severity,
        color: SEVERITY_COLORS[severity - 1],
        issueType: selectedType.name.toLowerCase(),
      };
      dispatch(updateBodyPartIssue(updatedIssue));
    }
  };

  const handleSubmit = () => {
    const currentUserId = isWeightRoomView ? activeAthleteId : userId;
    const currentReportDate = isWeightRoomView ? formattedActiveDate : activeWorkoutDate;
    const currentAccountCode = isWeightRoomView
      ? weightRoomViewData?.[activeWeightroomPanel]?.currentIssues?.[0]?.accountCode
      || currentUser?.accountCode
      : currentUser?.accountCode;

    let submitNewOrUpdated = {
      userId: currentUserId,
      accountCode: currentAccountCode,
      bodyPart: selectedBodyPart.slug,
      issueType: selectedType.name,
      severity: selectedSeverity,
      region: selectedRegion?.name || null,
      status: selectedStatus?.name || null,
      additionalNotes: additionalNotes || null,
      reportedDate: currentReportDate,
    };

    if (foundBodyPart) {
      submitNewOrUpdated = {
        ...submitNewOrUpdated,
        id: foundBodyPart.id,
        clearedDate: foundBodyPart.clearedDate || null,
      };
    }

    const bodyMapData = [submitNewOrUpdated];

    batch(() => {
      if (foundBodyPart) {
        // Edit existing body part issue
        dispatch(updateBodyMapData(
          isWeightRoomView ? currentWeightroomUser : currentUser,
          currentUserId,
          bodyMapData,
          currentReportDate,
          isWeightRoomView,
          isWeightRoomView ? activeWeightroomPanel : null,
        ));
      } else {
        // Submit new body part issue
        dispatch(submitBodyMapData(
          isWeightRoomView ? currentWeightroomUser : currentUser,
          currentUserId,
          bodyMapData,
          currentReportDate,
          isWeightRoomView,
          isWeightRoomView ? activeWeightroomPanel : null,
        ));
      }

      dispatch(setSelectedBodyPart(''));
    });
    setCurrentView('athleteInfo');
  };

  return (
    <Container>
      <SelectionContainer>
        <CustomSelectBox
          options={TYPE_OPTIONS}
          value={selectedType}
          defaultValue={TYPE_OPTIONS[0]}
          onChange={handleTypeChange}
          title='Type'
        />
      </SelectionContainer>
      <SelectionContainer>
        <Text styledTextProps={{ fontWeight: '700' }}>Severity</Text>
        <SeverityScale
          colors={SEVERITY_COLORS}
          selectedSeverity={selectedSeverity}
          onSeverityChange={handleSeverityChange}
          selectedType={selectedType}
        />
      </SelectionContainer>
      {heatMapTagsEnabled && (
        <>
          <SelectionContainer>
            <CustomSelectBox
              options={REGION_OPTIONS}
              value={selectedRegion}
              placeholder='Select a region'
              onChange={setSelectedRegion}
              title='Region (Optional)'
            />
          </SelectionContainer>
          <SelectionContainer>
            <CustomSelectBox
              options={STATUS_OPTIONS}
              value={selectedStatus}
              placeholder='Select a status'
              onChange={setSelectedStatus}
              title='Status (Optional)'
            />
          </SelectionContainer>
        </>
      )}
      <SelectionContainer>
        <Text styledTextProps={{ fontWeight: '700' }} styledWrapperProps={{ marginBottom: '5px' }}>
          Additional Notes (Optional)
        </Text>
        <CustomTextArea
          maxLength={NOTE_MAX_LENGTH}
          colors={textAreaColors}
          value={additionalNotes}
          onChange={(e) => setAdditionalNotes(e.target.value)}
        />
      </SelectionContainer>
      <ButtonContainer>
        <Button
          name='SAVE'
          onClick={handleSubmit}
          disabled={!selectedSeverity}
          styledTextProps={{
            fontSize: '16px',
            fontWeight: '700',
            color: !selectedSeverity ? '#757575' : '#fff',
          }}
          styledWrapperProps={{
            style: {
              width: '100%',
              backgroundColor: !selectedSeverity ? '#E0E0E0' : '#444',
              padding: '6px 12px',
              borderRadius: '4px',
              border: 'none',
              cursor: !selectedSeverity ? 'not-allowed' : 'pointer',
            },
          }}
        />
      </ButtonContainer>
    </Container>
  );
};

ReportForm.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
    accountCode: PropTypes.number.isRequired,
  }).isRequired,
  userId: PropTypes.number.isRequired,
  setCurrentView: PropTypes.func.isRequired,
  foundBodyPart: PropTypes.shape({
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    issueType: PropTypes.string.isRequired,
    severity: PropTypes.number.isRequired,
    region: PropTypes.string,
    status: PropTypes.string,
    additionalNotes: PropTypes.string,
    reportedDate: PropTypes.string.isRequired,
    clearedDate: PropTypes.string,
  }),
  heatMapTagsEnabled: PropTypes.bool.isRequired,
};

ReportForm.defaultProps = {
  foundBodyPart: null,
};

export default ReportForm;
