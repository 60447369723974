import React from 'react';
import { useSelector } from 'react-redux';
import { animated } from 'react-spring';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AthleteInfo from './AthleteInfo';
import ReportForm from './ReportForm';

const BottomSheetContainer = styled(animated.div)`
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ background }) => background || '#fff'};
  z-index: 1001;
  max-height: 90vh;
`;

const DragContainer = styled.div`
  display: flex;
  padding: 0px 130px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
`;

const DragHandleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 32px;
  cursor: grab;
`;

const DragHandle = styled.div`
  width: 30px;
  height: 4px;
  background: #9E9E9E;
  border-radius: 50px;
`;

const ReportFormContainer = styled.div`
  padding: 16px 20px;
`;

const BottomSheet = ({
  background,
  currentUser,
  userId,
  currentView,
  setCurrentView,
  bodyMapData,
  scrollRef,
  heatMapTagsEnabled,
}) => {
  const selectedBodyPart = useSelector(
    (state) => state.recoveryRadarTracker.data.selectedBodyPart,
  );

  const foundBodyPart = bodyMapData?.currentIssues?.find(
    (item) => item.bodyPart === selectedBodyPart.slug,
  );

  const handleToggleScroll = () => {
    if (scrollRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

      if (scrollTop + clientHeight < scrollHeight - 10) {
        scrollRef.current.scrollTo({
          top: scrollHeight,
          behavior: 'smooth',
        });
      } else {
        scrollRef.current.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <BottomSheetContainer background={background}>
      <DragContainer>
        <DragHandleWrapper onClick={handleToggleScroll}>
          <DragHandle />
        </DragHandleWrapper>
      </DragContainer>
      {currentView === 'athleteInfo' ? (
        <AthleteInfo
          bodyMapData={bodyMapData}
          setCurrentView={setCurrentView}
        />
      ) : (
        <ReportFormContainer>
          <ReportForm
            currentUser={currentUser}
            userId={userId}
            setCurrentView={setCurrentView}
            foundBodyPart={foundBodyPart}
            heatMapTagsEnabled={heatMapTagsEnabled}
          />
        </ReportFormContainer>
      )}
    </BottomSheetContainer>
  );
};

BottomSheet.propTypes = {
  background: PropTypes.string,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }),
  userId: PropTypes.number,
  currentView: PropTypes.string.isRequired,
  setCurrentView: PropTypes.func.isRequired,
  bodyMapData: PropTypes.shape({
    currentIssues: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        bodyPart: PropTypes.string.isRequired,
        issueType: PropTypes.string.isRequired,
        severity: PropTypes.number.isRequired,
      }),
    ).isRequired,
    previousIssues: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        bodyPart: PropTypes.string.isRequired,
        issueType: PropTypes.string.isRequired,
        severity: PropTypes.number.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  scrollRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }).isRequired,
  heatMapTagsEnabled: PropTypes.bool.isRequired,
};

BottomSheet.defaultProps = {
  currentUser: null,
  userId: null,
  background: '#ffffff',
};

export default BottomSheet;
