/* eslint-disable no-tabs */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import moment from 'moment';

import WorkoutsList from './WorkoutsList';
import TopWrapperContainer from './TopWrapperContainer';
import RecoveryRadarButton from '../../../recovery-radar-tracker/components/presentational/RecoveryRadarButton';

import { setIsRecoveryRadarWorkoutModalShowing } from '../../../recovery-radar-tracker/ducks/recoveryRadarTrackerActions';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const CenterColumnWrapper = styled('div')`
	display: flex;
	width: 50%;
  margin-left: 30px;
  margin-right: 30px;
  flex-direction: column;
  min-width: 350px;
  @media only screen and (max-width : 768px) {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  @media only screen and (min-width : 769px) and (max-width : 820px) {
    width: 80%;
  }
`;

const WorkoutsListWrapper = styled('div')`
	display: flex;
  justify-content: center;
  background: white;
  width: 100%;
  margin-top: 30px;
  border-radius: 24px;
  box-shadow: ${BoxShadow};
`;

const WorkoutsCenterColumn = ({ sharedAthlete }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const ssHubEnabled = currentUser?.userOrgSSHubSettings?.ssHubEnabled;

  const activeWorkoutDate = useSelector((state) => state.workouts.data.activeWorkoutDate);
  const today = moment().format('YYYY-MM-DD');
  const isWithinSevenDays = moment(activeWorkoutDate).isSameOrBefore(today)
  && moment(today).diff(moment(activeWorkoutDate), 'days') <= 7;

  const handleRecoveryRadarClick = () => {
    dispatch(setIsRecoveryRadarWorkoutModalShowing(true));
  };

  return (
    <CenterColumnWrapper>
      <TopWrapperContainer />
      {sharedAthlete?.id && isWithinSevenDays && ssHubEnabled && (
        <RecoveryRadarButton context='workoutEntry' handleClick={handleRecoveryRadarClick} />
      )}
      <WorkoutsListWrapper>
        <WorkoutsList />
      </WorkoutsListWrapper>
    </CenterColumnWrapper>
  );
};

WorkoutsCenterColumn.propTypes = {
  sharedAthlete: PropTypes.shape({
    id: PropTypes.number,
  }),
};

WorkoutsCenterColumn.defaultProps = {
  sharedAthlete: null,
};

export default WorkoutsCenterColumn;
