import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import Helmet from 'react-helmet';
import { batch, useDispatch, useSelector } from 'react-redux';
import LogRocket from 'logrocket';
import Logger from 'js-logger';

import { useReactToPrint } from 'react-to-print';
import Layout from '../../shared/components/Layout/Layout';
import ReportingSidebar from './components/ReportingSidebar';
import ReportingGrid from './components/ReportingGrid';
import ActiveReport from './components/ActiveReport';
import {
  setIsPrinting,
  setModalOpen,
  setReportReadyForPrint,
  setShowAllForDownload,
} from './ducks/reportingActions';
import { reportInteractionTracker } from '../../shared/utils/amplitudeHelper';

const PageWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  height: calc(100% - 49px);
  margin-top: 49px;
  cursor: default;

  @media screen and (max-width: 767px) {
    justify-content: center;
  }
  @media print {
    height: auto !important;
    overflow: visible !important;
  }
`;

const Reporting = () => {
  const activeReport = useSelector((state) => state.reporting.ui.activeReport);
  const activeModal = useSelector((state) => state.reporting.ui.activeModal);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const evaluationReportForm = useSelector((state) => state.reporting.data.evaluationReportForm);
  const evaluationReportOverview = useSelector(
    (state) => state.reporting.data.evaluationReportOverview,
  );
  const maxReport = useSelector((state) => state.reporting.data.maxReport);
  const questionnaireReport = useSelector((state) => state.reporting.data.questionnaireReport);
  const reportReadyForPrint = useSelector((state) => state.reporting.data.reportReadyForPrint);

  const dispatch = useDispatch();

  // printRef will be passed down to the ActiveReport in whichever element we want to print.
  // For comparison report, we pass it to the table element.
  const printRef = useRef(null);

  const handleReactPrint = useReactToPrint({
    content: () => printRef.current,
    onBeforeGetContent: () => {
      // Add print-specific styles
      if (activeReport === 'completion') {
        const style = document.createElement('style');
        style.textContent = `
          @page {
            size: A4 landscape;
            margin: 2mm;
          }
          body {
            -webkit-print-color-adjust: exact;
          }
          .stylesDiv {
            zoom: 82%;
          }
        `;
        document.head.appendChild(style);
      }
      if (activeReport === 'evaluation') {
        const cols = evaluationReportOverview.results[0].results.length;
        const hasSecondDate = evaluationReportForm.secondStartDate.date;

        // We are hiding any columns after the 10th one while printing
        // Anything over a cols length of 10 should get treated as 10
        let overviewZoom = '30%';
        let avgsZoom = hasSecondDate ? '16%' : '28%';

        switch (cols) {
          case 10:
            overviewZoom = '30%';
            avgsZoom = hasSecondDate ? '15%' : '28%';
            break;
          case 9:
            overviewZoom = '33%';
            avgsZoom = hasSecondDate ? '17%' : '30%';
            break;
          case 8:
            overviewZoom = '36%';
            avgsZoom = hasSecondDate ? '18%' : '33%';
            break;
          case 7:
            overviewZoom = '40%';
            avgsZoom = hasSecondDate ? '20%' : '36%';
            break;
          case 6:
            overviewZoom = '45%';
            avgsZoom = hasSecondDate ? '22%' : '40%';
            break;
          case 5:
            overviewZoom = '51%';
            avgsZoom = hasSecondDate ? '26%' : '41%';
            break;
          case 4:
            overviewZoom = '60%';
            avgsZoom = hasSecondDate ? '28%' : '50%';
            break;
          case 3:
            overviewZoom = '72%';
            avgsZoom = hasSecondDate ? '32%' : '56%';
            break;
          case 2:
            overviewZoom = '88%';
            avgsZoom = hasSecondDate ? '40%' : '60%';
            break;
          case 1:
            overviewZoom = 'unset';
            avgsZoom = hasSecondDate ? '50%' : '75%';
            break;
          default:
            break;
        }

        const style = document.createElement('style');
        style.textContent = `
          @page {
            size: A4 landscape;
            margin: 2mm;
          }
          body {
            -webkit-print-color-adjust: exact;
            overflow: visible !important;
          }
          .hideOnPrint {
            display: none !important;
          }
          td:has(.hideOnPrint) {
            display: none !important;
          }
          #evalBodyRow {
            overflow: visible !important;
          }
          #groupAnalysisTable {
            overflow: visible;
            zoom: ${avgsZoom};
          }
          .noHoverCategoryColumnTitleCell, .noHoverCategoryColumnSubtitleCell, .rawValueTitle {
            position: unset !important;
          }
          #overviewTable {
            zoom: ${overviewZoom};
          }
          table {
            page-break-before: avoid;
          }
          tr {
            page-break-inside: avoid;
            page-break-after: auto;
          }
        `;
        document.head.appendChild(style);
      }
      if (activeReport === 'max') {
        const cols = maxReport.data.columns.length;
        let zoom = 'unset';
        switch (cols) {
          case 16:
            zoom = '60%';
            break;
          case 15:
            zoom = '62%';
            break;
          case 14:
            zoom = '68%';
            break;
          case 13:
          case 12:
            zoom = '72%';
            break;
          case 11:
            zoom = '88%';
            break;
          case 10:
            zoom = '89%';
            break;
          case 9:
            zoom = '63%';
            break;
          case 8:
            zoom = '95%';
            break;
          case 7:
            zoom = '96%';
            break;
          case 6:
          case 5:
          case 4:
            zoom = '97%';
            break;
          default:
            break;
        }

        const style = document.createElement('style');
        style.textContent = `
          @page {
            size: A4 landscape;
            margin: 2mm;
          }
          body {
            -webkit-print-color-adjust: exact;
          }
          .maxTablePrint {
            overflow: unset;
            zoom: ${zoom};
          }
        `;
        document.head.appendChild(style);
      }
      if (activeReport === 'opt_out_notes') {
        const style = document.createElement('style');
        style.textContent = `
          @page {
            size: A4 landscape;
            margin: 2mm;
          }
          body {
            -webkit-print-color-adjust: exact;
          }
          .individualResult {
            break-inside: avoid;
          }
          .printableResultsWrapper {
            padding: 0px !important;
            zoom: 85%;
          }
          .selectedAthleteContainer {
            display: none;
          }
        `;
        document.head.appendChild(style);
      }
      if (activeReport === 'questionnaire') {
        const numOfCols = questionnaireReport.values.arrayOfQuestions.length
        + (questionnaireReport.values.showTotal ? 1 : 0);

        const isMulti = questionnaireReport.values.reportType === 'multiDay';

        let zoomPercentage = 'unset';
        switch (numOfCols) {
          case 11:
            zoomPercentage = '33.5%';
            break;
          case 10:
            zoomPercentage = '37%';
            break;
          case 9:
            zoomPercentage = '40%';
            break;
          case 8:
          case 7:
            zoomPercentage = isMulti ? '43%' : '44%';
            break;
          case 6:
            zoomPercentage = isMulti ? '59%' : '50%';
            break;
          case 5:
            zoomPercentage = isMulti ? '69%' : '66%';
            break;
          case 4:
            zoomPercentage = isMulti ? '80%' : '83%';
            break;
          case 3:
            zoomPercentage = isMulti ? '80%' : '84%';
            break;
          case 2:
            zoomPercentage = isMulti ? '80%' : '94%';
            break;
          case 1:
            zoomPercentage = isMulti ? '80%' : '95%';
            break;
          default:
            break;
        }

        if (isMulti) {
          const style = document.createElement('style');
          style.textContent = `
            @page {
              size: A4 landscape;
              margin: 2mm;
            }
            body {
              -webkit-print-color-adjust: exact;
            }
            div:has(a) {
              overlow: unset !important;
              text-overflow: unset !important;
              white-space: unset !important;
            }
            .sticky {
              width: unset !important;
              max-width: unset !important;
            }
            .stylesDiv {
              height: unset !important;
              width: unset !important;
            }
            .scrollAndBoxShadowDiv {
              overflow: visible !important;
              zoom: ${zoomPercentage};
            }
            .paginationButton {
              display: none !important;
            }
          `;
          document.head.appendChild(style);
        } else {
          const style = document.createElement('style');
          style.textContent = `
            @page {
              size: A4 landscape;
              margin: 2mm;
            }
            body {
              -webkit-print-color-adjust: exact;
            }
            div:has(a) {
              overlow: unset !important;
              text-overflow: unset !important;
              white-space: unset !important;
            }
            .sticky {
              width: unset !important;
              max-width: unset !important;
            }
            .stylesDiv {
              height: unset !important;
              max-height: none !important;
              overflow: visible !important;
              width: unset !important;
              zoom: ${zoomPercentage};
            }
          `;
          document.head.appendChild(style);
        }
      }
      if (activeReport === 'workout_results') {
        // Zooming is happening in the ReportSection file on the table
        const style = document.createElement('style');
        style.textContent = `
          @page {
            size: A4 landscape;
            margin: 2mm;
          }
          body {
            -webkit-print-color-adjust: exact;
          }
          .selectedAthleteContainer {
            display: none;
          }
        `;
        document.head.appendChild(style);
      }

      return null;
    },
    onBeforePrint: () => {
      // onBeforePrint logic
    },
    onAfterPrint: () => {
      if (activeReport === 'completion' || activeReport === 'evaluation' || activeReport === 'max' || activeReport === 'opt_out_notes' || activeReport === 'questionnaire' || activeReport === 'workout_results') {
        // Clear added style element after print/cancel
        document.head.removeChild(document.head.lastChild);
      }

      // Amplitude tracking
      reportInteractionTracker('Report Printed', activeReport);

      // Reseting showAllForDownload resets comparison report table size to 50
      // for proper pagination and resets reportReadyForPrint to false.
      batch(() => {
        dispatch(setShowAllForDownload(false));
        dispatch(setReportReadyForPrint(false));
        dispatch(setIsPrinting(false));
      });
    },
  });

  useEffect(() => {
    // Opening download modal for comparison report also
    // triggers cascade that sets readyForPrint to true.
    if (reportReadyForPrint && ((activeReport === 'comparison' && activeModal !== 'downloadComparisonReport'))) {
      handleReactPrint();
    }
  }, [reportReadyForPrint, activeModal]);

  /*
  This is the click function triggered by the print button in ActiveReportSidebar.
  For Comparison Report, asynchronous state updates
  are required to prepare the table to be printed properly.
  handlePrint sets showAllForDownload true, changing the table's page size to include all data rows.
  Once this is completed, reportReadyToPrint is set to true,
  triggering the useEffect above (handleReactPrint handles the actual print functionality).
  For other reports, if state updates are not required to prepare printRef,
  handleReactPrint maybe be called directly inside handlePrint.
  */
  const handlePrintClick = () => {
    if (activeReport === 'comparison') {
      batch(() => {
        dispatch(setShowAllForDownload(true));
        // Avoid unwanted overlapping interaction with download functionality
        dispatch(setModalOpen(false, ''));
      });
    } else if (activeReport === 'evaluation' || activeReport === 'max' || activeReport === 'questionnaire') {
      // These reports need time to fire other functions so everything is visible when printing
      dispatch(setIsPrinting(true));
      setTimeout(handleReactPrint, 500);
    } else {
      dispatch(setIsPrinting(true));
      handleReactPrint();
    }
  };

  if (
    process.env.ENVIRONMENT === 'PRODUCTION'
    && currentUser.userSettings
    && currentUser.userSettings.sessionLoggingEnabled === 1
  ) {
    LogRocket.init('v2xspp/tb-reporting');
    Logger.debug('Logrocket Enabled v1.2.00');
    LogRocket.identify(currentUser && currentUser.id, {
      name: currentUser && `${currentUser.first} ${currentUser.last}`,
      email: currentUser && currentUser.email,
      organizationId: currentUser && currentUser.organizationId,
      organizationAccessLevel:
        currentUser && currentUser.organizationAccessLevel,
      subscriptionId: currentUser && currentUser.organizationSubscriptionId,
      appVersion: '1.2.00',
    });
  }

  return (
    <Layout fullWidth>
      <Helmet>
        <title>Reporting | TeamBuildr</title>
      </Helmet>
      <PageWrapper>
        <ReportingSidebar handlePrint={handlePrintClick} />
        {activeReport === '' ? (
          <ReportingGrid />
        ) : (
          <ActiveReport printRef={printRef} handlePrint={handlePrintClick} />
        )}
      </PageWrapper>
    </Layout>
  );
};

export default Reporting;
