import {
  FETCH_SELECTIONS_START,
  FETCH_SELECTIONS_FULFILLED,
  FETCH_SELECTIONS_REJECTED,
  FETCH_MAXREPORT_START,
  FETCH_MAXREPORT_FULFILLED,
  FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_START,
  FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_FULFILLED,
  FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_REJECTED,
  FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_START,
  FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_FULFILLED,
  FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_REJECTED,
  SET_ACTIVE_REPORT,
  SET_MODAL_OPEN,
  SET_SIDEBAR_WIDTH,
  SET_LAYOUT_HEIGHT,
  SET_SHOW_ALL_FOR_DOWNLOAD,
  CLEAR_REPORT,
  FETCH_MAXREPORT_REJECTED,
  FETCH_ACTIVITY_REPORT_START,
  FETCH_ACTIVITY_REPORT_FULFILLED,
  FETCH_ACTIVITY_REPORT_REJECTED,
  FETCH_RAW_DATA_REPORT_START,
  FETCH_RAW_DATA_REPORT_FULFILLED,
  FETCH_RAW_DATA_REPORT_REJECTED,
  SET_MOBILE_SIDEBAR_ACTIVE,
  FETCH_EVALUATION_REPORT_GA_START,
  FETCH_EVALUATION_REPORT_GA_FULFILLED,
  FETCH_EVALUATION_REPORT_GA_REJECTED,
  SET_IS_REPORT_OPEN,
  FETCH_RESULTS_REPORT_FULFILLED,
  FETCH_RESULTS_REPORT_START,
  FETCH_RESULTS_REPORT_REJECTED,
  FETCH_OPT_OUT_REPORT_START,
  FETCH_OPT_OUT_REPORT_FULFILLED,
  FETCH_OPT_OUT_REPORT_REJECTED,
  FETCH_COMPARISON_REPORT_START,
  FETCH_COMPARISON_REPORT_FULFILLED,
  FETCH_COMPARISON_REPORT_REJECTED,
  FETCH_COMPLETION_REPORT_START,
  FETCH_COMPLETION_REPORT_FULFILLED,
  FETCH_COMPLETION_REPORT_REJECTED,
  DOWNLOAD_COMPLETION_CSV_START,
  DOWNLOAD_COMPLETION_CSV_FULFILLED,
  DOWNLOAD_COMPLETION_CSV_REJECTED,
  SET_IS_PRINTING,
} from './actionTypes';

const initialState = {
  activeReport: '',
  activeModal: '',
  isDownloadingCompletionReport: false,
  isLoadingCompletionReport: false,
  isLoadingEvaluationReport: false,
  isLoadingMaxreport: '',
  isLoadingQuestionnaireReportSingleDay: '',
  isLoadingQuestionnaireReportMultiday: '',
  isLoadingSelections: false,
  isModalOpen: false,
  isMobileSidebarOpen: false,
  hasReportData: false,
  sidebarWidth: 0,
  showAllForDownload: false,
  layoutHeight: 0,
  isPrinting: false,
  isReportOpen: false,
  isSidebarFilterActive: true,
  isActivityReportLoading: false,
  isLoadingRawDataReport: false,
  isDownloadingActivityReport: false,
  isLoadingComparisonReport: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SELECTIONS_START: {
      return {
        ...state,
        isLoadingSelections: true,
      };
    }
    case FETCH_SELECTIONS_FULFILLED: {
      return {
        ...state,
        isLoadingSelections: false,
      };
    }
    case FETCH_SELECTIONS_REJECTED: {
      return {
        ...state,
        isLoadingSelections: false,
      };
    }
    case FETCH_MAXREPORT_START: {
      return {
        ...state,
        isLoadingMaxReport: true,
      };
    }
    case FETCH_MAXREPORT_REJECTED: {
      return {
        ...state,
        isLoadingMaxReport: false,
      };
    }
    case FETCH_MAXREPORT_FULFILLED: {
      return {
        ...state,
        isLoadingMaxReport: false,
        isReportOpen: true,
        hasReportData: true,
        isSidebarFilterActive: false,
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_START: {
      return {
        ...state,
        isLoadingQuestionnaireReportSingleDay: true,
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_REJECTED: {
      return {
        ...state,
        isLoadingQuestionnaireReportSingleDay: false,
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_FULFILLED: {
      return {
        ...state,
        isLoadingQuestionnaireReportSingleDay: false,
        isReportOpen: true,
        hasReportData: true,
        isSidebarFilterActive: false,
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_START: {
      return {
        ...state,
        isLoadingQuestionnaireReportMultiday: true,
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_REJECTED: {
      return {
        ...state,
        isLoadingQuestionnaireReportMultiday: false,
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_FULFILLED: {
      return {
        ...state,
        isLoadingQuestionnaireReportMultiday: false,
        isReportOpen: true,
        hasReportData: true,
        isSidebarFilterActive: false,
      };
    }
    case SET_ACTIVE_REPORT: {
      return {
        ...state,
        activeReport: action.payload,
      };
    }
    case SET_MODAL_OPEN: {
      // If we're closing the modal and there is no reporting data,
      // go back to menu by clearing activeReport, otherwise if we're
      // opening the modal, show the activeReport, regardless of having data to show the form.

      const isModalActive = action.payload.isModalOpen ? (state.activeReport) : ('');

      return {
        ...state,
        activeReport: (!action.payload.isModalOpen && state.hasReportData)
          ? state.activeReport
          : isModalActive,
        activeModal: action.payload.activeModal,
        isModalOpen: action.payload.isModalOpen,
        isReportOpen: ((!action.payload.isModalOpen && state.hasReportData) || (action.payload.activeModal === 'downloadMaxReport' && state.hasReportData) || (action.payload.activeModal === 'downloadQuestionnaireReport' && state.hasReportData) || (action.payload.activeModal === 'downloadEvaluationReport' && state.hasReportData) || (action.payload.activeModal === 'downloadResultsReport' && state.hasReportData) || (action.payload.activeModal === 'downloadOptOutNotesReport' && state.hasReportData) || (action.payload.activeModal === 'downloadComparisonReport' && state.hasReportData) || (action.payload.activeModal === 'downloadCompletionReport' && state.hasReportData)) && true,
        isSidebarFilterActive: action.payload.activeModal !== 'downloadMaxReport' && true,
      };
    }
    case SET_SIDEBAR_WIDTH: {
      return {
        ...state,
        sidebarWidth: action.payload,
      };
    }
    case SET_LAYOUT_HEIGHT: {
      return {
        ...state,
        layoutHeight: action.payload,
      };
    }
    case CLEAR_REPORT: {
      return {
        ...state,
        activeReport: '',
        activeModal: '',
        isReportOpen: false,
        hasReportData: false,
      };
    }
    case FETCH_ACTIVITY_REPORT_START: {
      return {
        ...state,
        isActivityReportLoading: true,
      };
    }
    case FETCH_ACTIVITY_REPORT_FULFILLED: {
      return {
        ...state,
        isActivityReportLoading: false,
        isReportOpen: true,
        hasReportData: true,
        isSidebarFilterActive: false,
      };
    }
    case FETCH_ACTIVITY_REPORT_REJECTED: {
      return {
        ...state,
        isActivityReportLoading: false,
        activeReport: '',
        isReportOpen: false,
      };
    }
    case FETCH_RESULTS_REPORT_START: {
      return {
        ...state,
        isResultsReportLoading: true,
      };
    }
    case FETCH_RESULTS_REPORT_FULFILLED: {
      return {
        ...state,
        isResultsReportLoading: false,
        isReportOpen: true,
        hasReportData: true,
        isSidebarFilterActive: false,
      };
    }
    case FETCH_RESULTS_REPORT_REJECTED: {
      return {
        ...state,
        isResultsReportLoading: false,
        activeReport: '',
        isReportOpen: false,
      };
    }
    case FETCH_OPT_OUT_REPORT_START: {
      return {
        ...state,
        isOptOutReportLoading: true,
        hasReportData: false,
      };
    }
    case FETCH_OPT_OUT_REPORT_FULFILLED: {
      return {
        ...state,
        isOptOutReportLoading: false,
        isReportOpen: true,
        hasReportData: true,
        isSidebarFilterActive: false,
      };
    }
    case FETCH_OPT_OUT_REPORT_REJECTED: {
      return {
        ...state,
        isOptOutReportLoading: false,
        activeReport: '',
        isReportOpen: false,
      };
    }
    case FETCH_RAW_DATA_REPORT_START: {
      return {
        ...state,
        isLoadingRawDataReport: true,
      };
    }
    case FETCH_RAW_DATA_REPORT_REJECTED: {
      return {
        ...state,
        isLoadingRawDataReport: false,
      };
    }
    case FETCH_RAW_DATA_REPORT_FULFILLED: {
      return {
        ...state,
        isLoadingRawDataReport: false,
      };
    }
    case SET_MOBILE_SIDEBAR_ACTIVE: {
      return {
        ...state,
        isMobileSidebarOpen: state.isMobileSidebarOpen && false,
      };
    }
    case FETCH_EVALUATION_REPORT_GA_START: {
      return {
        ...state,
        isLoadingEvaluationReport: true,
      };
    }
    case FETCH_EVALUATION_REPORT_GA_FULFILLED: {
      return {
        ...state,
        isLoadingEvaluationReport: false,
        isReportOpen: true,
        hasReportData: true,
        isSidebarFilterActive: false,
      };
    }
    case FETCH_EVALUATION_REPORT_GA_REJECTED: {
      return {
        ...state,
        isLoadingEvaluationReport: false,
      };
    }
    case SET_SHOW_ALL_FOR_DOWNLOAD: {
      return {
        ...state,
        showAllForDownload: action.payload,
      };
    }
    case SET_IS_REPORT_OPEN: {
      return {
        ...state,
        isReportOpen: action.payload.bool,
        reportType: action.payload.type,
        activeModal: '',
        isModalOpen: false,
      };
    }
    case FETCH_COMPARISON_REPORT_START: {
      return {
        ...state,
        isLoadingComparisonReport: true,
      };
    }
    case FETCH_COMPARISON_REPORT_FULFILLED: {
      return {
        ...state,
        isLoadingComparisonReport: false,
        isReportOpen: true,
        hasReportData: true,
        isSidebarFilterActive: false,
      };
    }
    case FETCH_COMPARISON_REPORT_REJECTED: {
      return {
        ...state,
        isLoadingComparisonReport: false,
      };
    }
    case FETCH_COMPLETION_REPORT_START: {
      return {
        ...state,
        isLoadingCompletionReport: true,
      };
    }
    case FETCH_COMPLETION_REPORT_FULFILLED: {
      return {
        ...state,
        hasReportData: true,
        isLoadingCompletionReport: false,
        isReportOpen: true,
        isSidebarFilterActive: false,
      };
    }
    case FETCH_COMPLETION_REPORT_REJECTED: {
      return {
        ...state,
        isLoadingCompletionReport: false,
      };
    }
    case DOWNLOAD_COMPLETION_CSV_START: {
      return {
        ...state,
        isDownloadingCompletionReport: true,
      };
    }
    case DOWNLOAD_COMPLETION_CSV_FULFILLED: {
      return {
        ...state,
        isDownloadingCompletionReport: false,
      };
    }
    case DOWNLOAD_COMPLETION_CSV_REJECTED: {
      return {
        ...state,
        isDownloadingCompletionReport: false,
      };
    }
    case SET_IS_PRINTING: {
      return {
        ...state,
        isPrinting: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
