import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Text from '../../../../shared/_TBComponents/Text';
import CustomButton from './CustomButton';

const ScaleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 16px 0 8px 0;
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const SeverityScale = ({
  colors, selectedSeverity, onSeverityChange, selectedType,
}) => (
  <>
    <ScaleContainer>
      {[1, 2, 3, 4, 5, 6, 7].map((severity) => (
        <CustomButton
          key={severity}
          isActive={selectedSeverity === severity}
          activeColor={colors[severity - 1]}
          inactiveColor='#ffffff'
          borderColor='#E0E0E0'
          onClick={() => onSeverityChange(severity)}
        >
          {severity}
        </CustomButton>
      ))}
    </ScaleContainer>
    <LabelContainer>
      <Text styledTextProps={{ fontSize: '13px', color: '#BDBDBD' }}>
        {selectedType?.name === 'Pain' ? 'Not Painful' : 'Not Sore'}
      </Text>
      <Text styledTextProps={{ fontSize: '13px', color: '#BDBDBD' }}>
        {selectedType?.name === 'Pain' ? 'Extremely Painful' : 'Extremely Sore'}
      </Text>
    </LabelContainer>
  </>
);

SeverityScale.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedSeverity: PropTypes.number,
  onSeverityChange: PropTypes.func.isRequired,
  selectedType: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

SeverityScale.defaultProps = {
  selectedSeverity: null,
};

export default SeverityScale;
