/* eslint-disable no-nested-ternary */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-array-index-key */
import React, {
  useEffect,
} from 'react';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useInView } from 'react-intersection-observer';

import iconSet from '../../../../shared/images/teambuildr-selection.json';

const TableWrapper = styled('div')`
  break-inside: avoid;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 50px;
  margin-bottom: ${(props) => (props.last ? '200px' : '0px')};
  font-family: 'Nunito Sans';
`;

const TableHeader = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 70px;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const Table = styled('div')`
  background: white;
  border-radius: 12px;
  overflow: hidden;
  width: 100%;

  // Handling print logic here instead of Reporting.js
  @media print {
    zoom: ${(props) => (props.numOfCols === 11 ? '44%'
    : props.numOfCols === 10 ? '48%'
      : props.numOfCols === 9 ? '53%'
        : props.numOfCols === 8 ? '59%'
          : props.numOfCols === 7 ? '67%'
            : props.numOfCols === 6 ? '78%' : '')};
  }
`;

const TableTopRow = styled('div')`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
`;

const TableRow = styled('div')`
  width: 100%;
  display: flex;
`;

const NameCell = styled('div')`
  display: flex;
  flex: 1;
  min-height: 40px;
  padding: 20px;
  border: 1px solid #EEEEEE;
  flex-direction: column;
  justify-content: ${(props) => (props.firstRow ? 'center' : 'none')};
  background: ${(props) => (props.firstRow ? '#FCFCFC' : '#FFFFFF')};
  min-width: 300px;
  max-width: 300px;
`;

const PrimaryNameCellValue = styled('div')`
  min-height: 30px;
  display: flex;
  align-items: ${(props) => (props.firstRow ? 'center' : 'none')};
  color: ${(props) => (props.firstRow ? '#444444' : props.isClickable ? '#5999D0' : props.theme.colors.text)};
  cursor: ${(props) => ((props.firstRow || !props.isClickable) ? 'default' : 'pointer')};
`;

const SetCell = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  border: 1px solid #EEEEEE;
  padding: 20px;
  background: ${(props) => (props.firstRow ? '#FCFCFC' : props.background)};
  min-width: 200px;
`;

const SetCellValue = styled('div')`
  min-height: ${(props) => (props.firstRow ? '30px' : '56px')};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CenteredSetCellValue = styled('div')`
  min-height: ${(props) => (props.firstRow ? '30px' : '56px')};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const DetailedCellValues = styled('div')`
  width: 100%;
  height: 100%;
`;

const NoExercisesBlock = styled('div')`
  font-family: 'Nunito Sans';
  font-weight: 700;
  font-size: 24px;
  color: #939393;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderDeet = styled('div')`
  font-size: 20px;
  font-family: 'Nunito Sans';
  font-weight: 700;
  color: #444444;
`;

const AthleteName = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
  font-weight: 700;
  margin-left: 10px;
`;

const AthleteAndAvatar = styled('div')`
  display: flex;
  align-items: center;
`;

const SecondaryCellValue = styled('div')`
  font-size: 12px;
  font-weight: ${(props) => (props.bold ? 800 : 400)};
  margin-top: ${(props) => (props.bold ? '10px' : '0px')};
`;

const CenteredSecondaryCellValue = styled('div')`
  font-size: 12px;
  text-align: center;
  margin-top: ${(props) => (props.bold ? '10px' : '0px')};
  font-weight: ${(props) => (props.bold ? 800 : 400)};
`;

const ToolTipDiv = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const RefWrapper = styled('div')`

`;

const AthleteAvatar = styled('div')`
  img {
    height: ${(props) => (props.size ? `${props.size}px` : '35px')};
    width: ${(props) => (props.size ? `${props.size}px` : '35px')};
    border-radius: 50%;
  }
`;

const detailedTextMapper = (inputArray) => inputArray.map((detailedTexts, index) => (
  <SecondaryCellValue key={index} bold={detailedTexts.bold}>
    {detailedTexts.value}
  </SecondaryCellValue>
));

const lastDetailedTextMapper = (inputArray) => inputArray.map((detailedTexts, index) => (
  <CenteredSecondaryCellValue key={index} bold={detailedTexts.bold}>
    {detailedTexts.value}
  </CenteredSecondaryCellValue>
));

const TooltipContainer = styled('div')`
  position: relative;
  display: inline-block;
  display: flex;
  justify-content: space-between;
  width: 100%;
  .tooltip-box {
    position: absolute;
    visibility: hidden;
    background-color: #fff;
    color: #333;
    text-align: center;
    border-radius: 5px;
    padding: 5px 10px;
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
  }
  &:hover .tooltip-box {
    visibility: visible;
    opacity: 1;
  }
  .tooltip-top {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: -15px; /* Adjust this value to bring the tooltip down */
  }
  .tooltip-right {
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    margin-left: 10px;
  }
  .tooltip-bottom {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px;
  }
  .tooltip-left {
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    margin-right: 10px;
  }
`;

const Tooltip = ({ children, text, position = 'top' }) => (
  <TooltipContainer>
    {children}
    <div className={`tooltip-box tooltip-${position}`}>
      {text}
    </div>
  </TooltipContainer>
);

const ReportSection = ({
  user, lastUser, setHeaderDate, setCurrentAthlete,
}) => {
  const athleteGenerator = (generatedUser) => (
    <AthleteAndAvatar>
      <AthleteAvatar>
        <img src={generatedUser.pic || 'https://s3.amazonaws.com/teambuildr-staging-assets/pic/1670956695571-38.jpg'} alt='' />
      </AthleteAvatar>
      <AthleteName>
        {`${generatedUser.firstName} ${generatedUser.lastName}`}
      </AthleteName>
    </AthleteAndAvatar>
  );

  const { ref: athleteRef, inView: athleteInView } = useInView({
    rootMargin: '-50px 0px -80% 0px',
    threshold: 0,
  });

  useEffect(() => {
    if (athleteInView) {
      setCurrentAthlete(user);
    }
  }, [athleteInView, setCurrentAthlete, user]);

  const cellValueDisplayer = (displayValue) => {
    if (displayValue === ':white_check_mark:') {
      return (
        <IcomoonReact
          iconSet={iconSet}
          size={16}
          icon='checkmark'
          color='black'
        />
      );
    }
    return displayValue;
  };

  const rowMapper = (row, rowIndex, rows) => {
    const maxCells = rows[0].cells.length;
    const mappedArray = [];

    row.cells.forEach((cell, cellIndex) => {
      if (cellIndex === 0) {
        mappedArray.push(
          <NameCell
            key={cellIndex}
            className='name-cell'
            background={cell.backgroundColor}
            firstRow={rowIndex === 0}
          >
            <PrimaryNameCellValue onClick={() => (cell.link ? window.open(cell.link, '_blank') : null)} isClickable={cell.link !== null} firstRow={rowIndex === 0}>
              {cell.displayValue}
            </PrimaryNameCellValue>
            {cell.detailedTexts && cell.detailedTexts.length
              ? detailedTextMapper(cell.detailedTexts)
              : null}
          </NameCell>,
        );
      } else if (cellIndex === row.cells.length - 1) {
        mappedArray.push(
          <SetCell
            key={cellIndex}
            firstRow={rowIndex === 0}
            className='set-cell'
            background={
              cell.displayValue === ':white_check_mark:' || cell.displayValue === 'Mastered' ? '#DFF0D8' : cell.backgroundColor
            }
          >
            <CenteredSetCellValue firstRow={rowIndex === 0}>
              {cellValueDisplayer(cell.displayValue)}
            </CenteredSetCellValue>
            <DetailedCellValues>
              {cell.detailedTexts && cell.detailedTexts.length
                ? lastDetailedTextMapper(cell.detailedTexts)
                : null}
            </DetailedCellValues>
          </SetCell>,
        );
      } else {
        mappedArray.push(
          <SetCell
            key={cellIndex}
            firstRow={rowIndex === 0}
            className='set-cell'
            background={
              cell.displayValue === ':white_check_mark:' || cell.displayValue === 'Mastered' ? '#DFF0D8' : cell.backgroundColor
            }
          >
            <SetCellValue firstRow={rowIndex === 0}>
              {cellValueDisplayer(cell.displayValue)}
            </SetCellValue>
            <DetailedCellValues>
              {cell.detailedTexts && cell.detailedTexts.length
                ? detailedTextMapper(cell.detailedTexts)
                : null}
            </DetailedCellValues>
          </SetCell>,
        );
      }
    });

    const rowCells = row.cells.length;
    const leftoverCells = maxCells - rowCells;

    for (let i = 0; i < leftoverCells; i += 1) {
      mappedArray.push(
        <SetCell
          key={`leftoverCell${i}`}
          firstRow={rowIndex === 0}
          background='#F0F0F0'
          className='set-cell'
        />,
      );
    }

    return mappedArray;
  };

  return (
    <RefWrapper ref={athleteRef}>
      {user.dates.map((dateItem, index) => {
        const { ref: dateRef, inView: dateInView } = useInView({
          rootMargin: '-50px 0px -80% 0px',
          threshold: 0,
        });
        if (dateInView) {
          setHeaderDate(moment(dateItem.date).format('ll'));
        }
        return (
          <TableWrapper
            key={index}
            id={`${user.userId}report`}
            last={index === user.dates.length - 1 && lastUser}
            ref={dateRef}
          >
            <Tooltip text={`Number of Exercises: ${dateItem.reportTable.rows.length - 1}`} position='top'>
              <TableHeader
                className='TableHeader'
              >
                <ToolTipDiv>
                  {index === 0 ? athleteGenerator(user) : <div />}
                  <HeaderDeet>{moment(dateItem.date).format('ll')}</HeaderDeet>
                </ToolTipDiv>
              </TableHeader>
            </Tooltip>
            {dateItem.reportTable.rows.length ? (
              <Table numOfCols={dateItem.reportTable.rows[0].cells.length}>
                <TableTopRow className='table-top-row'>
                  {dateItem.reportTable.rows.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {rowMapper(row, rowIndex, dateItem.reportTable.rows)}
                    </TableRow>
                  ))}
                </TableTopRow>
              </Table>
            ) : <NoExercisesBlock>No Exercises Completed Yet</NoExercisesBlock>}
          </TableWrapper>
        );
      })}
    </RefWrapper>
  );
};

ReportSection.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  setHeaderDate: PropTypes.func.isRequired,
  setCurrentAthlete: PropTypes.func.isRequired,
  lastUser: PropTypes.bool.isRequired,
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
};

Tooltip.defaultProps = {
  position: 'top',
};

export default ReportSection;
