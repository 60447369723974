import {
  SET_IS_RECOVERY_RADAR_WORKOUT_MODAL_SHOWING,
  SET_IS_RECOVERY_RADAR_WEIGHTROOM_MODAL_SHOWING,
  FETCH_BODY_MAP_DATA_BY_DATE_START,
  FETCH_BODY_MAP_DATA_BY_DATE_FULFILLED,
  FETCH_BODY_MAP_DATA_BY_DATE_REJECTED,
  SUBMIT_BODY_MAP_DATA_START,
  SUBMIT_BODY_MAP_DATA_FULFILLED,
  SUBMIT_BODY_MAP_DATA_REJECTED,
  UPDATE_BODY_MAP_DATA_START,
  UPDATE_BODY_MAP_DATA_FULFILLED,
  UPDATE_BODY_MAP_DATA_REJECTED,
  SET_IS_REMOVE_MODAL_SHOWING,
  SET_REMOVE_ALL_BODY_MAP_DATA,
  DELETE_BODY_MAP_DATA_START,
  DELETE_BODY_MAP_DATA_FULFILLED,
  DELETE_BODY_MAP_DATA_REJECTED,
  SET_IS_ONBOARDING_MODAL_SHOWING,
} from './actionTypes';

const intialState = {
  isRecoveryRadarWorkoutModalShowing: false,
  isRecoveryRadarWeightroomModalShowing: false,
  isSubmittingBodyMapData: false,
  isBodyMapDataLoading: false,
  isRemoveModalShowing: false,
  removeAllBodyMapData: false,
  isDeletingBodyMapData: false,
  isOnboardingModalShowing: false,
};

export default function reducer(state = intialState, action) {
  switch (action.type) {
    case SET_IS_RECOVERY_RADAR_WORKOUT_MODAL_SHOWING:
      return {
        ...state,
        isRecoveryRadarWorkoutModalShowing: action.payload,
      };
    case SET_IS_RECOVERY_RADAR_WEIGHTROOM_MODAL_SHOWING:
      return {
        ...state,
        isRecoveryRadarWeightroomModalShowing: action.payload,
      };
    case FETCH_BODY_MAP_DATA_BY_DATE_START:
      return {
        ...state,
        isBodyMapDataLoading: true,
      };
    case FETCH_BODY_MAP_DATA_BY_DATE_FULFILLED:
      return {
        ...state,
        isBodyMapDataLoading: false,
      };
    case FETCH_BODY_MAP_DATA_BY_DATE_REJECTED:
      return {
        ...state,
        isBodyMapDataLoading: false,
      };
    case SUBMIT_BODY_MAP_DATA_START:
      return {
        ...state,
        isSubmittingBodyMapData: true,
      };
    case SUBMIT_BODY_MAP_DATA_FULFILLED:
      return {
        ...state,
        isSubmittingBodyMapData: false,
      };
    case SUBMIT_BODY_MAP_DATA_REJECTED:
      return {
        ...state,
        isSubmittingBodyMapData: false,
      };
    case UPDATE_BODY_MAP_DATA_START:
      return {
        ...state,
        isSubmittingBodyMapData: true,
      };
    case UPDATE_BODY_MAP_DATA_FULFILLED:
      return {
        ...state,
        isSubmittingBodyMapData: false,
      };
    case UPDATE_BODY_MAP_DATA_REJECTED:
      return {
        ...state,
        isSubmittingBodyMapData: false,
      };
    case SET_IS_REMOVE_MODAL_SHOWING:
      return {
        ...state,
        isRemoveModalShowing: action.payload,
      };
    case SET_REMOVE_ALL_BODY_MAP_DATA:
      return {
        ...state,
        removeAllBodyMapData: action.payload,
      };
    case DELETE_BODY_MAP_DATA_START:
      return {
        ...state,
        isDeletingBodyMapData: true,
      };
    case DELETE_BODY_MAP_DATA_FULFILLED:
      return {
        ...state,
        isDeletingBodyMapData: false,
      };
    case DELETE_BODY_MAP_DATA_REJECTED:
      return {
        ...state,
        isDeletingBodyMapData: false,
      };
    case SET_IS_ONBOARDING_MODAL_SHOWING:
      return {
        ...state,
        isOnboardingModalShowing: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
}
