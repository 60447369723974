import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Text from '../../../../shared/_TBComponents/Text';
import Avatar from '../../../../shared/_TBComponents/Avatar/Avatar';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AvatarContainer = styled.div`
  display: flex;
`;

const AthleteDetails = ({
  context,
  bodyMapData,
}) => {
  const sharedAthlete = useSelector((state) => state.workouts.data.sharedAthlete);
  const activeWeightroomPanel = useSelector(
    (state) => state.recoveryRadarTracker.data.activeWeightroomPanel,
  );
  const selectedPanel = useSelector(
    (state) => state.weightRoomView[activeWeightroomPanel],
  );

  const athleteName = context === 'weightRoomView'
    ? selectedPanel.activeAthlete : `${sharedAthlete.first} ${sharedAthlete.last}`;
  const athleteAvatar = context === 'weightRoomView'
    ? selectedPanel.athleteAvatar : sharedAthlete.pic;
  const areasSelected = bodyMapData?.currentIssues?.length || 0;

  return (
    <Container>
      <NameContainer>
        <Text
          styledTextProps={{
            fontSize: '28px',
            fontWeight: 700,
          }}
        >
          {athleteName}
        </Text>
        <Text
          styledTextProps={{
            fontSize: '19px',
            fontWeight: 400,
            color: '#9E9E9E',
          }}
        >
          Areas Selected (
          {areasSelected}
          )
        </Text>
      </NameContainer>
      <AvatarContainer>
        <Avatar
          avatar={athleteAvatar}
          alt={athleteName}
          size={60}
        />
      </AvatarContainer>
    </Container>
  );
};

AthleteDetails.propTypes = {
  context: PropTypes.oneOf(['weightRoomView', 'workoutEntry']),
  bodyMapData: PropTypes.shape({
    currentIssues: PropTypes.arrayOf(PropTypes.shape({
      bodyPart: PropTypes.string,
      issueType: PropTypes.string,
      severity: PropTypes.number,
      additionalNotes: PropTypes.string,
    })),
  }).isRequired,
};

AthleteDetails.defaultProps = {
  context: null,
};

export default AthleteDetails;
