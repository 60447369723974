/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import React, { useState } from 'react';
import styled from '@emotion/styled';
import Select from 'react-select';
import { push } from 'connected-react-router';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ResizeObserver from 'react-resize-observer';
import { useDispatch, useSelector } from 'react-redux';
import IcomoonReact from 'icomoon-react';
import Text from '../../../../../shared/components/Text/Text';
import { updateCurrentUserProfile } from '../../../../login/ducks/loginActions';
import Avatar from '../../../../../shared/components/Avatar/Avatar';
import Spinner from '../../../../../shared/components/Spinner/Spinner';
import countryCodes from '../../../../../shared/json/country-codes.json';
import timeZones from '../../../../../shared/json/timezones.json';
import FormLabel from '../../../../../shared/components/FormLabel/FormLabel';
import ProfilePicUploadButton from '../../../../../shared/components/ProfilePicUploadButton';
import ResetPasswordForm from './resetPasswordForm';
import LayoutWithSidebarNav from '../../../../../shared/components/Layout/LayoutWithSidebarNav';
import { generalSettingsSideBarNavArrayOfObjects } from '../../../../../shared/constants';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';
import SubText from '../../../../../shared/components/SubText/SubText';

// Schema for yup
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'First name must be longer than 2 characters')
    .max(100, 'Character limit is 100')
    .required('First name is required'),
  lastName: Yup.string()
    .min(2, 'Last name must be longer than 2 characters')
    .max(100, 'Character limit is 100')
    .required('Last name is required'),
  mobileNumber: Yup.string()
    .matches(/^[0-9\s]*$/, 'Must be digits'),
  //   .length(12, 'Should be exactly 10 digits'),
  emailAddress: Yup.string().email('Pleae enter a valid email').required('Email is required'),
});

const genderSelection = [
  { label: 'Male', value: 1 },
  { label: 'Female', value: 2 },
  { label: 'Undefined', value: 3 },
];

const countrySelection = countryCodes.map((country) => ({
  inputLabel: country.dialCode,
  label: `${country.label} ${country.dialCode}`,
  value: country.value,
}));

const timeZoneSelectionEntries = Object.entries(timeZones).map((entry) => ({
  label: entry[1],
  value: entry[0],
}));

const customSelectStyles = {
  container: (provided) => ({
    ...provided,
    minWidth: 95,
    minHeight: 56,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#E0E0E0',
    borderRadius: 6,
  }),
  menu: (provided) => ({
    ...provided,
    width: 200,
  }),
  control: (provided) => ({
    ...provided,
    minHeight: 56,
    width: '100%',
    border: 0,
    outline: '2px solid #E0E0E0',
  }),
};

const phoneNumberCustomSelectStyles = {
  container: (provided) => ({
    ...provided,
    minWidth: 95,
    minHeight: 56,
    marginRight: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#E0E0E0',
    borderRadius: 6,
  }),
  menu: (provided) => ({
    ...provided,
    width: 200,
  }),
  control: (provided) => ({
    ...provided,
    minHeight: 56,
    width: '100%',
    border: 0,
    outline: '2px solid #E0E0E0',
  }),
};

const LBS_WEIGHT_TYPE = 0;
const KGS_WEIGHT_TYPE = 1;
const WEIGHT_OPTIONS = [{ value: KGS_WEIGHT_TYPE, label: 'kgs' }, { value: LBS_WEIGHT_TYPE, label: 'lbs' }];

const AccountSettingsPage = () => {
  const dispatch = useDispatch();

  const [containerWidth, setContainerWidth] = useState(null);

  const currentUser = useSelector((state) => state.auth.data.currentUser);

  const currentUserUpdateError = useSelector((state) => state.auth.data.currentUserUpdateError);

  const isMeLoading = useSelector((state) => state.auth.ui.isLoginLoading);

  const isProfileUpdateLoading = useSelector((state) => state.auth.ui.isProfileUpdateLoading);

  const isProfileImageUpdateLoading = useSelector((state) => state.auth.ui.isProfileImageUpdateLoading);

  const imageError = useSelector((state) => state.auth.ui.imageError);

  const isContentLoading = isMeLoading || isProfileUpdateLoading
  || Object.keys(currentUser).length === 0;

  const helmetText = 'Account Settings';

  const navLinkArrayOfObjects = generalSettingsSideBarNavArrayOfObjects;

  const defaultCountryCodeValue = countrySelection.find(
    (country) => country.value === currentUser?.phoneCountryCode,
  );

  const defaultGenderValue = genderSelection.find(
    (gender) => gender.value === currentUser?.gender,
  );

  const defaultTimezoneValue = timeZoneSelectionEntries.filter((entryObj) => entryObj.value === (currentUser?.userTimeZone ? currentUser.userTimeZone
    : currentUser?.userSettings?.timeZone));

  const hardCodedDefaultTimezoneValue = {
    label: '(GMT-05:00) Eastern Time (US & Canada)',
    value: 'America/New_York',
  };

  const currentWeightType = currentUser?.userSettings?.weight ?? currentUser?.userSettings?.orgWeight ?? LBS_WEIGHT_TYPE;
  const weightTypeDefaultValue = currentWeightType === KGS_WEIGHT_TYPE ? { value: KGS_WEIGHT_TYPE, label: 'kgs' } : { value: LBS_WEIGHT_TYPE, label: 'lbs' };

  const gradientColors = currentUser.userSkin?.gradientColors;

  const isCurrentUserAthlete = currentUser && currentUser.admin === 0;

  const Container = styled('div')`
    position: relative;
      input:focus {
        outline: 5px dotted green;
      }
        .error-message {
        margin-top: 10px;
        font-size: 14px;
        color: #ff6600;
      }
      @media (max-width: 800px) {
        padding-top: 50px;
      }
`;

  const IconContainer = styled('div')`
    position: absolute;
    top: 0px;
    right: 0px;
      @media (min-width: 800px) {
        display: none;
      }
      @media (max-width: 800px) {
        top: 8px;
        right: 10px;
      }
`;

  const SaveButton = styled('button')`
  background-color:  ${(props) => (
    props.theme.colors.green
  )};;
  color: white;
  width:  ${containerWidth && containerWidth < 450 ? '100%' : '49%'};
  height: 50px;
  border-style: none;
  border-radius: 4px;
`;

  const RowWrapper = styled('div')`
  display: flex;
  flex-direction: ${containerWidth && containerWidth < 450 ? 'column' : 'row'};
  gap: 15px;
  padding-top: 15px;
`;

  const AvatarContentContainerColumn = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

  const ColumnWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width:  ${containerWidth && containerWidth < 450 ? '100%' : '50%'};
`;

  const HeaderColumnWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
`;

  const CustomInput = styled('input')`
  font-weight: 400;
  border: 2px solid #E0E0E0;
  border-radius: 6px;
  padding-left: 7px;
  padding-right: 7px;
  height: 56px;
`;

  const PageTitleContainer = styled('div')`
  text-align: ${containerWidth && containerWidth < 450 ? 'center' : 'start'};
  padding-bottom: 30px;
`;

  const SpinnerWrapper = styled('div')`
  display: flex;
  height: 100vh;
`;

  return (
    <LayoutWithSidebarNav
      helmetText={helmetText}
      navLinkArrayOfObjects={navLinkArrayOfObjects}
      sideBarTitle='Settings'
      sideBarTitleIcon='settings'
    >
      {isContentLoading ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <>
          <Formik
            initialValues={{
              countryCode: currentUser?.phoneCountryCode || '',
              emailAddress: currentUser?.email,
              firstName: currentUser?.first,
              gender: currentUser?.gender,
              lastName: currentUser?.last,
              mobileNumber: currentUser?.phone,
              timeZone: currentUser?.userTimeZone ? currentUser.userTimeZone
                : currentUser?.userSettings?.timeZone,
              weight: currentUser?.userSettings?.weight
               ?? currentUser?.userSettings?.orgWeight ?? LBS_WEIGHT_TYPE,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              // When button submits form and form is in the process of submitting,
              // submit button is disabled
              setSubmitting(true);
              if (!values.mobileNumber) {
                values.mobileNumber = null;
              }
              if (values.mobileNumber) {
                values.mobileNumber = values.mobileNumber.split(' ').join('');
              }
              dispatch(updateCurrentUserProfile(values));
              setSubmitting(false);
            }}
          >
            {/* Callback function containing Formik state and
             helpers that handle common form actions... destructured from props */}
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Container>
                  <ResizeObserver
                    onResize={(rect) => {
                      setContainerWidth(rect.width);
                    }}
                  />
                  <IconContainer onClick={() => { dispatch(push('/settings')); }}>
                    <IcomoonReact
                      iconSet={iconSet}
                      size={28}
                      icon='settings'
                      color='#444444'
                    />
                  </IconContainer>
                  <PageTitleContainer>
                    <Text fontSize={containerWidth && containerWidth < 450 ? 28 : 34} fontWeight='400'>Account Settings</Text>
                  </PageTitleContainer>
                  <AvatarContentContainerColumn>
                    {isProfileImageUpdateLoading ? (
                      <div style={{ width: 150, height: 150 }}>
                        <Spinner />
                      </div>
                    ) : (

                      <Avatar avatar={currentUser.pic} size={146} linearGradient={gradientColors}>
                        <div style={{ position: 'absolute', top: -21, right: 0 }}>
                          <ProfilePicUploadButton />
                        </div>
                      </Avatar>
                    )}
                    <Text fontSize={containerWidth && containerWidth < 450 ? 24 : 28} fontWeight='700'>
                      {`${currentUser.first} `}
                      {`${currentUser.last}`}
                    </Text>
                    <Text fontSize={18} fontWeight='400'>Account Settings</Text>
                    {imageError ? (
                      <div className='error-message'>{imageError}</div>
                    ) : null}
                  </AvatarContentContainerColumn>
                  <HeaderColumnWrapper>
                    <Text fontSize={containerWidth && containerWidth < 450 ? 24 : 28} fontWeight='700'>Your Information</Text>
                    <Text fontSize={18} fontWeight='400'>Manage your account and profile information.</Text>
                  </HeaderColumnWrapper>
                  {currentUserUpdateError ? (
                    <div className='error-message'>{currentUserUpdateError}</div>
                  ) : null}
                  <RowWrapper>
                    <ColumnWrapper>
                      <FormLabel size={16}>
                        First Name
                      </FormLabel>
                      <CustomInput
                        id='firstName'
                        name='firstName'
                        autoCapitalize='none'
                        placeholder='First Name'
                        value={values.firstName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched.firstName && errors.firstName ? (
                        <div className='error-message'>{errors.firstName}</div>
                      ) : null}
                    </ColumnWrapper>
                    <ColumnWrapper>
                      <FormLabel size={16}>
                        Last Name
                      </FormLabel>
                      <CustomInput
                        id='lastName'
                        name='lastName'
                        autoCapitalize='none'
                        placeholder='Last Name'
                        value={values.lastName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched.lastName && errors.lastName ? (
                        <div className='error-message'>{errors.lastName}</div>
                      ) : null}
                    </ColumnWrapper>
                  </RowWrapper>
                  <RowWrapper>
                    <ColumnWrapper>
                      <FormLabel size={16}>
                        Email
                      </FormLabel>
                      <CustomInput
                        id='emailAddress'
                        name='emailAddress'
                        autoCapitalize='none'
                        placeholder='Email'
                        value={values.emailAddress}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched.emailAddress && errors.emailAddress ? (
                        <div className='error-message'>{errors.emailAddress}</div>
                      ) : null}
                    </ColumnWrapper>
                    <ColumnWrapper>
                      <FormLabel size={16}>
                        Phone Number(optional)
                      </FormLabel>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                      >
                        <Select
                          styles={phoneNumberCustomSelectStyles}
                          options={countrySelection}
                          defaultValue={defaultCountryCodeValue}
                          onChange={(options) => {
                            setFieldValue('countryCode', options.value);
                          }}
                          formatOptionLabel={(option, { context }) => (context === 'menu' ? option.label : option.inputLabel)}
                        />
                        <CustomInput
                          style={{ width: '100%' }}
                          id='mobileNumber'
                          name='mobileNumber'
                          autoCapitalize='none'
                          placeholder='(xxx) xxx-xxxx'
                          value={values.mobileNumber}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            let val = e.target.value;
                            val = val.replace(/ /gm, '');
                            let num = `${val.substring(0, 3)} ${val.substring(3, 6)} ${val.substring(6, val.length)}`;
                            num = num.trim();
                            setFieldValue('mobileNumber', num);
                          }}
                        />
                      </div>
                      {touched.mobileNumber && errors.mobileNumber ? (
                        <div className='error-message'>{errors.mobileNumber}</div>
                      ) : null}
                    </ColumnWrapper>
                  </RowWrapper>
                  <RowWrapper>
                    <ColumnWrapper>
                      <FormLabel size={16}>
                        Gender
                      </FormLabel>
                      <div>
                        <Select
                          styles={customSelectStyles}
                          options={genderSelection}
                          defaultValue={defaultGenderValue}
                          onChange={(options) => {
                            setFieldValue('gender', options.value);
                          }}
                        />
                      </div>
                    </ColumnWrapper>
                    <ColumnWrapper>
                      <FormLabel size={16}>
                        Timezone
                      </FormLabel>
                      <div>
                        <Select
                          styles={customSelectStyles}
                          options={timeZoneSelectionEntries}
                          defaultValue={defaultTimezoneValue || hardCodedDefaultTimezoneValue}
                          onChange={(options) => {
                            setFieldValue('timeZone', options.value);
                          }}
                        />
                      </div>
                    </ColumnWrapper>
                  </RowWrapper>
                  {isCurrentUserAthlete && (
                  <RowWrapper>
                    <ColumnWrapper>
                      <FormLabel size={16}>
                        Weight Unit
                      </FormLabel>
                      <div>
                        <Select
                          styles={customSelectStyles}
                          options={WEIGHT_OPTIONS}
                          defaultValue={weightTypeDefaultValue}
                          onChange={(options) => {
                            setFieldValue('weight', options.value);
                            handleSubmit();
                          }}
                        />
                        <SubText>
                          Do this prior to completing your workout for the day or after your workout is completed to avoid inaccurate data conversions
                        </SubText>
                      </div>
                    </ColumnWrapper>
                  </RowWrapper>
                  )}
                  <RowWrapper>
                    <SaveButton type='submit' disabled={isSubmitting}>
                      Save Changes
                    </SaveButton>
                  </RowWrapper>
                </Container>
              </form>
            )}
          </Formik>
          <ResetPasswordForm />
        </>
      )}
    </LayoutWithSidebarNav>
  );
};
export default AccountSettingsPage;
