import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Text from '../../../../shared/_TBComponents/Text';
import Icon from '../../../../shared/_TBComponents/Icon';

const Container = styled('div')`
	display: flex;
  justify-content: center;
  width: ${(props) => (props.context === 'workoutEntry' ? '100%' : '')};
  margin-top: ${(props) => (props.context === 'workoutEntry' ? '30px' : '0')};
  margin-bottom: ${(props) => (props.context === 'workoutEntry' ? '0px' : '15px')};
  margin-right: ${(props) => (props.context === 'workoutEntry' ? '0px' : '30px')};
  margin-left: ${(props) => (props.context === 'workoutEntry' ? '0px' : '30px')};

  @media (max-width: 823px) {
    margin-right: ${(props) => (props.context === 'workoutEntry' ? '0px' : '20px')};
    margin-left: ${(props) => (props.context === 'workoutEntry' ? '0px' : '20px')};
  }
`;

const IconWrapper = styled('div')`
  padding-bottom: 3px;
`;

const CompleteIconWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding-bottom: 3px;
  border-radius: 50%;
	border: 1px solid #0DCC8A;
	background: #0DCC8A;
`;

const StyledButton = styled('div')`
	display: flex;
  width: ${(props) => (props.context === 'workoutEntry' ? '95%' : '100%')};
  height: 36px;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 20px;
  border: 2px solid ${({ borderColor }) => borderColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
  cursor: pointer;
  :hover {
    opacity: 0.65;
  }
`;

const RecoveryRadarButton = ({ context, handleClick, namespace }) => {
  const isDarkThemeEnabled = useSelector(
    (state) => state.weightRoomView.ui.isDarkThemeEnabled,
  );
  const workoutEntryData = useSelector(
    (state) => state.recoveryRadarTracker.data.workoutEntryData,
  );
  const weightRoomViewData = useSelector(
    (state) => state.recoveryRadarTracker.data.weightRoomViewData,
  );

  const isWeightRoomView = Object.keys(weightRoomViewData).length > 0
      && Object.values(weightRoomViewData).some(
        (panel) => panel.currentIssues.length > 0 || panel.previousIssues.length > 0,
      );

  const bodyMapData = isWeightRoomView
    ? weightRoomViewData[namespace]
    : workoutEntryData;

  const isCompleted = bodyMapData?.currentIssues?.length > 0;

  let backgroundColor = '#FFF';
  let borderColor = '#444';
  let textColor = '#444';
  let iconColor = '#444';

  if (context === 'weightRoomView' && isDarkThemeEnabled && isCompleted) {
    textColor = '#FFFFFFCC';
    iconColor = '#FFFFFFCC';
    backgroundColor = '#444';
    borderColor = '#444';
  } else if (isCompleted) {
    backgroundColor = '#444';
    borderColor = '#444';
    textColor = '#FFF';
    iconColor = '#FFF';
  } else if (context === 'weightRoomView' && isDarkThemeEnabled) {
    backgroundColor = '#000';
    borderColor = '#FFFFFFCC';
    textColor = '#FFFFFFCC';
    iconColor = '#FFFFFFCC';
  }

  return (
    <Container context={context}>
      <StyledButton
        onClick={handleClick}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        context={context}
      >
        {!isCompleted ? (
          <IconWrapper>
            <Icon icon='target' size={20} color={iconColor} />
          </IconWrapper>
        ) : (
          <CompleteIconWrapper>
            <Icon icon='checkmark' color='#FFF' size={12} />
          </CompleteIconWrapper>
        )}
        <Text
          upperCase
          styledTextProps={{
            color: textColor,
            fontSize: '12px',
            fontWeight: '900',
            letterSpacing: '1.5px',
          }}
        >
          Body Heat Map
        </Text>
      </StyledButton>
    </Container>
  );
};

RecoveryRadarButton.propTypes = {
  context: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  namespace: PropTypes.string.isRequired,
};

export default RecoveryRadarButton;
