import React from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';

import Button from '../../../../shared/_TBComponents/Button';
import Text from '../../../../shared/_TBComponents/Text';
import CustomButton from './CustomButton';

import { SEVERITY_COLORS } from '../../constants';
import {
  setIsRemoveModalShowing,
  setRemoveBodyMapDataItems,
  setRemovePreviousItems,
  setRemoveAllBodyMapData,
  setSelectedBodyPart,
} from '../../ducks/recoveryRadarTrackerActions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const BodyPartContainer = styled.div`
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const BodyPartItemContainer = styled.div`
  display: flex;
  align-items: stretch;
`;

const BodyPartItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  padding: 16px 20px;
  border-bottom: 1px solid #EEE;
  border-right: 1px solid #EEE;
  box-shadow: 1px 0px 5px rgba(0,0,0,0.05);
  cursor: pointer;
  transition: all 250ms ease-in-out;
  z-index: 2;

  &:hover {
    opacity: 0.7;
  }
`;

const IconButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  background: #FCFCFC;
  z-index: 1;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px 20px 0 20px;
  margin-bottom: 100px;
`;

const InstructionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 48px;
  margin-bottom: 50px;
`;

const RatingInfo = ({
  bodyMapData,
  setCurrentView,
}) => {
  const dispatch = useDispatch();

  const isRecoveryRadarWorkoutModalShowing = useSelector(
    (state) => state.recoveryRadarTracker.ui.isRecoveryRadarWorkoutModalShowing,
  );
  const activeWorkoutDate = useSelector(
    (state) => state.workouts.data.activeWorkoutDate,
  );
  const activeWeightroomPanel = useSelector(
    (state) => state.recoveryRadarTracker.data.activeWeightroomPanel,
  );
  const activeDate = useSelector(
    (state) => state.weightRoomView[activeWeightroomPanel]?.activeDate,
  );
  const previousItemsRemoved = useSelector(
    (state) => state.recoveryRadarTracker.data.previousItemsRemoved,
  );

  const parsedActiveDate = activeDate ? moment(activeDate).format('YYYY-MM-DD') : null;
  const selectedDate = isRecoveryRadarWorkoutModalShowing ? activeWorkoutDate : parsedActiveDate;

  const isToday = moment().isSame(
    isRecoveryRadarWorkoutModalShowing ? activeWorkoutDate : parsedActiveDate, 'day',
  );

  const filteredPreviousIssues = isToday
    ? bodyMapData?.previousIssues?.filter(
      (issue) => !previousItemsRemoved.includes(issue.id)
        && !bodyMapData?.currentIssues?.some(
          (currentIssue) => currentIssue.bodyPart === issue.bodyPart,
        ),
    )
    : [];

  const handleSelectIssue = (issue) => {
    dispatch(setSelectedBodyPart({ slug: issue.bodyPart }));
    setCurrentView('reportForm');
  };

  const handleDeleteItem = (item) => {
    dispatch(setRemoveAllBodyMapData(false));

    if (item.reportedDate !== selectedDate) {
      dispatch(setRemovePreviousItems([item.id]));
    } else {
      dispatch(setRemoveBodyMapDataItems([item.id]));
    }

    dispatch(setIsRemoveModalShowing(true));
  };

  const handleDeleteAllItems = () => {
    const allPrevIssues = bodyMapData?.previousIssues.map((item) => item.id);
    batch(() => {
      dispatch(setRemoveAllBodyMapData(true));
      dispatch(setIsRemoveModalShowing(true));
      dispatch(setRemovePreviousItems(allPrevIssues));
    });
  };

  const formatSlug = (slug) => {
    if (!slug) return '';
    return slug
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <Container>
      <BodyPartContainer>
        {filteredPreviousIssues?.map((issue) => (
          <BodyPartItemContainer key={issue.id}>
            <BodyPartItem onClick={() => handleSelectIssue(issue)}>
              <Text>{formatSlug(issue.bodyPart)}</Text>
              <CustomButton
                isActive={false}
                activeColor='#FCFCFC'
                borderColor='#EEE'
                size='32px'
                icon='question-mark'
                iconColor='#9E9E9E'
                hasPadding
                disabled
              />
            </BodyPartItem>
            <IconButtonWrapper>
              <Button
                iconOnly
                icon='trashcan'
                iconColor='#FF3737'
                iconSize={24}
                noBorder
                onClick={() => handleDeleteItem(issue)}
              />
            </IconButtonWrapper>
          </BodyPartItemContainer>
        ))}
        {bodyMapData?.currentIssues?.map((issue) => (
          <BodyPartItemContainer key={issue.id}>
            <BodyPartItem onClick={() => handleSelectIssue(issue)}>
              <Text>{formatSlug(issue.bodyPart)}</Text>
              <CustomButton
                isActive
                activeColor={SEVERITY_COLORS[issue.severity - 1]}
                borderColor='#E0E0E0'
                isSoreness={issue.issueType === 'soreness'}
                size='32px'
                disabled
              >
                {issue.severity}
              </CustomButton>
            </BodyPartItem>
            <IconButtonWrapper>
              <Button
                iconOnly
                icon='trashcan'
                iconColor='#FF3737'
                iconSize={24}
                noBorder
                onClick={() => handleDeleteItem(issue)}
              />
            </IconButtonWrapper>
          </BodyPartItemContainer>
        ))}
      </BodyPartContainer>
      {(bodyMapData?.currentIssues?.length > 0
      || (isToday && filteredPreviousIssues?.length > 0)) ? (
        <ButtonWrapper>
          <Button
            noBorder
            name='Remove All'
            txtColor='#5F8BDC'
            styledTextProps={{ fontSize: '19px', fontWeight: '400', letterSpacing: '0px' }}
            styledWrapperProps={{
              style: {
                textDecoration: 'underline',
                textDecorationColor: '#5F8BDC',
              },
            }}
            onClick={handleDeleteAllItems}
          />
        </ButtonWrapper>
        ) : (
          <InstructionContainer>
            <Text styledTextProps={{ color: '#9E9E9E' }}>
              Select an area on the body chart above to get started.
            </Text>
          </InstructionContainer>
        )}
    </Container>
  );
};

RatingInfo.propTypes = {
  bodyMapData: PropTypes.shape({
    currentIssues: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        bodyPart: PropTypes.string.isRequired,
        issueType: PropTypes.string.isRequired,
        severity: PropTypes.number.isRequired,
      }),
    ).isRequired,
    previousIssues: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        bodyPart: PropTypes.string.isRequired,
        issueType: PropTypes.string.isRequired,
        severity: PropTypes.number.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  setCurrentView: PropTypes.func.isRequired,
};

export default RatingInfo;
