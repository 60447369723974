// options for dropdowns
export const TYPE_OPTIONS = [
  { id: 1, name: 'Pain' },
  { id: 2, name: 'Soreness' },
];

export const STATUS_OPTIONS = [
  { id: 1, name: 'New Issue' },
  { id: 2, name: 'Awaiting Assessment' },
  { id: 3, name: 'Receiving Treatment' },
  { id: 4, name: 'Rehabilitation' },
];

export const REGION_OPTIONS = [
  { id: 1, name: 'Upper' },
  { id: 2, name: 'Lower' },
  { id: 3, name: 'Inner' },
  { id: 4, name: 'Outer' },
];

export const NOTE_MAX_LENGTH = 250;

// colors for the severity scale from 1 down to 7
export const SEVERITY_COLORS = [
  '#3DD6A1',
  '#B0E4D2',
  '#D6E095',
  '#FFE091',
  '#FFCA63',
  '#F67361',
  '#FF3737',
  // color 8 below is the default grey to fix a selection bug
  '#898989',
];
