import {
  SET_SELECTED_BODY_PART,
  UPDATE_BODY_PART_ISSUE,
  FETCH_BODY_MAP_DATA_BY_DATE_FULFILLED,
  FETCH_BODY_MAP_DATA_BY_DATE_REJECTED,
  SUBMIT_BODY_MAP_DATA_FULFILLED,
  SUBMIT_BODY_MAP_DATA_REJECTED,
  UPDATE_BODY_MAP_DATA_FULFILLED,
  UPDATE_BODY_MAP_DATA_REJECTED,
  SET_REMOVE_BODY_MAP_DATA_ITEMS,
  SET_CONFIRM_REMOVE_PREVIOUS_ITEMS,
  SET_REMOVE_PREVIOUS_ITEMS,
  SET_ACTIVE_WEIGHTROOM_PANEL,
} from './actionTypes';

const initialState = {
  selectedBodyPart: '',
  workoutEntryData: {
    currentIssues: [],
    previousIssues: [],
  },
  weightRoomViewData: {
    panel1: { currentIssues: [], previousIssues: [] },
    panel2: { currentIssues: [], previousIssues: [] },
    panel3: { currentIssues: [], previousIssues: [] },
    panel4: { currentIssues: [], previousIssues: [] },
  },
  bodyMapDataError: '',
  reportFormData: [],
  reportFormDataError: '',
  updatedIssues: {},
  itemsToRemove: [],
  previousItemsToRemove: [],
  previousItemsRemoved: [],
  activeWeightroomPanel: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_BODY_PART: {
      return {
        ...state,
        selectedBodyPart: action.payload,
      };
    }
    case UPDATE_BODY_PART_ISSUE: {
      return {
        ...state,
        updatedIssues: action.payload,
      };
    }
    case FETCH_BODY_MAP_DATA_BY_DATE_FULFILLED: {
      const { isWeightRoomView, weightRoomPanel, data } = action.payload;
      if (isWeightRoomView && weightRoomPanel) {
        // Update specific panel data in WRV
        return {
          ...state,
          weightRoomViewData: {
            ...state.weightRoomViewData,
            [weightRoomPanel]: data,
          },
        };
      }
      // Update data for Workout Entry
      return {
        ...state,
        workoutEntryData: data,
      };
    }
    case FETCH_BODY_MAP_DATA_BY_DATE_REJECTED: {
      const { isWeightRoomView, weightRoomPanel, error } = action.payload;
      if (isWeightRoomView && weightRoomPanel) {
        return {
          ...state,
          bodyMapDataError: {
            ...state.bodyMapDataError,
            [weightRoomPanel]: error,
          },
        };
      }
      return {
        ...state,
        bodyMapDataError: error,
      };
    }
    case SUBMIT_BODY_MAP_DATA_FULFILLED:
      return {
        ...state,
        reportFormData: action.payload,
        updatedIssues: {},
      };
    case SUBMIT_BODY_MAP_DATA_REJECTED:
      return {
        ...state,
        reportFormDataError: action.payload?.response?.headers?.message,
      };
    case UPDATE_BODY_MAP_DATA_FULFILLED:
      return {
        ...state,
        reportFormData: action.payload,
        updatedIssues: {},
      };
    case UPDATE_BODY_MAP_DATA_REJECTED:
      return {
        ...state,
        reportFormDataError: action.payload?.response?.headers?.message,
      };
    case SET_REMOVE_BODY_MAP_DATA_ITEMS:
      return {
        ...state,
        itemsToRemove: action.payload,
      };
    case SET_REMOVE_PREVIOUS_ITEMS:
      return {
        ...state,
        previousItemsToRemove: action.payload,
      };
    case SET_CONFIRM_REMOVE_PREVIOUS_ITEMS:
      return {
        ...state,
        previousItemsRemoved: action.payload,
      };
    case SET_ACTIVE_WEIGHTROOM_PANEL:
      return {
        ...state,
        activeWeightroomPanel: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
}
