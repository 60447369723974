import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import BodyHeatMap from '../../../recovery-radar/components/BodyHeatMap';
import { SEVERITY_COLORS } from '../../constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 24px;
  border-radius: 8px;
  padding: 16px 20px 0 20px;
`;

const StyledButton = styled.button`
  flex: 1;
  padding: 6px 16px;
  margin: 4px;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Nunito Sans', sans-serif;
  color: ${({ active }) => (active ? '#fff' : '#9E9E9E')};
  background-color: ${({ active }) => (active ? '#444' : '#fff')};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${({ active }) => (active ? '#333' : '#f0f0f0')};
  }
`;

const SideIndicatorContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const SideIndicator = styled.div`
  position: absolute;
  font-size: 23px;
  font-weight: 700;
  color: #9E9E9E;
  top: 17.5%;
  ${({ side }) => (side === 'left' ? 'right: 30px;' : 'left: 30px;')}
`;

const RecoveryRadarBodyMap = ({
  selectedBodyPart,
  onBodyPartPress,
  resetOnBodyPartPress,
  bodyMapData,
}) => {
  const [isBackSideEnabled, setIsBackSideEnabled] = useState(false);

  const updatedIssues = useSelector((state) => state.recoveryRadarTracker.data.updatedIssues);

  const getToolTipValue = (issueType, severity) => {
    if (!issueType || severity === null) {
      return '';
    }
    if (issueType === 'pain') {
      return `Pain (${severity})`;
    }
    return `Soreness (${severity})`;
  };

  // The two code blocks below format the data to use in the BodyHeatMap component
  // and then also merge the live selected issues with the existing issues
  const formattedBodyMapData = bodyMapData.currentIssues.map((issue) => {
    const isUpdated = updatedIssues?.slug === issue.bodyPart;

    return {
      slug: issue.bodyPart,
      intensity: isUpdated ? updatedIssues.severity : issue.severity,
      color: isUpdated ? updatedIssues.color : issue.color,
      soreness: isUpdated ? updatedIssues.issueType === 'soreness' : issue.issueType === 'soreness',
      value: (() => {
        if (isUpdated) {
          return updatedIssues.issueType === 'pain'
            ? `Pain (${updatedIssues.severity})`
            : `Soreness (${updatedIssues.severity})`;
        }
        return issue.issueType === 'pain'
          ? `Pain (${issue.severity})`
          : `Soreness (${issue.severity})`;
      })(),
    };
  });

  if (
    updatedIssues?.slug && !bodyMapData.currentIssues.some(
      (issue) => issue.bodyPart === updatedIssues.slug,
    )) {
    formattedBodyMapData.push({
      slug: updatedIssues.slug,
      intensity: updatedIssues.severity || 8,
      color: updatedIssues.color,
      soreness: updatedIssues.issueType === 'soreness',
      value: getToolTipValue(updatedIssues.issueType, updatedIssues.severity),
    });
  }

  const toggleSwitch = (side) => {
    setIsBackSideEnabled(side === 'back');
  };

  // Check if the selected body part is on the back side of the body or not
  useEffect(() => {
    if (
      selectedBodyPart?.slug?.includes('back')
        || selectedBodyPart?.slug?.includes('hamstring')
        || selectedBodyPart?.slug?.includes('hips')
        || selectedBodyPart?.slug?.includes('gluteal')) {
      toggleSwitch('back');
    } else {
      toggleSwitch('front');
    }
  }, [selectedBodyPart]);

  return (
    <Container>
      {!selectedBodyPart && (
      <ButtonContainer>
        <StyledButton
          active={!isBackSideEnabled}
          onClick={() => toggleSwitch('front')}
        >
          FRONT
        </StyledButton>
        <StyledButton
          active={isBackSideEnabled}
          onClick={() => toggleSwitch('back')}
        >
          BACK
        </StyledButton>
      </ButtonContainer>
      )}
      <SideIndicatorContainer>
        <SideIndicator side='right'>{!isBackSideEnabled ? 'R' : 'L'}</SideIndicator>
        <BodyHeatMap
          colors={SEVERITY_COLORS}
          data={formattedBodyMapData}
          side={isBackSideEnabled ? 'back' : 'front'}
          onBodyPartPress={onBodyPartPress}
          padding={0}
          noBlur
          resetOnBodyPartPress={resetOnBodyPartPress}
        />
        <SideIndicator side='left'>{!isBackSideEnabled ? 'L' : 'R'}</SideIndicator>
      </SideIndicatorContainer>
    </Container>
  );
};

RecoveryRadarBodyMap.propTypes = {
  selectedBodyPart: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onBodyPartPress: PropTypes.func.isRequired,
  resetOnBodyPartPress: PropTypes.func.isRequired,
  bodyMapData: PropTypes.shape({
    currentIssues: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        bodyPart: PropTypes.string.isRequired,
        issueType: PropTypes.string.isRequired,
        severity: PropTypes.number.isRequired,
      }),
    ).isRequired,
    previousIssues: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        bodyPart: PropTypes.string.isRequired,
        issueType: PropTypes.string.isRequired,
        severity: PropTypes.number.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

RecoveryRadarBodyMap.defaultProps = {
  selectedBodyPart: '',
};

export default RecoveryRadarBodyMap;
