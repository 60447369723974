import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TextArea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 2px solid #E0E0E0;
  border-radius: 5px;
  font-size: 14px;
  font-family: 'Nunito Sans', sans-serif;
  color: ${({ colors }) => colors.text};
  background-color: ${({ colors }) => colors.background};
  resize: none;
  outline: none;

  &::placeholder {
    color: ${({ colors }) => colors.placeholder};
  }

  &:focus {
    border-color: ${({ colors }) => colors.focus};
    box-shadow: 0 0 0 2px ${({ colors }) => colors.focus}33;
  }
`;

const ErrorText = styled.p`
  font-size: 13px;
  font-family: 'Nunito Sans';
  color: ${({ colors }) => colors.error};
  margin-top: 5px;
`;

const CustomTextArea = ({
  value, onChange, maxLength, colors,
}) => {
  const isLimitReached = value.length >= maxLength;

  return (
    <>
      <TextArea
        placeholder='Additional notes related to this entry'
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        colors={colors}
      />
      {isLimitReached && <ErrorText colors={colors}>Character limit reached</ErrorText>}
    </>
  );
};

CustomTextArea.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  maxLength: PropTypes.number.isRequired,
  colors: PropTypes.shape({
    text: PropTypes.string,
    background: PropTypes.string,
    placeholder: PropTypes.string,
    focus: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
};

export default CustomTextArea;
