import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTransition, animated } from 'react-spring/web.cjs';
import { matchSorter } from 'match-sorter';

import Dropdown from './Dropdown';
import WeightroomSearch from '../container/WeightroomSearch';
import AthletePicker from './AthletePicker';
import WeightroomPinPad from '../container/WeightroomPinPad';
import { setActiveWeightroomPanel } from '../../../recovery-radar-tracker/ducks/recoveryRadarTrackerActions';

const PanelChooseHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 30px 15px 30px;

  @media screen and (orientation: portrait) {
    padding: 25px 20px 15px 20px!important;
  }
`;

const AthletePickerWrapper = styled('div')`
  /* ${(props) => (
    props.activeScreens === 4
      ? 'height: calc(50vh - 108px);'
      : 'height: calc(100vh - 108px);'
  )} */
  flex: 1;

  @media screen and (orientation: portrait) {
    ${(props) => (
    props.activeScreens === 2 && `
      height: calc(50vh - 108px);
    `
  )}
  }

  @media screen and (orientation: landscape) {
    ${(props) => (
    props.activeScreens === 2 && `
      height: calc(100vh - 108px);
    `
  )}
  }
`;

const PanelAthletes = (props) => {
  const {
    namespace,
    openPinPad,
    selectAthlete,
  } = props;
  const dispatch = useDispatch();

  const accountCode = useSelector((state) => state.weightRoomView.data.currentUser.accountCode);
  const data = useSelector((state) => state.weightRoomView.data.users || []);
  const searchFilter = useSelector((state) => state.weightRoomView[namespace].athleteSearch);
  const activeScreens = useSelector((
    state,
  ) => state.weightRoomView.ui.activeScreens);
  const isPinPadOpen = useSelector((
    state,
  ) => state.weightRoomView[namespace].isPinPadOpen);
  const isDimensionSet = useSelector((
    state,
  ) => state.weightRoomView[namespace].isDimensionSet);
  const panelDimensions = useSelector((
    state,
  ) => state.weightRoomView[namespace].panelDimensions);
  const userGroups = useSelector((
    state,
  ) => state.weightRoomView.data.userGroups);
  const groupFilter = useSelector((
    state,
  ) => state.weightRoomView.data.groupFilter);

  const filterSearch = matchSorter(data, searchFilter, { keys: ['first', 'last'] });

  const isSilkBrowser = /\bSilk\b/.test(navigator.userAgent);

  const handleActiveAthlete = (name) => {
    if (name.pinEnabled && name.pinEnabled === 'true') {
      openPinPad(name.name, name.id, name.avatar, namespace);
    } else {
      selectAthlete(accountCode, name.name, name.id, name.avatar, namespace);
      dispatch(setActiveWeightroomPanel(namespace));
    }
  };

  const transitions = useTransition(isPinPadOpen, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const nonAnimatedPinPad = isPinPadOpen ? (
    <div
      style={{
        display: 'inherit',
        flexDirection: 'inherit',
        position: 'inherit',
        width: 'inherit',
        height: 'inherit',
      }}
    >
      <WeightroomPinPad
        namespace={namespace}
      />
    </div>
  ) : (
    <div
      style={{
        display: 'inherit',
        flexDirection: 'inherit',
        position: 'inherit',
        width: 'inherit',
        height: 'inherit',
      }}
    >
      <PanelChooseHeader>
        <Dropdown
          data={userGroups}
        />
        <WeightroomSearch namespace={namespace} />
      </PanelChooseHeader>
      <AthletePickerWrapper
        activeScreens={activeScreens}
      >
        <AthletePicker
          handleActive={handleActiveAthlete}
          groupFilter={groupFilter}
          data={filterSearch}
          isDimensionSet={isDimensionSet}
          namespace={namespace}
          panelDimensions={panelDimensions}
        />
      </AthletePickerWrapper>
    </div>
  );

  const animatedPinPad = transitions.map(({ item, key, props }) => (
    item ? (
      <animated.div
        key={key}
        style={{
          ...props,
          display: 'inherit',
          flexDirection: 'inherit',
          position: 'inherit',
          width: 'inherit',
          height: 'inherit',
          zIndex: 1000,
        }}
      >
        <WeightroomPinPad
          namespace={namespace}
        />
      </animated.div>
    ) : (
      <animated.div
        key={key}
        style={{
          ...props,
          display: 'inherit',
          flexDirection: 'inherit',
          position: 'inherit',
          width: 'inherit',
          height: 'inherit',
        }}
      >
        <PanelChooseHeader>
          <Dropdown
            data={userGroups}
          />
          <WeightroomSearch namespace={namespace} />
        </PanelChooseHeader>
        <AthletePickerWrapper
          activeScreens={activeScreens}
        >
          <AthletePicker
            handleActive={handleActiveAthlete}
            groupFilter={groupFilter}
            isDimensionSet={isDimensionSet}
            data={filterSearch}
            namespace={namespace}
            panelDimensions={panelDimensions}
          />
        </AthletePickerWrapper>
      </animated.div>
    )
  ));

  return (
    <>
      {isSilkBrowser ? (
        nonAnimatedPinPad
      ) : (
        animatedPinPad
      )}
    </>
  );
};

PanelAthletes.propTypes = {
  namespace: PropTypes.string.isRequired,
  openPinPad: PropTypes.func.isRequired,
  selectAthlete: PropTypes.func.isRequired,
};

export default PanelAthletes;
