import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Modal from '../../../../../shared/_TBComponents/Modal';
import Button from '../../../../../shared/_TBComponents/Button';
import Text from '../../../../../shared/_TBComponents/Text';
import { submitUserAcceptance } from '../../../ducks/settingsActions';
/**
 * Modal component that displays the disclaimer for AI-powered workouts and requires user
 * acknowledgment before enabling the feature
 */
const AIAcknowledgmentModal = ({ isOpen, onRequestClose, onAcknowledge }) => {
  const handleAcknowledge = () => {
    // Submit user acceptance with ID 1 (for coach in webapp)
    submitUserAcceptance(1).then(() => {
      onAcknowledge();
    }).catch(() => {
      onRequestClose();
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title='DISCLAIMER FOR AI-GENERATED WORKOUT PLANS'
      titleIcon='checkmark'
      customMinHeight='300px'
      customPadding='30px'
      customButton
      hideButton
    >
      <ContentWrapper>
        <Text fontSize={16} fontWeight='600'>
          IMPORTANT: READ BEFORE USING OUR SERVICES
        </Text>

        <Text fontSize={14} fontWeight='400'>
          By accessing or using the AI-generated workout plans provided by TeamBuildr, you
          acknowledge that you have read, understood, and agree to be bound by the following
          disclaimer:
        </Text>

        <Section>
          <SectionTitle fontSize={15} fontWeight='600'>
            NON-PERSONALIZED CONTENT
          </SectionTitle>
          <Text fontSize={14} fontWeight='400'>
            The workout plans generated by our artificial intelligence system are NOT personalized
            to your specific health condition, fitness level, or individual circumstances. These
            workout plans are created using generalized algorithms and should be considered as
            general fitness suggestions only.
          </Text>
        </Section>

        <Section>
          <SectionTitle fontSize={15} fontWeight='600'>
            CONSULTATION REQUIREMENT
          </SectionTitle>
          <Text fontSize={14} fontWeight='400'>
            BEFORE beginning any workout plan generated by our system:
          </Text>
          <Text fontSize={14} fontWeight='400' style={{ paddingLeft: '16px' }}>
            • You MUST consult with a qualified healthcare professional, such as a physician,
            physical therapist, or certified fitness trainer
          </Text>
          <Text fontSize={14} fontWeight='400' style={{ paddingLeft: '16px' }}>
            • Your healthcare provider should evaluate your current health status, physical
            limitations, and fitness goals
          </Text>
          <Text fontSize={14} fontWeight='400' style={{ paddingLeft: '16px' }}>
            • Any exercise regimen should be approved by your healthcare provider
          </Text>
        </Section>

        <Section>
          <SectionTitle fontSize={15} fontWeight='600'>
            ASSUMPTION OF RISK
          </SectionTitle>
          <Text fontSize={14} fontWeight='400'>
            Physical exercise carries inherent risks. By using our AI-generated workout plans, you:
          </Text>
          <Text fontSize={14} fontWeight='400' style={{ paddingLeft: '16px' }}>
            • Voluntarily assume all risks associated with physical activity
          </Text>
          <Text fontSize={14} fontWeight='400' style={{ paddingLeft: '16px' }}>
            • Acknowledge that injuries may occur during exercise
          </Text>
          <Text fontSize={14} fontWeight='400' style={{ paddingLeft: '16px' }}>
            • Accept responsibility for monitoring your own physical condition while exercising
          </Text>
        </Section>

        <Section>
          <SectionTitle fontSize={15} fontWeight='600'>
            LIMITATION OF LIABILITY
          </SectionTitle>
          <Text fontSize={14} fontWeight='400'>
            To the fullest extent permitted under Maryland law, TeamBuildr disclaims all liability
            for any injuries, damages, or losses arising from or related to the use of our
            AI-generated workout plans. This includes, but is not limited to:
          </Text>
          <Text fontSize={14} fontWeight='400' style={{ paddingLeft: '16px' }}>
            • Physical injuries sustained during exercise
          </Text>
          <Text fontSize={14} fontWeight='400' style={{ paddingLeft: '16px' }}>
            • Aggravation of preexisting conditions
          </Text>
          <Text fontSize={14} fontWeight='400' style={{ paddingLeft: '16px' }}>
            • Any health complications resulting from exercise
          </Text>
        </Section>

        <Section>
          <SectionTitle fontSize={15} fontWeight='600'>
            PROPER FORM AND TECHNIQUE
          </SectionTitle>
          <Text fontSize={14} fontWeight='400'>
            Users are solely responsible for ensuring proper form and technique when performing
            exercises. Improper form may increase the risk of injury. We strongly recommend:
          </Text>
          <Text fontSize={14} fontWeight='400' style={{ paddingLeft: '16px' }}>
            • Working with a qualified fitness professional to learn proper technique
          </Text>
          <Text fontSize={14} fontWeight='400' style={{ paddingLeft: '16px' }}>
            • Starting slowly and gradually increasing intensity
          </Text>
          <Text fontSize={14} fontWeight='400' style={{ paddingLeft: '16px' }}>
            • Stopping any exercise that causes pain or discomfort
          </Text>
        </Section>

        <Section>
          <SectionTitle fontSize={15} fontWeight='600'>
            NOT MEDICAL ADVICE
          </SectionTitle>
          <Text fontSize={14} fontWeight='400'>
            The content provided through our service does not constitute medical advice, diagnosis,
            or treatment. Our AI system does not have the capability to:
          </Text>
          <Text fontSize={14} fontWeight='400' style={{ paddingLeft: '16px' }}>
            • Assess your specific medical conditions
          </Text>
          <Text fontSize={14} fontWeight='400' style={{ paddingLeft: '16px' }}>
            • Evaluate your individual physical limitations
          </Text>
          <Text fontSize={14} fontWeight='400' style={{ paddingLeft: '16px' }}>
            • Provide rehabilitation programs for injuries or medical conditions
          </Text>
        </Section>

        <Section>
          <SectionTitle fontSize={15} fontWeight='600'>
            MARYLAND LAW
          </SectionTitle>
          <Text fontSize={14} fontWeight='400'>
            This disclaimer is governed by the laws of the State of Maryland. Any claims or disputes
            arising from the use of our services shall be subject to the exclusive jurisdiction of
            the courts located within Maryland.
          </Text>
        </Section>

        <Text fontSize={14} fontWeight='400'>
          By using our AI-generated workout plans, you acknowledge that you have read, understood,
          and agree to the terms of this disclaimer.
        </Text>

        <Text fontSize={14} fontWeight='400'>
          Last Updated: February 27, 2025
        </Text>

        <Text fontSize={14} fontWeight='400'>
          TeamBuildr LLC, 9466 Georgia Ave #2141, Silver Spring, MD 20910
        </Text>

        <ButtonContainer>
          <Button
            name='Decline'
            onClick={onRequestClose}
            bgColor='#FFFFFF'
            borderColor='#444444'
            txtColor='#444444'
            fontSize='14px'
          />
          <Button
            name='I Acknowledge'
            onClick={handleAcknowledge}
            bgColor='#19AB27'
            borderColor='#19AB27'
            txtColor='#FFFFFF'
            noBorder
            fontSize='14px'
          />
        </ButtonContainer>
      </ContentWrapper>
    </Modal>
  );
};

AIAcknowledgmentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onAcknowledge: PropTypes.func.isRequired,
};

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;
`;

const ButtonContainer = styled('div')`
  display: flex;
  gap: 16px;
  margin-top: 24px;
  justify-content: flex-end;
`;

const Section = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const SectionTitle = styled(Text)`
  margin-bottom: 4px;
`;

export default AIAcknowledgmentModal;
