import React, { useState, useCallback } from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

import MaxTable from './MaxTable';
import MaxChart from './MaxChart';

const TableContainer = styled('div')`
  overflow: scroll;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;

  @media screen and (max-height: 825px) {
    overflow: visible;
  }
`;

const ChartContainer = styled('div')`
  margin: 0px 30px;
`;

const ReportContainer = styled('div')`
  display: flex;
  flex-direction: column;
  overflow: visible;
  flex-grow: 1;
  min-height: 0;
  overflow: auto;
`;

const MaxReportData = () => {
  const [activeAthlete, setActiveAthlete] = useState(0);
  const [athletesChartData, setAthletesChartData] = useState({});

  const average = useSelector((state) => state.reporting.data.maxReport.values.average);
  const maxReport = useSelector((state) => state.reporting.data.maxReport.data);
  const layoutHeight = useSelector((state) => state.reporting.ui.layoutHeight);

  // Using useCallback to memoize the tableColumns and tableData array so it only forces the table
  // component to re-render if maxReport changes. In this case the activeAthlete state for the chart
  // was rendering the table for no reason.

  // Table Columns Prep
  const tableColumns = useCallback(maxReport?.columns.map((column, index) => ({
    name: column.valueDescription,
    selector: column.columnId,
    sortable: true,
    sortFunction: (rowA, rowB) => {
      let a = rowA[column.columnId];
      let b = rowB[column.columnId];
      if (column.columnId === 'userFirst' || column.columnId === 'userLast') {
        if (a) {
          a = rowA[column.columnId].toLowerCase();
        }
        if (b) {
          b = rowB[column.columnId].toLowerCase();
        }
      }
      // If a and b are both null (this would be true in the case
      // that these rows are the 'Average' rows)
      if (!a && !b) {
        return 0;
      }
      if (!a) {
        return -1;
      }
      if (!b) {
        return 1;
      }
      // Normal sort comparisons now that we know neither value is null
      if (a < b) {
        return -1;
      } if (a > b) {
        return 1;
      }
      return 0;
    },
    style: {
      fontWeight: column.columnId === 'userFirst' || column.columnId === 'userLast' ? '700' : '400',
    },
    center: !((column.columnId === 'userFirst' || column.columnId === 'userLast')),
    omit: column.columnId === 'userId',
  })), [maxReport]);

  // Table Data Prep
  // const tableData = useCallback([], [maxReport]); Caused issues with duplicate rows when sorting.
  const tableData = [];
  maxReport?.rows.forEach((row) => {
    // Don't add the avearge row to the table if show averages is toggled off
    if (!average && row[0].columnId === 'average') {
      return;
    }
    const tableRow = {};
    row.forEach((column) => {
      const { columnId } = column;
      // Create a key value based on the columnId and displayValue (for Table and showing SAQ).
      // Also display -- when displayValue is empty.
      tableRow[columnId === 'average' ? 'userFirst' : columnId] = column.displayValue === '' ? '--' : column.displayValue;
    });
    tableData.push(tableRow);
  });

  // RawValue Average Data Prep
  // Use this array for the chart prep to render team averages.
  const rawTableData = [];
  maxReport?.rows.forEach((row) => {
    const tableRow = {};
    row.forEach((column) => {
      const { columnId } = column;
      // Create a key value based on the columnId and rawValue (for Bar Chart)
      tableRow[columnId === 'average' ? 'userFirst' : columnId] = column.rawValue;
    });
    rawTableData.push(tableRow);
  });

  return (
    <ReportContainer>
      <ChartContainer>
        <MaxChart
          activeAthlete={activeAthlete}
          layoutHeight={layoutHeight}
          setActiveAthlete={setActiveAthlete}
          athletesChartData={athletesChartData}
          setAthletesChartData={setAthletesChartData}
          rawTableData={rawTableData}
        />
      </ChartContainer>
      <TableContainer>
        <MaxTable
          setActiveAthlete={setActiveAthlete}
          tableColumns={tableColumns}
          tableData={tableData}
        />
      </TableContainer>
    </ReportContainer>
  );
};

export default MaxReportData;
