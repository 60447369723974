/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, {
  memo, useCallback, useState, useEffect, useRef,
} from 'react';
import { differenceWith } from 'ramda';
import PropTypes from 'prop-types';
import { SvgMaleWrapper, SvgMaleWrapperWithButtons } from './components/SvgMaleWrapper';
import { bodyFront } from './assets/bodyFront';
import { bodyBack } from './assets/bodyBack';

const comparison = (a, b) => a.slug === b.slug;

const Body = ({
  colors,
  data,
  height,
  width,
  padding,
  side,
  setSideForMaleWrapperWithButtons,
  onBodyPartPress,
  onBodyPartPressColor,
  resetOnBodyPartPress,
  includeFrontAndBackButtonWrapper,
  includeLeftRightIndicators,
  includeLeftRightIndicatorsWithButtonWrapper,
  noBlur,
}) => {
  const [onClickSelectedColor, setOnClickSelectedColor] = useState(null);
  const [selectedBodyPart, setSelectedBodyPart] = useState(null);
  const [hoveredPosition, setHoveredPosition] = useState(null);
  const [hoveredValue, setHoveredValue] = useState(null);
  const [textWidth, setTextWidth] = useState(0);

  const textRef = useRef(null);

  const handleBodyPartPress = (bodyPart, color) => {
    if (onBodyPartPress) {
      const updatedData = data.find((issue) => issue.slug === bodyPart.slug);
      const isExistingIssue = !!updatedData;

      // Do nothing when the body part has already been selected
      if (selectedBodyPart === bodyPart.slug) {
        return;
      }
      // Determine color to apply to selected body part for new or existing issue
      const updatedColor = isExistingIssue
        ? bodyPart.color ?? updatedData?.color
        : color;

      setSelectedBodyPart(bodyPart.slug);
      setOnClickSelectedColor(updatedColor);
      onBodyPartPress(bodyPart, updatedColor);
    }
  };

  // Updates body part colors live when type or severity changes
  useEffect(() => {
    if (selectedBodyPart) {
      const updatedIssue = data.find((issue) => issue.slug === selectedBodyPart);
      if (updatedIssue && updatedIssue.color) {
        setOnClickSelectedColor(updatedIssue.color);
      }
    }
  }, [data, selectedBodyPart]);

  // Reset the selected body part when going back in the RR tracker modal
  useEffect(() => {
    setSelectedBodyPart(null);
    setOnClickSelectedColor(null);
  }, [resetOnBodyPartPress]);

  useEffect(() => {
    if (textRef.current) {
      const bbox = textRef.current.getBBox();
      setTextWidth(bbox.width);
    }
  }, [hoveredValue]);

  const getTooltipDirection = (slug) => {
    switch (slug) {
      case 'abs-upper':
        return 'right';
      case 'abs-lower':
        return 'right';
      case 'adductors-left-front':
        return 'right';
      case 'adductors-left-back':
        return 'left';
      case 'ankles-left-front':
        return 'right';
      case 'ankles-left-back':
        return 'left';
      case 'biceps-left':
        return 'right';
      case 'calves-left-front':
        return 'right';
      case 'calves-left-back':
        return 'left';
      case 'chest-left':
        return 'right';
      case 'deltoids-left-front':
        return 'right';
      case 'deltoids-left-back':
        return 'left';
      case 'feet-left-front':
        return 'right';
      case 'feet-left-back':
        return 'left';
      case 'forearm-left-front':
        return 'right';
      case 'forearm-left-back':
        return 'left';
      case 'gluteal-left':
        return 'left';
      case 'hamstring-left':
        return 'left';
      case 'hands-left-front':
        return 'right';
      case 'hands-left-back':
        return 'left';
      case 'head-front':
        return 'right';
      case 'head-back':
        return 'right';
      case 'knees-left':
        return 'right';
      case 'lower-back-left':
        return 'left';
      case 'hips-left':
        return 'left';
      case 'neck-left-front':
        return 'right';
      case 'neck-left-back':
        return 'left';
      case 'obliques-left':
        return 'right';
      case 'quadriceps-left':
        return 'right';
      case 'tibialis-left':
        return 'right';
      case 'trapezius-left-front':
        return 'right';
      case 'trapezius-left-back':
        return 'left';
      case 'triceps-left-front':
        return 'right';
      case 'triceps-left-back':
        return 'left';
      case 'upper-back-left':
        return 'left';
      case 'adductors-right-front':
        return 'left';
      case 'adductors-right-back':
        return 'right';
      case 'ankles-right-front':
        return 'left';
      case 'ankles-right-back':
        return 'right';
      case 'biceps-right':
        return 'left';
      case 'calves-right-front':
        return 'left';
      case 'calves-right-back':
        return 'right';
      case 'chest-right':
        return 'left';
      case 'deltoids-right-front':
        return 'left';
      case 'deltoids-right-back':
        return 'right';
      case 'feet-right-front':
        return 'left';
      case 'feet-right-back':
        return 'right';
      case 'forearm-right-front':
        return 'left';
      case 'forearm-right-back':
        return 'right';
      case 'gluteal-right':
        return 'right';
      case 'hamstring-right':
        return 'right';
      case 'hands-right-front':
        return 'left';
      case 'hands-right-back':
        return 'right';
      case 'knees-right':
        return 'left';
      case 'lower-back-right':
        return 'right';
      case 'hips-right':
        return 'right';
      case 'neck-right-front':
        return 'left';
      case 'neck-right-back':
        return 'right';
      case 'obliques-right':
        return 'left';
      case 'quadriceps-right':
        return 'left';
      case 'tibialis-right':
        return 'left';
      case 'trapezius-right-front':
        return 'left';
      case 'trapezius-right-back':
        return 'right';
      case 'triceps-right-front':
        return 'left';
      case 'triceps-right-back':
        return 'right';
      case 'upper-back-right':
        return 'right'; /** Tooltip points left (towards the center) for right-side body parts */
      default:
        return 'right'; /** Fallback direction */
    }
  };

  const getCentroid = (pathString) => {
    /** Create a new SVG path element */
    const pathElement = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    pathElement.setAttribute('d', pathString);

    /** Append the path element to the document */
    document.body.appendChild(pathElement);

    /** Get the total length of the path */
    const totalLength = pathElement.getTotalLength();
    const numPoints = 100; // Number of points to sample
    let x = 0; let
      y = 0;

    /** Sample points along the path */
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < numPoints; i++) {
      const t = (i / (numPoints - 1)) * totalLength;
      const point = pathElement.getPointAtLength(t);
      x += point.x;
      y += point.y;
    }

    /** Remove the temporary path element */
    document.body.removeChild(pathElement);

    /** Return the centroid coordinates */
    return [x / numPoints, y / numPoints];
  };

  const handleBodyPartHover = (bodyPart) => {
    if (bodyPart.pathArray && bodyPart.pathArray[0]) {
      const centroid = getCentroid(bodyPart.pathArray[0]);
      setHoveredPosition({ x: centroid[0], y: centroid[1] });
      setHoveredValue(bodyPart);
    }
  };

  const mergedBodyParts = useCallback(
    (dataSource) => {
      const innerData = data
        .map((d) => dataSource.find((t) => t.slug === d.slug))
        .filter(Boolean);

      const coloredBodyParts = innerData.map((d) => {
        const bodyPart = data.find((e) => e.slug === d.slug);
        let colorIntensity = 1;
        if (bodyPart?.intensity) colorIntensity = bodyPart.intensity;
        return {
          ...d, color: colors[colorIntensity - 1], soreness: bodyPart.soreness, value: bodyPart.value,
        };
      });

      const formattedBodyParts = differenceWith(comparison, dataSource, data);

      return [...formattedBodyParts, ...coloredBodyParts];
    },
    [data, colors],
  );

  const computedHeight = height ?? (includeFrontAndBackButtonWrapper ? 505 : 464);
  const computedWidth = width ?? (includeFrontAndBackButtonWrapper ? 276 : 300);

  const getColorToFill = (bodyPart) => (bodyPart.intensity
    ? colors[bodyPart.intensity] /** Intensity color takes priority */
    : (bodyPart.slug === selectedBodyPart ? onClickSelectedColor : bodyPart.color));

  const renderBodySvg = (dataFrontOrBack) => {
    /** could build out female wrapper in the futer if we choose */
    /** const SvgWrapper = gender === 'male' ? SvgMaleWrapper : SvgFemaleWrapper; */
    const SvgWrapper = includeFrontAndBackButtonWrapper ? SvgMaleWrapperWithButtons : SvgMaleWrapper;

    return (
      <SvgWrapper
        side={side}
        height={computedHeight}
        width={computedWidth}
        padding={padding}
        data={data}
        setSideForMaleWrapperWithButtons={setSideForMaleWrapperWithButtons}
        includeLeftRightIndicators={includeLeftRightIndicators}
        includeLeftRightIndicatorsWithButtonWrapper={includeLeftRightIndicatorsWithButtonWrapper}
        noBlur={noBlur}
      >
        {mergedBodyParts(dataFrontOrBack).map((bodyPart) => {
          if (bodyPart.pathArray) {
            return bodyPart.pathArray.map((path) => (
              <g>
                <path
                  key={path}
                  id={bodyPart.slug}
                  fill={getColorToFill(bodyPart)}
                  fillOpacity={
                    bodyPart.soreness
                      ? (hoveredValue?.slug === bodyPart.slug ? '0.1' : '0.3')
                      : (hoveredValue?.slug === bodyPart.slug ? '0.8' : '1.0')
                  }
                  d={path}
                  style={{
                    cursor: (!!bodyPart?.value || onBodyPartPress) && 'pointer',
                    stroke: bodyPart.soreness && getColorToFill(bodyPart),
                    strokeWidth: bodyPart.soreness && 3,
                  }}
                  onMouseEnter={(event) => (bodyPart?.value ? handleBodyPartHover(bodyPart, event) : null)}
                  onMouseLeave={() => { setHoveredPosition(null); setHoveredValue(null); }}
                  onClick={() => handleBodyPartPress(bodyPart, onBodyPartPressColor)}
                />
              </g>
            ));
          }
          return null;
        })}
        {hoveredPosition && hoveredValue?.value && (
        <g>
          <line
            x1={getTooltipDirection(hoveredValue.slug) === 'right' ? hoveredPosition.x + 20 : hoveredPosition.x + 20}
            y1={hoveredPosition.y}
            x2={getTooltipDirection(hoveredValue.slug) === 'right' ? hoveredPosition.x + 90 : hoveredPosition.x - 90}
            y2={hoveredPosition.y}
            stroke='#444444'
            strokeWidth={2}
          />
          <circle
            cx={hoveredPosition.x + 17}
            cy={hoveredPosition.y}
            r={6}
            fill='#444444'
          />
          <text
            ref={textRef}
            x={getTooltipDirection(hoveredValue.slug) === 'right' ? hoveredPosition.x + 100 : hoveredPosition.x - (100 + textWidth)}
            y={hoveredPosition.y + 16}
            fill='#444444'
            fontSize='48px'
            fontWeight={700}
            fontFamily='Nunito Sans'
          >
            {hoveredValue.value}
          </text>
        </g>
        )}
      </SvgWrapper>
    );
  };

  return renderBodySvg(
    side === 'front' ? bodyFront : bodyBack,
  );
};

Body.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string), /** Array of color strings, the number of colors you pass
  should match the intensity scale coming from your data. For example if you have an intensity scale in the data that is 1-3
  you should pass 3 colors in the array in order of least intense color to most */
  /** example: [yellow, green, blue] I would expect data slug with intensity 1 to be yellow, data slug with
   intensity 2 to be green and data slug with intensity 3 to be blue */
  data: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string.isRequired,
    intensity: PropTypes.number.isRequired,
    soreness: PropTypes.bool, /** This is optional and used to differentiate between reported pain vs soreness */
    value: PropTypes.string, /** This is optional and if you include a value there will be a tool tip associated with the
    hovered body part that shows this value */
  })),
  height: PropTypes.number,
  width: PropTypes.number,
  padding: PropTypes.number,
  side: PropTypes.oneOf(['front', 'back']),
  setSideForMaleWrapperWithButtons: PropTypes.func, /** local state function for setting side with buttons passed from parent */
  onBodyPartPress: PropTypes.func, /** Function for handling body part press */
  onBodyPartPressColor: PropTypes.string,
  resetOnBodyPartPress: PropTypes.func, /** Function for resetting selected body part */
  includeFrontAndBackButtonWrapper: PropTypes.bool, /** Boolean for including front and back button wrapper */
  includeLeftRightIndicators: PropTypes.bool, /** Boolean for including left-right indicators on heat map with no button wrapper */
  includeLeftRightIndicatorsWithButtonWrapper: PropTypes.bool, /** Boolean for including left-right indicators with button wrapper */
  noBlur: PropTypes.bool, /** Boolean for turning off blur filter when there is no data, which is on by default */
};

Body.defaultProps = {
  colors: ['#FFCB47', '#F67361'],
  data: [],
  side: 'front',
  setSideForMaleWrapperWithButtons: null,
  onBodyPartPress: null,
  onBodyPartPressColor: '#898989',
  resetOnBodyPartPress: null,
  includeFrontAndBackButtonWrapper: false,
  height: null,
  width: null,
  padding: 16,
  includeLeftRightIndicators: false,
  includeLeftRightIndicatorsWithButtonWrapper: true,
  noBlur: false,
};

export default memo(Body);
