// authored by Joshua Beedle
import React from 'react';
import { animated, useSpring } from 'react-spring';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import styled from '@emotion/styled';

import Wrapper from './Wrapper';
import ButtonText from './ButtonText';
import iconSet from '../../images/teambuildr-selection.json';
import Spinner from '../Spinner';

const Icon = styled('img')`
  height: 20px;
  margin-right: 20px;
`;

/**
 * @param {String} borderColor custom color for button border
 * @param {Boolean} bottom bool to determine align-self to flex-end or not
 * @param {String} bgColor custom color for button background
 * @param {String} customBorderRadius custom radius for button border
 * @param {String} customIcon custom icon for button not in icon json file
 * @param {Boolean} disabled bool to determine disabling the button
 * @param {String} fontSize custom font size for ButtonText
 * @param {Boolean} fullWidth bool to determine streching the button to full width of container
 * @param {String} icon name of the icon attached to button
 * @param {String} iconColor color of the icon attached to button
 * @param {String} iconMarginTop custom margin-top for attached icon
 * @param {Boolean} iconOnly bool to determine if button is just displaying the icon
 * @param {Number} iconSize custom icon size for attached icon
 * @param {Boolean} isLoading bool to determine displaying a loading spinner in the button
 * @param {Boolean} large bool to determine if the button height is large (48px)
 * @param {String} name title of the button
 * @param {Boolean} noBorder bool to determine if the button is borderless
 * @param {Boolean} noHover bool to determine if the button should not have a hover effect
 * @param {Boolean} rounded bool to determine if the button is rounded
 * @param {String} spinnerColor custom color for the spinner while loading
 * @param {Boolean} square bool to determine if the button is squared
 * @param {Object} styledTextProps custom props for ButtonText
 * @param {Object} styledWrapperProps custom props for Wrapper
 * @param {String} txtColor custom color for ButtonText
 * @param {Boolean} upperCase bool to determine if text should be upper case
*/

const Button = ({
  borderColor,
  bottom,
  bgColor,
  customBorderRadius,
  customIcon,
  disabled,
  fontSize,
  fullWidth,
  icon,
  iconColor,
  iconMarginTop,
  iconOnly,
  iconSize,
  isLoading,
  large,
  name,
  noBorder,
  noHover,
  onClick,
  rounded,
  spinnerColor,
  square,
  styledTextProps,
  styledWrapperProps,
  txtColor,
  upperCase,
}) => {
  const spinnerAnimation = useSpring({ opacity: 1 });

  return (
    <Wrapper
      borderColor={borderColor}
      bottom={bottom}
      bgColor={bgColor}
      customBorderRadius={customBorderRadius}
      customIcon={customIcon}
      disabled={disabled}
      fullWidth={fullWidth}
      icon={icon}
      iconMarginTop={iconMarginTop}
      iconOnly={iconOnly}
      iconSize={iconSize}
      isLoading={isLoading}
      large={large}
      noBorder={noBorder}
      noHover={noHover}
      onClick={onClick}
      rounded={rounded}
      spinnerColor={spinnerColor}
      square={square}
      {...styledWrapperProps}
    >
      {customIcon ? (
        <Icon alt='button' className='fluid' src={customIcon} />
      ) : (
        icon && (
        <IcomoonReact
          className='icomoon-svg'
          icon={icon && icon}
          iconSet={iconSet}
          size={iconSize}
          color={iconColor}
        />
        )
      )}
      {isLoading ? (
        <animated.div style={spinnerAnimation}>
          <Spinner large={false} />
        </animated.div>
      ) : (
        !iconOnly && (
          <ButtonText
            fontSize={fontSize}
            {...styledTextProps}
            txtColor={txtColor}
          >
            {upperCase ? name.toUpperCase() : name}
          </ButtonText>
        )
      )}
    </Wrapper>
  );
};

Button.propTypes = {
  borderColor: PropTypes.string,
  bottom: PropTypes.bool,
  bgColor: PropTypes.string,
  customBorderRadius: PropTypes.string,
  customIcon: PropTypes.string,
  disabled: PropTypes.bool,
  fontSize: PropTypes.string,
  fullWidth: PropTypes.bool,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  iconMarginTop: PropTypes.string,
  iconOnly: PropTypes.bool,
  iconSize: PropTypes.number,
  isLoading: PropTypes.bool,
  large: PropTypes.bool,
  name: PropTypes.string.isRequired,
  noBorder: PropTypes.bool,
  noHover: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  rounded: PropTypes.bool,
  spinnerColor: PropTypes.string,
  square: PropTypes.bool,
  styledTextProps: PropTypes.instanceOf(Object),
  styledWrapperProps: PropTypes.instanceOf(Object),
  txtColor: PropTypes.string,
  upperCase: PropTypes.bool,
};

Button.defaultProps = {
  borderColor: '#444444',
  bottom: false,
  bgColor: null,
  customBorderRadius: null,
  customIcon: null,
  disabled: false,
  fontSize: '12px',
  fullWidth: false,
  icon: '',
  iconColor: '#444444',
  iconMarginTop: '0px',
  iconOnly: false,
  iconSize: 15,
  isLoading: false,
  large: false,
  noBorder: false,
  noHover: false,
  rounded: false,
  spinnerColor: '#444444',
  square: false,
  styledTextProps: {},
  styledWrapperProps: {},
  txtColor: '#444444',
  upperCase: false,
};

export default Button;
