/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'emotion-theming';
import Shimmer from 'react-shimmer-effect';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Text from '../../../../shared/components/Text/Text';
import SharedPickerComponent from './SharedPickerComponent';
import {
  setActiveAthlete, setActiveMetricType, fetchAthleteAverages, fetchWearablesReportForDataChartByGroupOrAthleteId, setFilterInfoFromPieChart,
} from '../../ducks/wearablesDashboardActions';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const SharedPickerCard = ({ pickerType, textToDisplay, containerWidth }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [athleteListWithGroup, setAthleteListWithGroup] = useState([]);

  const isAthleteParticipationLoading = useSelector((state) => state.wearablesDashboard.ui.isAthleteParticipationLoading);
  const activeAthlete = useSelector((state) => state.wearablesDashboard.data.activeAthlete);
  const activeMetricType = useSelector((state) => state.wearablesDashboard.data.activeMetricType);
  const activeGroup = useSelector((state) => state.wearablesDashboard.data.activeGroup);
  const activeUserIds = useSelector((state) => state.wearablesDashboard.data.activeUserIds);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const accountCode = currentUser?.accountCode;
  const activeWearablesDashDate = useSelector((state) => state.wearablesDashboard.data.activeWearablesDashDate);
  const arrayOfAthletesForAthletePicker = useSelector((state) => state.wearablesDashboard.data.athleteParticipation?.active?.users);

  const metricTypeList = [
    {
      id: 1,
      name: 'Exertion Score',
      rawValue: 1,
    },
    {
      id: 2,
      name: 'Sleep',
      rawValue: 2,
    },
    {
      id: 3,
      name: 'HRV',
      rawValue: 3,
    },
    {
      id: 4,
      name: 'Steps',
      rawValue: 4,
    },
    {
      id: 5,
      name: 'Body Weight',
      rawValue: 5,
    },
  ];

  const reduxFunctionForAthletePicker = (athlete) => {
    // Amplitude tracking
    dashboardTracker('Wearables Dashboard', 'Currently Viewing/Athlete Picker', 'Option changed');
    dispatch(setFilterInfoFromPieChart());
    const athleteId = athlete.id;
    /** if there is a name property in the active athlete object we know
    * if a group, if there is a first and last property then we know it's an athlete
     */
    const filterType = athlete?.name ? 1 : 3;
    const metricTypeRawValue = activeMetricType?.rawValue;
    dispatch(setActiveAthlete(athlete));
    dispatch(fetchAthleteAverages(accountCode, athleteId, activeWearablesDashDate, filterType, metricTypeRawValue));

    const filterTypeForWearablesRawReport = 1;
    const filterIds = athlete?.name ? [activeUserIds] : [athleteId];
    const csv = false;
    dispatch(fetchWearablesReportForDataChartByGroupOrAthleteId(accountCode, filterIds, activeWearablesDashDate, filterTypeForWearablesRawReport, csv));
  };

  const reduxFunctionForMetricPicker = (metricType) => {
    // Amplitude tracking
    dashboardTracker('Wearables Dashboard', 'Metric Type Picker', `Metric type option changed - ${metricType.name}`);
    dispatch(setFilterInfoFromPieChart());
    dispatch(setActiveMetricType(metricType));
    const athleteId = activeAthlete?.id;
    const filterType = activeAthlete?.name ? 1 : 3;
    const metricTypeRawValue = metricType.rawValue;

    dispatch(fetchAthleteAverages(accountCode, athleteId, activeWearablesDashDate, filterType, metricTypeRawValue));

    const filterTypeForWearablesRawReport = 1;
    const filterIds = activeAthlete?.name ? [activeUserIds] : [athleteId];
    const csv = false;
    dispatch(fetchWearablesReportForDataChartByGroupOrAthleteId(accountCode, filterIds, activeWearablesDashDate, filterTypeForWearablesRawReport, csv));
  };

  useEffect(() => {
    if (arrayOfAthletesForAthletePicker) {
      setAthleteListWithGroup([activeGroup, ...arrayOfAthletesForAthletePicker]);
    }
  }, [arrayOfAthletesForAthletePicker]);

  const Wrapper = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 25px 5px;
    height: 68px;
    width: ${(props) => (props.containerWidth <= 350 ? '260px' : '320px')};
    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 8px 16px rgba(48, 49, 51, 0.1);
    border-radius: 24px;
    background-color: ${theme.colors.background[0]};
  `;

  const SharedPickerCardLoadingShimmer = styled('div')`
    display: flex;
    height: 68px;
    width: ${(props) => (props.containerWidth <= 350 ? '260px' : '320px')};
    border-radius: 24px;
`;

  const AthletePicker = () => {
    if (arrayOfAthletesForAthletePicker && arrayOfAthletesForAthletePicker.length > 0 && Object.keys(activeAthlete).length) {
      return <SharedPickerComponent list={athleteListWithGroup} reduxFunction={reduxFunctionForAthletePicker} visibleContainerItem={activeAthlete} icon={{ name: 'swap', size: 16, color: '#444444' }} isAthletePicker pickerButtonColor='#e8e8e8' pickerFontColor='#444444' small addSearchBar containerWidth={containerWidth} />;
    }
    return <Text fontSize={12} fontWeight={700}>No connected athletes.</Text>;
  };

  const MetricPicker = () => {
    if (arrayOfAthletesForAthletePicker && arrayOfAthletesForAthletePicker.length > 0 && Object.keys(activeAthlete).length) {
      return <SharedPickerComponent list={metricTypeList} reduxFunction={reduxFunctionForMetricPicker} visibleContainerItem={activeMetricType} icon={{ name: 'swap', size: 16, color: '#444444' }} isMetricPicker pickerButtonColor='#e8e8e8' pickerFontColor='#444444' small containerWidth={containerWidth} />;
    }
    return <Text fontSize={12} fontWeight={700}>No metrics available.</Text>;
  };

  return (
    <>
      {isAthleteParticipationLoading ? (
        <Shimmer>
          <SharedPickerCardLoadingShimmer containerWidth={containerWidth} />
        </Shimmer>
      ) : (
        <Wrapper containerWidth={containerWidth}>
          <Text fontSize={15} fontWeight={700}>{textToDisplay}</Text>
          {pickerType === 'athletePicker' && (AthletePicker())}
          {pickerType !== 'athletePicker' && (MetricPicker())}
        </Wrapper>

      )}
    </>
  );
};

SharedPickerCard.propTypes = {
  pickerType: PropTypes.string.isRequired,
  textToDisplay: PropTypes.string.isRequired,
};
export default SharedPickerCard;
