import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ThemeProvider } from 'emotion-theming';
import LogRocket from 'logrocket';
import Logger from 'js-logger';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import hexToHSL from '../../../shared/utils/hexToHSL';
import Header from './presentational/Header';
import Spinner from './presentational/Spinner';
import WeightroomPanel from './container/WeightroomPanel';
import PayGate from './presentational/PayGate';
import PanelSettings from './presentational/PanelSettings';
import WeightroomProgramListModal from './container/WeightroomProgramListModal';
import WeightroomResetProgramModal from './container/WeightroomResetProgramModal';
import WeightroomProgramInfoModal from './container/WeightroomProgramInfoModal';
import WeightroomCompleteProgramModal from './container/WeightroomCompleteProgramModal';
import WeightroomCompleteProgramWorkoutModal from './container/WeightroomCompleteProgramWorkoutModal';
import WeightroomSkipProgramWorkoutModal from './container/WeightroomSkipProgramWorkoutModal';
import ImportedDataModal from '../../../shared/components/ImportedDataModal/ImportedDataModal';
import GroupAccessModal from './presentational/GroupAccessModal';
import RecoveryRadarTracker from '../../recovery-radar-tracker/RecoveryRadarTracker';

const WeightRoomContainer = styled('div')`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`;

const PanelsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  overflow-y: auto;
`;

const SpinnerContainer = styled('div')`
  height: 100vh;
`;

const WeightroomView = ({
  currentUser,
  closeGlobalModal,
  handleScreenToggle,
  openGlobalModal,
}) => {
  const darkTheme = {
    backgroundColor: 'rgba(0, 0, 0, 0.87)',
    backgroundColorL2: 'rgb(65, 65, 65)',
    backgroundColorL3: 'rgb(95, 95, 95)',
    borderColor: 'rgb(100, 100, 100)',
    buttonBorderColor: 'rgb(65, 65, 65)',
    buttonBorderColor2: 'rgb(65, 65, 65)',
    focusColor: '#0ab3cd',
    noBorderColor: 'rgb(58, 58, 58)',
    textColor: 'rgba(255, 255, 255, 0.80)',
    lightTextColor: 'rgba(255, 255, 255, 0.75)',
    lightTextColor2: 'rgba(255, 255, 255, 0.65)',
    overlayColor: 'rgba(30, 30, 30, 0.6)',
    noBorderColorForm: 'rgb(95, 95, 95)',
    iconBorderColor: 'rgba(65, 65, 65)',
    overflowGradient: 'linear-gradient(to right,rgba(36,36,36,0),rgba(36,36,36,1))',
  };

  const lightTheme = {
    backgroundColor: 'white',
    backgroundColorL2: 'white',
    backgroundColorL3: 'white',
    buttonBorderColor: '#979797',
    buttonBorderColor2: '#acacac',
    borderColor: '#e4e7e7',
    noBorderColor: '#979797',
    focusColor: '#0dcc8a',
    textColor: '#444444',
    lightTextColor: '#979797',
    lightTextColor2: '#686868',
    overlayColor: 'rgba(216, 216, 216, 0.5)',
    noBorderColorForm: 'hsl(0,0%,80%)',
    iconBorderColor: '#444444',
    overflowGradient: 'linear-gradient(to right,rgba(255,255,255,0),rgba(255,255,255,1))',
  };

  const activeScreens = useSelector((state) => state.weightRoomView.ui.activeScreens);
  const fetchedGroups = useSelector((state) => state.weightRoomView.ui.fetchedGroups);
  const fetchedUsers = useSelector((state) => state.weightRoomView.ui.fetchedUsers);
  const isDarkThemeEnabled = useSelector((state) => state.weightRoomView.ui.isDarkThemeEnabled);
  const skin = useSelector((state) => state.weightRoomView.data.currentUser.userSkin);
  const isGroupAccessModalShowing = useSelector(
    (state) => state.weightRoomView.ui.isGroupAccessModalShowing,
  );

  const [theme, setTheme] = useState(isDarkThemeEnabled ? darkTheme : lightTheme);

  useEffect(() => {
    setTheme(isDarkThemeEnabled ? darkTheme : lightTheme);
  }, [isDarkThemeEnabled]);

  const colorPrimary = skin && (isDarkThemeEnabled ? hexToHSL(skin.primary) : skin.primary);
  const colorAccent = skin && (isDarkThemeEnabled ? hexToHSL(skin.accent) : (skin.accent));

  const Theme = {
    colorAccent: skin && colorAccent,
    colorPrimary: skin && colorPrimary,
    isAccentDarkTheme: skin && skin.accentDarkTheme,
    isPrimaryDarkTheme: skin && skin.darkTheme,
    ...theme,
  };

  if (process.env.ENVIRONMENT === 'PRODUCTION' && (currentUser.userSettings && currentUser.userSettings.sessionLoggingEnabled === 1)) {
    LogRocket.init('v2xspp/tb-weightroom-view');
    Logger.debug('Logrocket Enabled v1.2.00');
    LogRocket.identify(currentUser && currentUser.id, {
      name: currentUser && `${currentUser.first} ${currentUser.last}`,
      email: currentUser && currentUser.email,
      organizationId: currentUser && currentUser.organizationId,
      organizationAccessLevel: currentUser && currentUser.organizationAccessLevel,
      subscriptionId: currentUser && currentUser.organizationSubscriptionId,
      appVersion: '1.2.00',
    });
  }

  useEffect(() => {
    Logger.debug(process.env.ENVIRONMENT);
    const setVhProperty = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    setVhProperty();
    window.addEventListener('resize', setVhProperty);
    return () => {
      window.removeEventListener('resize', setVhProperty);
    };
  }, []);

  const panelComponentRef = useRef();

  const handleScreenClick = () => {
    handleScreenToggle();
  };

  const isFetchComplete = (fetchedGroups === true && fetchedUsers === true);
  const hasAccess = currentUser && (currentUser.organizationAccessLevel >= 5);
  const isPrimaryDarkTheme = skin && (skin.darkTheme);
  return (
    <ThemeProvider theme={Theme}>
      <Helmet>
        <title>Weightroom View | TeamBuildr</title>
      </Helmet>
      <WeightRoomContainer>
        {isFetchComplete ? (
          <>
            <Header
              isPrimaryDarkTheme={isPrimaryDarkTheme}
              logo={skin.logo}
              openGlobalModal={openGlobalModal}
              closeGlobalModal={closeGlobalModal}
              screens={activeScreens}
              handleScreenToggle={handleScreenClick}
            />
            <PanelSettings />
            {/* In order to pass namespace to the program modals */}
            {Array(activeScreens).fill().map((set, i) => (
              <>
                <WeightroomProgramListModal
                  namespace={`panel${i + 1}`}
                />
                <WeightroomResetProgramModal
                  namespace={`panel${i + 1}`}
                />
                <WeightroomProgramInfoModal
                  namespace={`panel${i + 1}`}
                />
                <WeightroomCompleteProgramModal
                  namespace={`panel${i + 1}`}
                />
                <WeightroomCompleteProgramWorkoutModal
                  namespace={`panel${i + 1}`}
                />
                <WeightroomSkipProgramWorkoutModal
                  namespace={`panel${i + 1}`}
                />
              </>
            ))}
            {/* In order to pass namespace to the integration modals */}
            {Array(activeScreens).fill().map((set, i) => (
              <>
                <ImportedDataModal
                  namespace={`panel${i + 1}`}
                  isWeightRoomView
                />
              </>
            ))}
            <RecoveryRadarTracker modalContext='weightRoomView' />
            <PanelsContainer>
              {Array(activeScreens).fill().map((set, i) => (
                <>
                  <WeightroomPanel
                    key={i}
                    ref={panelComponentRef[i]}
                    namespace={`panel${i + 1}`}
                    handleScreenClick={handleScreenClick}
                  />
                </>
              ))}
            </PanelsContainer>
            {!hasAccess && (
              <PayGate
                accountRequired='Platinum'
              />
            )}
          </>
        ) : (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
        {isGroupAccessModalShowing && (
          <GroupAccessModal />
        )}
      </WeightRoomContainer>
    </ThemeProvider>
  );
};

WeightroomView.propTypes = {
  closeGlobalModal: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Object).isRequired,
  handleScreenToggle: PropTypes.func.isRequired,
  openGlobalModal: PropTypes.func.isRequired,
};

export default WeightroomView;
