import React from 'react';
import DataTable, { createTheme } from 'react-data-table-component';

createTheme('teambuildr', {
  text: {
    primary: '#444444',
    secondary: '#898989',
  },
  background: {
    default: 'transparent',
  },
});

const teambuildrStyles = {
  headRow: {
    style: {
      padding: '20px 10px',
      border: 'none',
      pageBreakAfter: 'avoid',
      pageBreakBefore: 'avoid',
    },
  },
  headCells: {
    style: {
      fontFamily: 'Nunito Sans',
      fontSize: '12px',
      fontWeight: '900',
      textTransform: 'uppercase',
      letterSpacing: '1.29px',
      textAlign: 'center',
    },
  },
  cells: {
    style: {
      padding: '20px 18px',
      fontFamily: 'Nunito Sans',
      fontSize: '12px',
      fontWeight: '800',
    },
  },
  rows: {
    style: {
      background: 'white',
      marginBottom: '10px',
      borderBottomWidth: '0px',
      borderRadius: '4px',
      boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.1)',
      pageBreakInside: 'avoid',
    },
    highlightOnHoverStyle: {
      backgroundColor: '#f9f9f9',
      borderBottomColor: '#f9f9f9',
    },
  },
};

/**
 * Adds additional styles to table from the component that is rendering it
 * @param {*} options Dictionary of options
 */
const generateStyle = (options) => {
  if (options.headCells && options.headCells.textAlign) {
    teambuildrStyles.headCells.style.textAlign = options.headCells.textAlign;
  }
  if (options.props && options.props.fitContainer) {
    teambuildrStyles.tableWrapper = {
      style: {
        display: 'block',
      },
    };
  }
  return teambuildrStyles;
};

const Table = ({
  ...props
}) => {
  const options = { props };

  return (
    <DataTable
      {...props}
      customStyles={generateStyle(options)}
      theme='teambuildr'
    />
  );
};

export default Table;
