import React, { useEffect, useRef } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import { init, identify, Identify } from '@amplitude/analytics-browser';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import Logger from 'js-logger';

import { ThemeProvider } from 'emotion-theming';
import { useSelector } from 'react-redux';

import AppRoutes from '../../services/Routes/routes';
import mainTheme from '../theme/theme';
import FourOhFourRoute from '../../services/Routes/FourOhFourRoute';

const AppWrapper = () => {
  const isAmplitudeInitialized = useRef(false);

  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const showMaintenanceModeAlert = useSelector((state) => state.auth.ui.showMaintenanceModeAlert);
  const skin = currentUser?.userSkin !== undefined && currentUser?.userSkin;
  const ldClient = useLDClient();

  // START Amplitude setup
  useEffect(() => {
    // Only allow Amplitude to initialize in production and after a user is logged in.
    if (process.env.ENVIRONMENT === 'PRODUCTION' && currentUser?.id && !isAmplitudeInitialized?.current) {
      // Initialize Amplitude
      init('153704185f16943e3f8609c338cdb899', {
        defaultTracking: true,
        minIdLength: 1,
        userId: currentUser?.id,
      });

      // Create an Identify instance to set Amplitude user properties
      const identifyUser = new Identify();

      // Set multiple user properties
      identifyUser.set('admin', currentUser?.admin);
      identifyUser.set('gender', currentUser?.gender);
      identifyUser.set('organizationAccessLevel', currentUser?.organizationAccessLevel);
      identifyUser.set('organizationId', currentUser?.organizationId);
      identifyUser.set('organizationName', currentUser?.organizationName);
      identifyUser.set('organizationResellerAccountEnabled', currentUser?.organizationResellerAccountEnabled);
      identifyUser.set('organizationStripeSubscriptionActive', currentUser?.organizationStripeSubscriptionActive);
      identifyUser.set('organizationSubscriptionStatus', currentUser?.organizationSubscriptionStatus);
      identifyUser.set('primaryAdmin', currentUser?.primaryAdmin);
      identifyUser.set('ssHubEnabled', currentUser?.userOrgSSHubSettings?.ssHubEnabled);

      // Set the Amplitude user properties
      identify(identifyUser);

      // Set the useRef to true to prevent re-initialization
      isAmplitudeInitialized.current = true;
    }
  }, [currentUser]);
  // END Amplitude setup

  const theme = {
    colorAccent: skin && skin.accent,
    colorPrimary: skin && skin.primary,
    colorSecondary: skin && skin.secondary,
    isDarkMode: skin && skin.darknessMode,
    isAccentDarkTheme: skin && skin.accentDarkTheme,
    isPrimaryDarkTheme: skin && skin.darkTheme,
    isSecondaryDarkTheme: skin && skin.secondaryDarkTheme,
    ...mainTheme,
  };

  const StyledToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
  }
  .Toastify__toast {
  }
  .Toastify__toast-body {
    font-family: 'Nunito Sans';
    color: white;
  }
  .Toastify__close-button {
    color: white;
    opacity: 1;
    :hover {
      opacity: 0.7;
    }
  }
  .Toastify__close-button > svg {
    color: white;
  }
  .Toastify__toast-theme--light {
    background: #444444;
  }
`;

  useEffect(() => {
    if (showMaintenanceModeAlert) {
      // TODO: potentially create a nicer banner component to show this message
      const alertMessage = 'TeamBuildr is currently under maintenance. Please visit https://status.teambuildr.com to check the current status of TeamBuildr servers.';
      alert(alertMessage);
    }
  }, [showMaintenanceModeAlert]);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length) {
      ldClient.identify({
        kind: 'user',
        key: currentUser.id,
        name: `${currentUser.first} ${currentUser.last}`,
        email: currentUser.email,
        custom: {
          accountCode: currentUser.accountCode,
          platform: 'web',
        },
      });
    }
  }, [currentUser]);

  return (
    <ThemeProvider theme={theme}>
      <Sentry.ErrorBoundary fallback={<FourOhFourRoute errorBoundary />}>
        <div className='custom-toast-container' style={{ position: 'absolute' }}>
          <StyledToastContainer
            position='bottom-right'
            autoClose={5000}
            closeOnClick
            pauseOnHover
            theme='dark'
          />
        </div>
        <AppRoutes />
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  );
};

export default AppWrapper;
