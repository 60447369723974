import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import ResizeObserver from 'react-resize-observer';
import PropTypes from 'prop-types';

import { useTheme } from 'emotion-theming';
import { setSidebarWidth, setLayoutHeight } from '../ducks/reportingActions';
import ReportingSidebarFilter from './ReportingSidebarFilter';
import ActiveReportSidebar from './ActiveReportSidebar';
import { BoxShadow, TransitionGlobal } from '../../../shared/GlobalStyles';
import Button from '../../../shared/_TBComponents/Button';

const SidebarContainer = styled('aside')`
  height: 100%;
  left: 0px;
  background: ${(props) => ((props.activeReport === 'questionnaire' || props.activeReport === 'completion') && props.isReportOpen ? '#F5F5F5' : props.theme.colors.background[0])};
  align-self: flex-start;
  transition: ${TransitionGlobal};
  width: ${(props) => (props.isReportOpen ? '100px' : '350px')};

  h1 {
    color: ${(props) => props.theme.colors.text};
    letter-spacing: 1.3px;
    margin-bottom: 15px;
    font-size: 22px;
  }

  @media screen and (max-width: 767px) {
    position: absolute;
    width: 100%;
    transform: ${(props) => (props.isMobileSidebarActive
    ? 'translate(0px, -0%)'
    : 'translate(0px, -110%)')};
    height: calc(100% - 49px);
    padding-bottom: 80px;
    z-index: 100000;
  }

  @media screen and (min-width: 767px) and (max-width: 1024px) {
    width: ${(props) => (props.isReportOpen ? '100px' : '300px')};
  }
`;

const ReportingSidebar = ({ handlePrint }) => {
  const [isMobileSidebarActive, setMobileSidebarActive] = useState(false);
  const [isButtonVisible, setButtonVisible] = useState(true);

  const isReportOpen = useSelector((state) => state.reporting.ui.isReportOpen);
  const activeModal = useSelector((state) => state.reporting.ui.activeModal);
  const sidebarFilter = useSelector(
    (state) => state.reporting.data.sidebarFilter,
  );
  const activeReport = useSelector((state) => state.reporting.ui.activeReport);

  const dispatch = useDispatch();

  const theme = useTheme();

  const updateSidebarWidth = (width) => {
    dispatch(setSidebarWidth(width));
  };
  const updateLayoutHeight = (height) => {
    dispatch(setLayoutHeight(height));
  };

  // Logic to display the filter button or not
  useEffect(() => {
    const handleResize = () => {
      setButtonVisible(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <SidebarContainer
        activeModal={activeModal}
        isReportOpen={isReportOpen || activeModal === 'downloadMaxReport' || activeModal === 'downloadActivityReport' || activeModal === 'downloadQuestionnaireReport' || activeModal === 'downloadEvaluationReport' || activeModal === 'downloadOptOutNotesReport' || activeModal === 'downloadResultsReport' || activeReport === 'downloadComparisonReport' || activeReport === 'downloadCompletionReport'}
        isMobileSidebarActive={isMobileSidebarActive}
        activeReport={activeReport}
      >
        <ResizeObserver
          onResize={(rect) => {
            updateSidebarWidth(rect.width);
            updateLayoutHeight(rect.height);
          }}
        />
        {!isReportOpen && activeModal !== 'downloadMaxReport' && activeModal !== 'downloadActivityReport' && activeModal !== 'downloadQuestionnaireReport' && activeModal !== 'downloadEvaluationReport' && activeModal !== 'downloadOptOutNotesReport' && activeModal !== 'downloadResultsReport' && activeModal !== 'downloadComparisonReport' && activeModal !== 'downloadCompletionReport' ? (
          <ReportingSidebarFilter />
        ) : (
          <ActiveReportSidebar
            download={activeReport === 'max' || activeReport === 'activity' || activeReport === 'questionnaire' || activeReport === 'completion'}
            edit={activeReport === 'max' || activeReport === 'questionnaire' || activeReport === 'workout_results' || activeReport === 'opt_out_notes' || activeReport === 'comparison' || activeReport === 'completion'}
            handlePrint={handlePrint}
            print
          />
        )}
      </SidebarContainer>
      {isButtonVisible && !isReportOpen && (
        <Button
          bgColor={isMobileSidebarActive ? theme.colors.green : '#FFFFFF'}
          icon={isMobileSidebarActive ? 'checkmark' : ''}
          name={`${isMobileSidebarActive ? (
            'Finish Selection'
          ) : (
            `${sidebarFilter.filterIds ? sidebarFilter.filterIds.length : 0} ${sidebarFilter.filterType && sidebarFilter.filterType.label.split(' ')[2]}s Selected`
          )}`}
          noBorder
          onClick={() => setMobileSidebarActive(!isMobileSidebarActive && true)}
          spinnerColor='#FFFFFF'
          styledWrapperProps={{
            alignSelf: 'center',
            bottom: '30px',
            boxShadow: BoxShadow,
            padding: '10px 18px',
            position: 'absolute',
            zIndex: 100000,
          }}
          txtColor={isMobileSidebarActive ? '#FFFFFF' : '#444444'}
          upperCase
        />
      )}
    </>
  );
};

ReportingSidebar.propTypes = {
  handlePrint: PropTypes.func,
};

ReportingSidebar.defaultProps = {
  handlePrint: () => {},
};

export default ReportingSidebar;
