import React from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import RecoveryRadarTrackerModal from './components/presentational/RecoveryRadarTrackerModal';
import {
  setIsRecoveryRadarWorkoutModalShowing,
  setIsRecoveryRadarWeightroomModalShowing,
  setSelectedBodyPart,
  updateBodyPartIssue,
} from './ducks/recoveryRadarTrackerActions';

const RecoveryRadarTrackerModalOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(216, 216, 216);
  z-index: 1000;
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
`;

const RecoveryRadarTracker = ({ modalContext }) => {
  const dispatch = useDispatch();

  const isModalOpen = useSelector((state) => {
    if (modalContext === 'workoutEntry') {
      return state.recoveryRadarTracker.ui.isRecoveryRadarWorkoutModalShowing;
    }
    if (modalContext === 'weightRoomView') {
      return state.recoveryRadarTracker.ui.isRecoveryRadarWeightroomModalShowing;
    }
    return false;
  });

  const handleClose = () => {
    batch(() => {
      dispatch(setSelectedBodyPart(''));
      dispatch(updateBodyPartIssue({}));
    });
    if (modalContext === 'workoutEntry') {
      dispatch(setIsRecoveryRadarWorkoutModalShowing(false));
    } else if (modalContext === 'weightRoomView') {
      dispatch(setIsRecoveryRadarWeightroomModalShowing(false));
    }
  };

  if (!isModalOpen) return null;

  return (
    <>
      <RecoveryRadarTrackerModal isOpen={isModalOpen} onClose={handleClose} />
      <RecoveryRadarTrackerModalOverlay
        isActive={isModalOpen}
        onClick={handleClose}
      />
    </>
  );
};

RecoveryRadarTracker.propTypes = {
  modalContext: PropTypes.oneOf(['workoutEntry', 'weightRoomView']).isRequired,
};

export default RecoveryRadarTracker;
