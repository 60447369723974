import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import MaxReport from './MaxReport/MaxReport';
import ActivityReport from './ActivityReport/ActivityReport';
import RawDataReport from './RawDataReport/RawDataReport';
import QuestionnaireReport from './QuestionnaireReport/QuestionnaireReport';
import EvaluationReport from './EvaluationReport/EvaluationReport';
import Report from './BaseResultsReport/Report';
import ComparisonReport from './ComparisonReport/ComparisonReport';
import CompletionReport from './CompletionReport/CompletionReport';

const ActiveReport = ({ handlePrint, printRef }) => {
  const activeReport = useSelector((state) => state.reporting.ui.activeReport);

  return (
    <>
      {activeReport === 'max' && (
        <MaxReport handlePrint={handlePrint} printRef={printRef} />
      )}
      {activeReport === 'activity' && (
        <ActivityReport handlePrint={handlePrint} printRef={printRef} />
      )}
      {activeReport === 'raw_data' && (
        <RawDataReport />
      )}
      {activeReport === 'questionnaire' && (
        <QuestionnaireReport handlePrint={handlePrint} printRef={printRef} />
      )}
      {activeReport === 'evaluation' && (
        <EvaluationReport handlePrint={handlePrint} printRef={printRef} />
      )}
      {activeReport === 'comparison' && (
        <ComparisonReport handlePrint={handlePrint} printRef={printRef} />
      )}
      {activeReport === 'workout_results' && (
        <Report handlePrint={handlePrint} printRef={printRef} resultsReportType='workoutResultsReport' />
      )}
      {activeReport === 'opt_out_notes' && (
        <Report handlePrint={handlePrint} printRef={printRef} resultsReportType='optOutNotesReport' />
      )}
      {activeReport === 'completion' && (
        <CompletionReport handlePrint={handlePrint} printRef={printRef} />
      )}
    </>
  );
};

ActiveReport.propTypes = {
  printRef: PropTypes.shape({
    current: PropTypes.oneOfType([
      PropTypes.instanceOf(Element),
      PropTypes.oneOf([null]),
    ]),
  }),
  handlePrint: PropTypes.func,
};

ActiveReport.defaultProps = {
  printRef: null,
  handlePrint: () => {},
};

export default ActiveReport;
