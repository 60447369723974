import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { updateOrganizationSettings } from '../../../../login/ducks/loginActions';
import Text from '../../../../../shared/components/Text/Text';
import Toggle from '../../../../../shared/components/Toggle/Toggle';
import AIAcknowledgmentModal from './AIAcknowledgmentModal';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 15px;

  .react-toggle .react-toggle-track {
    background-color: #E9E9EA;
  }
  .react-toggle--checked .react-toggle-track {
    background-color: ${({ theme }) => theme.colors.green};
  }
  .react-toggle-thumb {
    border: none;
  }

  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #E9E9EA;
  }
  
  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: ${({ theme }) => theme.colors.green};
  }
`;

const Row = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  cursor: pointer;
`;

const Column = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 602px;
`;

const HeaderContainer = styled('div')`
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: #E0E0E0 1px solid;
`;

const BetaSettings = ({ containerWidth, flags }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const accountCode = currentUser?.accountCode;
  const organizationSettings = useSelector((state) => state.auth.data.organizationSettings);
  const userAcceptances = useSelector((state) => state.auth.data.currentUser?.acceptances || []);

  const [darknessMode, setDarknessMode] = useState(organizationSettings?.skin?.darknessMode);
  const [athleteWorkouts, setAthleteWorkouts] = useState(
    organizationSettings?.generalSettings?.athleteWorkoutsEnabled,
  );
  const [athleteWorkoutsAI, setAthleteWorkoutsAI] = useState(
    organizationSettings?.generalSettings?.athleteWorkoutsAIEnabled,
  );
  const [isAIModalOpen, setIsAIModalOpen] = useState(false);

  const hasAcceptedTerms = userAcceptances.includes(1);

  const debouncedSetDarknessMode = useDebouncedCallback(
    (newSettings) => {
      const valueObj = {
        skin: {
          darknessMode: newSettings,
        },
      };

      dispatch(updateOrganizationSettings(accountCode, valueObj));
    },
    1000,
  );

  const debouncedSetAthleteWorkouts = useDebouncedCallback(
    (newSettings) => {
      const valueObj = {
        generalSettings: {
          athleteWorkoutsEnabled: newSettings,
        },
      };

      dispatch(updateOrganizationSettings(accountCode, valueObj));
    },
    1000,
  );

  const debouncedSetAthleteWorkoutsAI = useDebouncedCallback(
    (newSettings) => {
      const valueObj = {
        generalSettings: {
          athleteWorkoutsAIEnabled: newSettings,
        },
      };

      dispatch(updateOrganizationSettings(accountCode, valueObj));
    },
    1000,
  );

  const handleSwitches = (v) => {
    setDarknessMode(v);
    debouncedSetDarknessMode(v);
  };

  const handleAthleteWorkouts = (v) => {
    setAthleteWorkouts(v);
    debouncedSetAthleteWorkouts(v);
    if (!v) {
      setAthleteWorkoutsAI(false);
      debouncedSetAthleteWorkoutsAI(false);
    }
  };

  const handleAthleteWorkoutsAI = (v) => {
    if (v && !hasAcceptedTerms) {
      setIsAIModalOpen(true);
    } else if (v) {
      setAthleteWorkoutsAI(true);
      debouncedSetAthleteWorkoutsAI(true);
    } else {
      setAthleteWorkoutsAI(false);
      debouncedSetAthleteWorkoutsAI(false);
    }
  };

  const handleAIAcknowledge = () => {
    setAthleteWorkoutsAI(true);
    debouncedSetAthleteWorkoutsAI(true);
    setIsAIModalOpen(false);
  };

  return (
    <Wrapper>
      <HeaderContainer>
        <Text fontSize={containerWidth && containerWidth < 450 ? 24 : 28} fontWeight='700'>
          Beta Settings
        </Text>
        <Text fontSize={18} fontWeight='400'> Manage notifications on your devices or in our notification hub for various topics.</Text>
      </HeaderContainer>
      <>
        <Row>
          <Column>
            <Text fontSize={18} fontWeight='700' pb={10}>
              Dark(ness) Mode
            </Text>
            <Text fontSize={18} fontWeight='400'>
              In dark mode, new beta pages use a darker color palette.
            </Text>
          </Column>
          <Toggle
            checked={darknessMode}
            icons={false}
            onChange={() => {
              handleSwitches(!darknessMode);
            }}
          />
        </Row>
        {flags && flags.athleteAddedWorkouts && (
          <Row>
            <Column>
              <Text fontSize={18} fontWeight='700' pb={10}>
                Allow Athlete Workouts
              </Text>
              <Text fontSize={18} fontWeight='400'>
                Enable athletes to add their own exercises to a workout. This does
                not allow athletes to modify what is programmed by a coach.
                Available exercises are limited to your organization&apos;s
                Exercise database.
              </Text>
            </Column>
            <Toggle
              checked={athleteWorkouts}
              icons={false}
              onChange={() => {
                handleAthleteWorkouts(!athleteWorkouts);
              }}
            />
          </Row>
        )}
        {athleteWorkouts && flags && flags.aiAthleteWorkouts && (
          <Row style={{ marginLeft: '20px' }}>
            <Column>
              <Text fontSize={18} fontWeight='700' pb={10}>
                AI-Powered Athlete Workouts
              </Text>
              <Text fontSize={18} fontWeight='400'>
                Enables athletes to use TeamBuildr&apos;s AI assistant to add
                their own exercises (from your organization&apos;s database) to
                a workout.
              </Text>
            </Column>
            <Toggle
              checked={athleteWorkoutsAI}
              icons={false}
              onChange={() => {
                handleAthleteWorkoutsAI(!athleteWorkoutsAI);
              }}
            />
          </Row>
        )}
      </>
      <AIAcknowledgmentModal
        isOpen={isAIModalOpen}
        onRequestClose={() => setIsAIModalOpen(false)}
        onAcknowledge={handleAIAcknowledge}
      />
    </Wrapper>
  );
};

BetaSettings.propTypes = {
  containerWidth: PropTypes.number,
  flags: PropTypes.instanceOf(Object),
};

BetaSettings.defaultProps = {
  containerWidth: '',
  flags: {},
};

export default withLDConsumer()(BetaSettings);
