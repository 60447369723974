import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AthleteDetails from './AthleteDetails';
import RatingInfo from './RatingInfo';

const AthleteContainer = styled.div`
  padding: 8px 20px 16px 20px;
  border-bottom: 1px solid #EEE;
`;

const AthleteInfo = ({
  bodyMapData,
  setCurrentView,
}) => {
  const isRecoveryRadarWorkoutModalShowing = useSelector(
    (state) => state.recoveryRadarTracker.ui.isRecoveryRadarWorkoutModalShowing,
  );
  const isRecoveryRadarWeightroomModalShowing = useSelector(
    (state) => state.recoveryRadarTracker.ui.isRecoveryRadarWeightroomModalShowing,
  );

  const getContext = () => {
    if (isRecoveryRadarWorkoutModalShowing) {
      return 'workoutEntry';
    }
    if (isRecoveryRadarWeightroomModalShowing) {
      return 'weightRoomView';
    }
    return '';
  };

  return (
    <>
      <AthleteContainer>
        <AthleteDetails
          context={getContext()}
          bodyMapData={bodyMapData}
        />
      </AthleteContainer>
      <RatingInfo
        bodyMapData={bodyMapData}
        setCurrentView={setCurrentView}
      />
    </>
  );
};

AthleteInfo.propTypes = {
  bodyMapData: PropTypes.shape({
    currentIssues: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        bodyPart: PropTypes.string.isRequired,
      }),
    ).isRequired,
    previousIssues: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        bodyPart: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  setCurrentView: PropTypes.func.isRequired,
};

export default AthleteInfo;
