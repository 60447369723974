/* eslint-disable max-len */
import React, { useState } from 'react';
import styled from '@emotion/styled';
import Select from 'react-select';
import { Formik } from 'formik';
import ResizeObserver from 'react-resize-observer';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { useDebouncedCallback } from 'use-debounce';
import Text from '../../../../../shared/components/Text/Text';
import Toggle from '../../../../../shared/components/Toggle/Toggle';
import timeZones from '../../../../../shared/json/timezones.json';
import { updateOrganizationSettings } from '../../../../login/ducks/loginActions';

const OrganizationPreferences = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [containerWidth, setContainerWidth] = useState(null);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const accountCode = currentUser?.accountCode;
  const organizationSettings = useSelector((state) => state.auth.data.organizationSettings);
  const currentGlobalTimeZone = organizationSettings?.generalSettings?.timeZone;
  const currentGlobalWeightType = organizationSettings?.generalSettings?.weightType;
  const currnetGlobalMaxCalculation = organizationSettings?.generalSettings?.maxCalculation;
  const currentGlobalRepCap = organizationSettings?.generalSettings?.repCap;

  const accessLevel = currentUser.organizationAccessLevel;

  const timeZoneSelectionEntries = Object.entries(timeZones).map((entry) => ({
    label: entry[1],
    value: entry[0],
  }));

  const defaultTimezoneValue = timeZoneSelectionEntries.filter((entryObj) => entryObj.value
  === organizationSettings?.generalSettings?.timeZone);

  const hardCodedDefaultTimezoneValue = {
    label: '(GMT-05:00) Eastern Time (US & Canada)',
    value: 'America/New_York',
  };

  const weightTypeDefaultValue = organizationSettings?.generalSettings?.weightType === 1 ? { value: 1, label: 'kgs' } : { value: 0, label: 'lbs' };

  const maxCalFormulatDefaultValue = organizationSettings?.generalSettings?.maxCalculation === 2 ? { value: 2, label: 'Epley' } : { value: 1, label: 'Brzycki' };

  const repCapValue = { value: organizationSettings?.generalSettings?.repCap, label: `${organizationSettings?.generalSettings?.repCap}` };

  const [organizationPreferencesSettings, setOrganizationPreferencesSettings] = useState({
    athleteManagementOfMaxes: Boolean(organizationSettings?.generalSettings?.athleteMaxPrManagement),
    dailyWorkoutEmails: Boolean(organizationSettings?.generalSettings?.dailyWorkoutEmailsEnabled),
    scheduledReportCardEmails: Boolean(organizationSettings?.generalSettings?.sendReportCards),
    allowAthletesToResetPrograms: Boolean(organizationSettings?.dayBasedProgramSettings?.allowUserProgramRestarts),
  });

  const debouncedOrganizationPreferencesSettings = useDebouncedCallback(
    (newSettings) => {
      const valueObj = {
        generalSettings: {
          athleteMaxPrManagement: newSettings.athleteManagementOfMaxes,
          dailyWorkoutEmailsEnabled: newSettings.dailyWorkoutEmails,
          sendReportCards: newSettings.scheduledReportCardEmails,
          reportCardRecipients: newSettings.reportCardRecipients,
        },
        dayBasedProgramSettings: {
          allowUserProgramRestarts: newSettings.allowAthletesToResetPrograms,
        },
      };
      dispatch(updateOrganizationSettings(accountCode, valueObj));
    },
    1000,
  );

  const handleSwitches = (field, v) => {
    setOrganizationPreferencesSettings((prev) => ({
      ...prev,
      [field]: v,
    }));
    debouncedOrganizationPreferencesSettings({
      ...organizationPreferencesSettings,
      [field]: v,
    });
  };

  const handleDropdownChange = (field, v) => {
    debouncedOrganizationPreferencesSettings({
      ...organizationPreferencesSettings,
      [field]: v,
    });
  };

  const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 45px;
    .react-toggle .react-toggle-track {
      background-color: #E9E9EA;
    }
    .react-toggle--checked .react-toggle-track {
      background-color: ${theme.colors.green};
    }
    .react-toggle-thumb {
      border: none;
    }
    .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
      background-color: #E9E9EA;
    }
    .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
      background-color: ${theme.colors.green};
    }
  `;

  const Row = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 15px;
    height: 46px;
  `;

  const RowWithSubtitle = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 15px;
  `;

  const HeaderContainer = styled('div')`
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: #E0E0E0 1px solid;
  `;

  const CustomInput = styled('input')`
    font-weight: 400;
    border: 2px solid #E0E0E0;
    border-radius: 6px;
    padding-left: 16px;
    padding-right: 16px;
    height: 46px;
    max-width: 120px;
    margin-left: 10px;
    text-align: center;
  `;

  const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  max-width: 602px;
`;

  const SubText = styled('span')`
  font-size: 16px;
  margin-top: 5px;
  color: ${(props) => props.theme.textColor};
`;

  const customSelectStyles = {
    container: (provided) => ({
      ...provided,
      minWidth: 120,
      height: 46,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#E0E0E0',
      borderRadius: 6,
    }),
    menu: (provided) => ({
      ...provided,
      width: 200,
    }),
    control: (provided) => ({
      ...provided,
      minHeight: 46,
      width: '100%',
      border: 0,
      outline: '2px solid #E0E0E0',
    }),
  };

  const timeZoneCustomSelectStyles = {
    container: (provided) => ({
      ...provided,
      width: containerWidth && containerWidth < 375 ? 175 : 230,
      height: 46,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#E0E0E0',
      borderRadius: 6,
    }),
    menu: (provided) => ({
      ...provided,
      width: 200,
    }),
    control: (provided) => ({
      ...provided,
      minHeight: 46,
      width: '100%',
      border: 0,
      outline: '2px solid #E0E0E0',
    }),
  };

  const repsArray = [];

  const emailReceipeints = [
    { value: 1, label: 'Athletes' },
    { value: 2, label: 'Parents/Guardians' },
    { value: 3, label: 'Athletes and Parents/Guardians' },
  ];

  const mapBackendValuesToSelectOptions = (backendValue, options) => {
    switch (backendValue) {
      case 'Athletes':
        return options.find((option) => option.label === 'Athletes');
      case 'Guardians':
        return options.find((option) => option.label === 'Parents/Guardians');
      case 'Athletes and Guardians':
        return options.find((option) => option.label === 'Athletes and Parents/Guardians');
      default:
        return null;
    }
  };

  const mapRecipientToInteger = (backendValue) => {
    switch (backendValue) {
      case 'Athletes':
        return 1;
      case 'Guardians':
        return 2;
      case 'Athletes and Guardians':
        return 3;
      default:
        return 1;
    }
  };

  for (let i = 3; i <= 30; i += 1) {
    repsArray.push({ value: i, label: i });
  }

  return (
    <Wrapper>
      <HeaderContainer>
        <Text fontSize={containerWidth && containerWidth < 450 ? 24 : 28} fontWeight='700'>
          Organization Preferences
        </Text>
        <Text fontSize={18} fontWeight='400'>
          {'Manage your organization\'s global preferences.'}
        </Text>
      </HeaderContainer>
      <>
        <Formik
          initialValues={{
            timeZone: organizationSettings?.generalSettings?.timeZone,
            weightType: organizationSettings?.generalSettings?.weightType,
            maxCalcFormula: organizationSettings?.generalSettings?.maxCalculation,
            numberOfReps: organizationSettings?.generalSettings?.repCap,
            emailReceipeints: mapRecipientToInteger(organizationSettings?.generalSettings?.reportCardRecipients),
          }}
          onSubmit={(values, { setSubmitting }) => {
          // When button submits form and form is in the process of submitting,
          // submit button is disabled
            setSubmitting(true);

            const valueObj = {
              generalSettings: {
                timeZone: values.timeZone,
                weightType: values.weightType,
                maxCalculation: values.maxCalcFormula,
                repCap: parseInt(values.numberOfReps, 10),
                reportCardRecipients: values.emailReceipeints,
              },
            };
            // check to see if any values have changed, if nothing has changed, don't hit the API on blur
            if (
              values.timeZone !== currentGlobalTimeZone
              || values.weightType !== currentGlobalWeightType
              || values.maxCalcFormula !== currnetGlobalMaxCalculation
              || values.numberOfReps !== currentGlobalRepCap) {
              dispatch(updateOrganizationSettings(accountCode, valueObj));
            }
            setSubmitting(false);
          }}
        >
          {/* Callback function containing Formik state and
            helpers that handle common form actions... destructured from props */}
          {({
            values,
            setFieldValue,
            handleChange,
            handleSubmit,
          }) => (
            <form onBlur={handleSubmit}>
              <ResizeObserver
                onResize={(rect) => {
                  setContainerWidth(rect.width);
                }}
              />
              <Row>
                <Text fontSize={containerWidth && containerWidth < 375 ? 15 : 18} fontWeight='700'>
                  Time Zone
                </Text>
                <div>
                  <Select
                    styles={timeZoneCustomSelectStyles}
                    options={timeZoneSelectionEntries}
                    defaultValue={defaultTimezoneValue || hardCodedDefaultTimezoneValue}
                    onChange={(options) => {
                      setFieldValue('timeZone', options.value);
                      handleSubmit();
                    }}
                  />
                </div>
              </Row>

              <Row>
                <Text fontSize={containerWidth && containerWidth < 375 ? 15 : 18} fontWeight='700'>
                  Weight Unit
                </Text>
                <div>
                  <Select
                    styles={customSelectStyles}
                    options={[{ value: 1, label: 'kgs' }, { value: 0, label: 'lbs' }]}
                    defaultValue={weightTypeDefaultValue}
                    onChange={(options) => {
                      setFieldValue('weightType', options.value);
                      handleSubmit();
                    }}
                  />
                </div>
              </Row>

              <Row>
                <Text fontSize={containerWidth && containerWidth < 375 ? 15 : 18} fontWeight='700'>
                  Max Calculation Formula
                </Text>
                <div>
                  <Select
                    styles={customSelectStyles}
                    options={[{ value: 1, label: 'Brzycki' }, { value: 2, label: 'Epley' }]}
                    defaultValue={maxCalFormulatDefaultValue}
                    onChange={(options) => {
                      setFieldValue('maxCalcFormula', options.value);
                      handleSubmit();
                    }}
                  />
                </div>
              </Row>

              <Row>
                <Text fontSize={containerWidth && containerWidth < 375 ? 15 : 18} fontWeight='700'>
                  Highest # of Reps for Max Calc.
                </Text>
                <Select
                  styles={customSelectStyles}
                  options={repsArray}
                  defaultValue={repCapValue}
                  onChange={(options) => {
                    setFieldValue('numberOfReps', options.value);
                    handleSubmit();
                  }}
                />
              </Row>
            </form>
          )}
        </Formik>
        <Row>
          <Text fontSize={containerWidth && containerWidth < 375 ? 15 : 18} fontWeight='700'>
            Athlete Management of Maxes/PRs
          </Text>
          <Toggle
            checked={organizationPreferencesSettings.athleteManagementOfMaxes}
            icons={false}
            onChange={() => {
              handleSwitches('athleteManagementOfMaxes', !organizationPreferencesSettings.athleteManagementOfMaxes);
            }}
          />
        </Row>
        <Row>
          <Text fontSize={containerWidth && containerWidth < 375 ? 15 : 18} fontWeight='700'>
            Daily Workout Emails
          </Text>
          <Toggle
            checked={organizationPreferencesSettings.dailyWorkoutEmails}
            icons={false}
            onChange={() => {
              handleSwitches('dailyWorkoutEmails', !organizationPreferencesSettings.dailyWorkoutEmails);
            }}
          />
        </Row>
        {accessLevel >= 6 && (
          <RowWithSubtitle>
            <TextContainer>
              <Text fontSize={containerWidth && containerWidth < 375 ? 15 : 18} fontWeight='700'>
                Schedule Weekly Performance Assessment Emails
              </Text>
              <SubText>
                When enabled, emails will go out Sunday at 4:15am EST time (this cannot be changed at the moment).
              </SubText>
            </TextContainer>
            <Toggle
              checked={organizationPreferencesSettings.scheduledReportCardEmails}
              icons={false}
              onChange={() => {
                handleSwitches('scheduledReportCardEmails', !organizationPreferencesSettings.scheduledReportCardEmails);
              }}
            />
          </RowWithSubtitle>
        )}
        {organizationPreferencesSettings.scheduledReportCardEmails && (
          <Row>
            <Text fontSize={containerWidth && containerWidth < 375 ? 15 : 18} fontWeight='700'>
              Scheduled Emails Will Be Sent To
            </Text>
            <Select
              styles={customSelectStyles}
              options={emailReceipeints}
              defaultValue={mapBackendValuesToSelectOptions(organizationSettings?.generalSettings?.reportCardRecipients, emailReceipeints)}
              onChange={(options) => {
                handleDropdownChange('reportCardRecipients', options.value);
              }}
            />
          </Row>
        )}
        {accessLevel >= 6 && (
          <Row>
            <Text fontSize={containerWidth && containerWidth < 375 ? 15 : 18} fontWeight='700'>
              Allow Athletes to Reset Programs
            </Text>
            <Toggle
              checked={organizationPreferencesSettings.allowAthletesToResetPrograms}
              icons={false}
              onChange={() => {
                handleSwitches('allowAthletesToResetPrograms', !organizationPreferencesSettings.allowAthletesToResetPrograms);
              }}
            />
          </Row>
        )}
      </>
    </Wrapper>
  );
};

export default OrganizationPreferences;
